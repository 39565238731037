import useLocalStorage, { writeStorage } from "@rehooks/local-storage";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { createContext, useContext } from "react";

import { useConfigureSentryScope } from "../utils/sentry";

import { triggerError } from "./generalError";

import type { FunctionComponent } from "react";
import type { InjectedEnv } from "shared/types/Env";

export const ConfigurationContext = createContext<InjectedEnv | null>(null);

const localStorageConfigKey = "onboardConfig";

export const useHubConfig = () => {
  // make sure all tabs always use the latest config
  // sync CSRF_TOKEN with the latest CSRF cookie
  const [data] = useLocalStorage<InjectedEnv>(localStorageConfigKey);
  const result = useQuery({
    queryKey: ["get", "/api/configuration"],
    queryFn: () =>
      axios.get<InjectedEnv>("/api/configuration").then((response) => {
        writeStorage(localStorageConfigKey, response.data);
        return response.data;
      }),
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    placeholderData: data ?? undefined,
  });
  return { ...result, data: data || result.data || undefined };
};

//
export const ConfigurationProvider: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { data, isLoading, error } = useHubConfig();

  useConfigureSentryScope(data);

  if (error) {
    triggerError(error);
  }

  if (isLoading || !data) {
    return null;
  }

  return <ConfigurationContext.Provider value={data}>{children}</ConfigurationContext.Provider>;
};

export const useHubConfiguration = () => {
  const config = useContext(ConfigurationContext);

  if (!config) {
    throw new Error("Missing ConfigurationProvider!");
  }

  return config;
};
