/**
 * Validates whether the string is alphanumeric, no special characters. Allows spaces and hyphens.
 * Falsy values return true.
 *
 * @param val string to compare against
 * @returns {boolean}
 */
export const checkAlphaNumericHyphen = (val: string | undefined | null): boolean => {
  const regex = new RegExp(/^[\w\-\s]+$/);
  if (val) return regex.test(val);
  return true;
};
