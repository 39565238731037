import clsx from "clsx";
import * as styles from "./SlobRadio.module.less";
import type { InputHTMLAttributes } from "react";

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "className"> & {
  error?: boolean;
};

export function SlobRadio(props: Props) {
  const { error, ...rest } = props;

  return (
    <span
      className={clsx(
        styles.slobRadio,
        rest.disabled && styles.slobRadio__disabled,
        error && styles.slobRadio__error,
      )}
    >
      <input {...rest} type="radio" className={styles.slobRadioInput} />

      <span className={styles.slobRadioInner} />
    </span>
  );
}
