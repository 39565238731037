import { AccessControl } from "accesscontrol";
import { deniedBrokerAndBAClientProperties } from "shared/rbac/permissionProperties";
import type { UserRole as UserRoleDB } from "@prisma/client";

// Database resources
export const CLIENT = "client";
export const CARRIER = "carrier";

export const BEN_ADMIN = "BEN_ADMIN";
export const BROKER = "BROKER";
export const SLF_OWNER = "SLF_OWNER";
export const SLF_ADMIN = "SLF_ADMIN";
export const roleIds = [BEN_ADMIN, BROKER, SLF_OWNER, SLF_ADMIN] as const;

export type UserRole = UserRoleDB;
export type UserRoles = Array<
  typeof BEN_ADMIN | typeof BROKER | typeof SLF_OWNER | typeof SLF_ADMIN
>;

// Any resource with the `CLIENT_` prefix will own the resources inside that client.
// Example
// readOwn(CLIENT_DOCUMENT) - role can read the documents inside its client
// readAny(CLIENT_DOCUMENT) - role can read the documents of any client
export const CLIENT_ACTION = "client-action";
export const CLIENT_EIF = "client-eif";
export const CLIENT_DOCUMENT = "client-document";
export const CLIENT_WEBLINK = "client-webLink";
export const CLIENT_ONBOARDING_FORMS = "client-onboarding-forms";

// Action resources
export const ONBOARDING_FORMS_REASSIGN = "onboarding-form-reassign";
export const GENERATE_EMAILS_ACTIVITIES = "generate-emails_activities";
export const BEN_ADMIN_ASSIGN = "ben-admin-assign";
export const BROKER_ASSIGN = "broker-assign";
export const TRIGGER_POST_ONBOARDING_SURVEY = "trigger-post-onboarding-survey";
export const TRIGGER_PHASE_CHANGE_EMAILS = "trigger-phase-change-emails";
export const TRIGGER_RISK_ACCEPTANCE_EMAILS = "trigger-risk-acceptance-emails";
export const GENERATE_EMAILS_UPCOMING_DATES = "generate-emails-upcoming-dates";
export const GENERATE_EMAILS_ACTIVELY_AT_WORK_REMINDER =
  "generate-emails-actively-at-work-reminder";
export const TRIGGER_DENTAL_EMAILS = "trigger-dental-emails";
export const RESEND_REJECTED_EMAILS = "resend-rejected-emails";
export const PURGE_QA_DATA = "purge-qa-data";
export const GET_EMAILS = "get-emails";
export const RESEND_EMAILS = "resend-emails";
export const GET_BEN_ADMINS = "get-ben-admins";
export const GET_BROKERS = "get-brokers";
export const GET_SLF_ADMINS = "get-slf-admins";
export const GET_SLF_OWNERS = "get-slf-owners";
export const GET_SLF_USERS = "get-slf-users";
export const GET_USERS = "get-users";
export const COPY_ACTIVATION_LINK = "copy-activation-link";
export const BULK_UPDATE_EMAIL_AND_SEND_ACTIVATION_EMAIL =
  "bulk-update-email-and-send-activation-email";
export const UPDATE_PHASE_CHANGE_STATUS_FLAG = "update-phase-change-status-flag";
export const UNDO_IC_EDIT = "undo-ic-edit";

// OKTA resources
export const EMAIL = "email";

export type ResourceType =
  | typeof CLIENT
  | typeof CLIENT_ACTION
  | typeof CLIENT_EIF
  | typeof BEN_ADMIN
  | typeof BROKER
  | typeof SLF_OWNER
  | typeof SLF_ADMIN
  | typeof EMAIL
  | typeof PURGE_QA_DATA
  | typeof CLIENT_DOCUMENT
  | typeof CLIENT_WEBLINK
  | typeof CLIENT_ONBOARDING_FORMS
  | typeof BEN_ADMIN_ASSIGN
  | typeof BROKER_ASSIGN
  | typeof ONBOARDING_FORMS_REASSIGN
  | typeof TRIGGER_POST_ONBOARDING_SURVEY
  | typeof TRIGGER_PHASE_CHANGE_EMAILS
  | typeof TRIGGER_RISK_ACCEPTANCE_EMAILS
  | typeof GENERATE_EMAILS_ACTIVITIES
  | typeof TRIGGER_DENTAL_EMAILS
  | typeof GENERATE_EMAILS_UPCOMING_DATES
  | typeof GENERATE_EMAILS_ACTIVELY_AT_WORK_REMINDER
  | typeof RESEND_REJECTED_EMAILS
  | typeof CARRIER
  | typeof GET_EMAILS
  | typeof RESEND_EMAILS
  | typeof GET_BEN_ADMINS
  | typeof GET_BROKERS
  | typeof GET_SLF_ADMINS
  | typeof GET_SLF_OWNERS
  | typeof GET_SLF_USERS
  | typeof GET_USERS
  | typeof COPY_ACTIVATION_LINK
  | typeof BULK_UPDATE_EMAIL_AND_SEND_ACTIVATION_EMAIL
  | typeof UPDATE_PHASE_CHANGE_STATUS_FLAG
  | typeof UNDO_IC_EDIT;

type GetUserRoleAndResouceTypeMap = { userRole: UserRole; requiredRBAC: ResourceType };
export const roleGetActionList: GetUserRoleAndResouceTypeMap[] = [
  {
    userRole: "BEN_ADMIN",
    requiredRBAC: GET_BEN_ADMINS,
  },
  {
    userRole: "BROKER",
    requiredRBAC: GET_BROKERS,
  },
  {
    userRole: "SLF_ADMIN",
    requiredRBAC: GET_SLF_ADMINS,
  },
  {
    userRole: "SLF_OWNER",
    requiredRBAC: GET_SLF_OWNERS,
  },
];

export const accessController = new AccessControl();

accessController.deny([BROKER]).readAny(BROKER).updateAny(BROKER);

accessController
  .grant([BEN_ADMIN, BROKER])
  .readOwn(CLIENT)
  .updateOwn(CLIENT, deniedBrokerAndBAClientProperties);

accessController.grant([BROKER]).readOwn(BROKER).updateOwn(BROKER, ["*", "!clientIds"]);

accessController.grant([BEN_ADMIN]).readOwn(BEN_ADMIN);

accessController.grant([SLF_OWNER, SLF_ADMIN]).updateAny(BEN_ADMIN_ASSIGN);
accessController.grant([SLF_OWNER, SLF_ADMIN]).updateAny(BROKER_ASSIGN);

accessController
  .grant([SLF_OWNER, SLF_ADMIN])
  .createAny(CLIENT)
  .readAny(CLIENT)
  .updateAny(CLIENT)
  .deleteAny(CLIENT);

accessController
  .grant(SLF_ADMIN)
  .createAny([BEN_ADMIN, BROKER])
  .readAny([BEN_ADMIN, BROKER])
  .updateAny([BEN_ADMIN, BROKER])
  .deleteAny([BEN_ADMIN, BROKER]);

accessController
  .grant(SLF_OWNER)
  .extend(SLF_ADMIN)
  .createAny([SLF_OWNER, SLF_ADMIN])
  .readAny([SLF_OWNER, SLF_ADMIN])
  .updateAny([SLF_OWNER, SLF_ADMIN])
  .deleteAny([SLF_OWNER, SLF_ADMIN]);

accessController.grant([SLF_ADMIN, SLF_OWNER]).createAny([EMAIL]);

accessController.grant([BEN_ADMIN, BROKER]).readOwn(CLIENT_ACTION);
accessController.grant([BEN_ADMIN, BROKER]).createOwn(CLIENT_ACTION);
accessController.grant([BEN_ADMIN, BROKER]).updateOwn(CLIENT_ACTION);
accessController.grant([SLF_ADMIN, SLF_OWNER]).createAny(CLIENT_ACTION);
accessController.grant([SLF_ADMIN, SLF_OWNER]).readAny(CLIENT_ACTION);
accessController.grant([SLF_ADMIN, SLF_OWNER]).updateAny(CLIENT_ACTION);

accessController.grant([BEN_ADMIN, BROKER]).readOwn(CLIENT_EIF);
accessController.grant([BEN_ADMIN, BROKER]).createOwn(CLIENT_EIF);
accessController.grant([BEN_ADMIN, BROKER]).updateOwn(CLIENT_EIF);
accessController.grant([BEN_ADMIN, BROKER]).deleteOwn(CLIENT_EIF);
accessController.grant([SLF_ADMIN, SLF_OWNER]).readAny(CLIENT_EIF);
accessController.grant([SLF_ADMIN, SLF_OWNER]).createAny(CLIENT_EIF);
accessController.grant([SLF_ADMIN, SLF_OWNER]).updateAny(CLIENT_EIF);
accessController.grant([SLF_ADMIN, SLF_OWNER]).deleteAny(CLIENT_EIF);

accessController
  .grant([BEN_ADMIN, BROKER])
  .createOwn(CLIENT_DOCUMENT)
  .readOwn(CLIENT_DOCUMENT)
  .updateOwn(CLIENT_DOCUMENT)
  .deleteOwn(CLIENT_DOCUMENT);
accessController
  .grant([SLF_ADMIN, SLF_OWNER])
  .createAny(CLIENT_DOCUMENT)
  .readAny(CLIENT_DOCUMENT)
  .updateAny(CLIENT_DOCUMENT)
  .deleteAny(CLIENT_DOCUMENT);

accessController.grant([BEN_ADMIN, BROKER]).readOwn(CLIENT_WEBLINK);
accessController
  .grant([SLF_ADMIN, SLF_OWNER])
  .createAny(CLIENT_WEBLINK)
  .readAny(CLIENT_WEBLINK)
  .updateAny(CLIENT_WEBLINK)
  .deleteAny(CLIENT_WEBLINK);

accessController
  .grant([BEN_ADMIN, BROKER])
  .readOwn(CLIENT_ONBOARDING_FORMS)
  .createOwn(CLIENT_ONBOARDING_FORMS)
  .updateOwn(CLIENT_ONBOARDING_FORMS);

accessController
  .grant([SLF_OWNER, SLF_ADMIN])
  .createAny(CLIENT_ONBOARDING_FORMS)
  .readAny(CLIENT_ONBOARDING_FORMS)
  .updateAny(CLIENT_ONBOARDING_FORMS)
  .deleteAny(CLIENT_ONBOARDING_FORMS);

accessController.grant([SLF_OWNER, SLF_ADMIN]).updateAny(ONBOARDING_FORMS_REASSIGN);

accessController.grant([SLF_OWNER]).createAny(GENERATE_EMAILS_ACTIVITIES);
accessController.grant([SLF_OWNER]).readAny(GENERATE_EMAILS_ACTIVITIES);

accessController.grant([SLF_OWNER]).createAny(TRIGGER_POST_ONBOARDING_SURVEY);

accessController.grant([SLF_OWNER]).createAny(TRIGGER_PHASE_CHANGE_EMAILS);

accessController.grant([SLF_OWNER]).createAny(TRIGGER_RISK_ACCEPTANCE_EMAILS);

accessController.grant([SLF_OWNER]).createAny(GENERATE_EMAILS_UPCOMING_DATES);

accessController.grant([SLF_OWNER]).createAny(GENERATE_EMAILS_ACTIVELY_AT_WORK_REMINDER);

accessController.grant([SLF_OWNER]).createAny(PURGE_QA_DATA);

accessController.grant([SLF_OWNER]).createAny(TRIGGER_DENTAL_EMAILS);

accessController.grant([SLF_OWNER]).createAny(RESEND_REJECTED_EMAILS);

accessController.grant([SLF_OWNER]).createAny(CARRIER);

accessController.grant([SLF_ADMIN, SLF_OWNER]).readAny(GET_EMAILS);

accessController.grant([SLF_ADMIN, SLF_OWNER]).createAny(RESEND_EMAILS);

accessController.grant([BEN_ADMIN, BROKER, SLF_ADMIN, SLF_ADMIN]).readAny(GET_BEN_ADMINS);
accessController.grant([BROKER, SLF_ADMIN, SLF_OWNER]).readAny(GET_BROKERS);
accessController.grant([SLF_ADMIN, SLF_OWNER]).readAny(GET_SLF_ADMINS);
accessController.grant([SLF_OWNER]).readAny(GET_SLF_OWNERS);
accessController.grant([SLF_OWNER, SLF_ADMIN]).readAny(GET_SLF_USERS);
accessController.grant([SLF_OWNER, SLF_ADMIN]).readAny(GET_USERS);

accessController.grant([SLF_ADMIN, SLF_OWNER]).createAny(COPY_ACTIVATION_LINK);
accessController
  .grant([SLF_ADMIN, SLF_OWNER])
  .createAny(BULK_UPDATE_EMAIL_AND_SEND_ACTIVATION_EMAIL);

accessController.grant([SLF_OWNER]).createAny(UPDATE_PHASE_CHANGE_STATUS_FLAG);

accessController.grant([SLF_OWNER, SLF_ADMIN]).createAny(UNDO_IC_EDIT).updateAny(UNDO_IC_EDIT);

accessController.lock();
