import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { jsonContactToContact } from "client/src/hooks/contact";
import { compareQueryKey, useSlobMutation } from "client/src/hooks/query";
import type { JsonToTypeMapper, ResponseError } from "client/src/hooks/query";
import type {
  MonthlyClaimsReportMailingLocation,
  MonthlyClaimsReportMailingLocationInput,
} from "shared/types/MonthlyClaimsReportMailingLocation";

export const jsonMonthlyClaimsReportMailingLocationToMonthlyClaimsReportMailingLocation: JsonToTypeMapper<
  MonthlyClaimsReportMailingLocation
> = (mailingLocation) => {
  return {
    ...mailingLocation,
    createdAt: new Date(mailingLocation.createdAt),
    updatedAt: new Date(mailingLocation.updatedAt),
    deletedAt: mailingLocation.deletedAt ? new Date(mailingLocation.deletedAt) : null,
    sendMonthlyClaimsReportsToContact: mailingLocation.sendMonthlyClaimsReportsToContact
      ? jsonContactToContact(mailingLocation.sendMonthlyClaimsReportsToContact)
      : null,
  };
};

export type CreateMonthlyClaimsReportMailingLocationQuery = Pick<
  ReturnType<typeof useCreateMonthlyClaimsReportMailingLocation>,
  "isPending" | "mutateAsync" | "error"
>;

export function useCreateMonthlyClaimsReportMailingLocation() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    MonthlyClaimsReportMailingLocationInput,
    MonthlyClaimsReportMailingLocation,
    `/api/clients/:clientId/monthly-claims-report-mailing-location`,
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/monthly-claims-report-mailing-location`,
    map: jsonMonthlyClaimsReportMailingLocationToMonthlyClaimsReportMailingLocation,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });

  return query;
}

export type UpdateMonthlyClaimsReportMailingLocationQuery = Pick<
  ReturnType<typeof useUpdateMonthlyClaimsReportMailingLocation>,
  "isPending" | "mutateAsync" | "error"
>;

export function useUpdateMonthlyClaimsReportMailingLocation() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    MonthlyClaimsReportMailingLocationInput,
    MonthlyClaimsReportMailingLocation,
    `/api/clients/:clientId/monthly-claims-report-mailing-location/:id`,
    ResponseError,
    unknown
  >({
    method: "put",
    path: `/api/clients/:clientId/monthly-claims-report-mailing-location/:id`,
    map: jsonMonthlyClaimsReportMailingLocationToMonthlyClaimsReportMailingLocation,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });

  return query;
}

export type DeleteMonthlyClaimsReportMailingLocationQuery = Pick<
  ReturnType<typeof useDeleteMonthlyClaimsReportMailingLocation>,
  "isPending" | "mutateAsync" | "error"
>;

export function useDeleteMonthlyClaimsReportMailingLocation() {
  const queryClient = useQueryClient();

  const query = useSlobMutation({
    method: "delete",
    path: `/api/clients/:clientId/monthly-claims-report-mailing-location/:id`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });

  return query;
}
