import { areArraysEqual, getHasChangesByKeyList, rejectNullableValues } from "../utils/utils";
import type { ValueOf } from "./Helper";
import type { LocationId, Location, LocationInput, LocationStateCode } from "./Location";
import type {
  LocationManagementType,
  Contact as DBContact,
  ContactLocation as DBContactLocation,
  User as DBUser,
  Location as DBLocation,
} from "@prisma/client";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { PolicyId } from "shared/types/Client";
import type { User, UserId } from "shared/types/User";

export type ContactId = string;

export const contactTypes = [
  "DATA_FEEDS_IMPLEMENTOR",
  "DATA_FEEDS_PRODUCTION_SUPPORT",
  "DATA_FEEDS_BEN_ADMIN",
  "WEB_ADMIN",
  "PRIMARY_WEB_ADMIN",
  "DISABILITY_CLAIM_REIMBURSEMENT_CHECK_PAYEE",
  "CLAIMS_CHECK_PAYEE",
  "TPA",
] as const;
export type ContactType = ValueOf<typeof contactTypes>;

export const LocationManagementTypes = ["ALL", "LIMITED"] as const;

export type Contact = {
  id: ContactId;
  clientId: string;
  policyId: PolicyId | null;

  type: ContactType;

  firstName: string | null;
  lastName: string | null;
  email: string | null;
  title: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;

  locationId?: LocationId | null;
  location: Location | null;

  billingAccess: boolean | null;
  claimsAccess: boolean | null;
  memberChangesAccess: boolean | null;
  documentsAccess: boolean | null;
  paymentsAccess: boolean | null;
  accessesForPolicyIds: PolicyId[];

  locationManagementType: LocationManagementType | null;
  managedLocations?: Location[];

  address1: string | null;
  address2: string | null;
  city: string | null;
  state: LocationStateCode | null;
  zipCode: string | null;

  tpaFirmName: string | null;

  createdAt: Date;
  createdBy: string | null;
  updatedAt: Date;
  updatedBy: string | null;
  updatedByUser?: User | null;
  deletedAt?: Date | null;
  deletedBy?: string | null;
};

export type ContactFields = keyof Contact;

export type ContactInput = {
  id?: ContactId | null;
  policyId: PolicyId | null;
  type: ContactType;

  firstName: string | null;
  lastName: string | null;
  email: string | null;
  title?: string | null;
  phoneNumber?: string | null;
  faxNumber?: string | null;

  locationId?: string | null; //used for connecting/disconnecting contact to an existing location. locationId is given preference when updating contact
  location?: LocationInput & { id?: LocationId }; //used when creating and updating a contact and location

  billingAccess?: boolean | null;
  claimsAccess?: boolean | null;
  memberChangesAccess?: boolean | null;
  documentsAccess?: boolean | null;
  paymentsAccess?: boolean | null;
  accessesForPolicyIds?: PolicyId[];

  locationManagementType?: LocationManagementType | null;
  managedLocationIds?: string[];

  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: LocationStateCode | null;
  zipCode?: string | null;

  tpaFirmName?: string | null;

  deletedAt?: Date | null;
  deletedBy?: UserId | null;
};

export const contactsAreEqual = (
  c1: ContactInput | null | undefined,
  c2: ContactInput | null | undefined,
): boolean => {
  if (c1 === undefined || c2 === undefined) return true;
  if (!c1 || !c2) return c1 === c2;
  const isEqual = getHasChangesByKeyList<ContactInput>([
    "type",
    "firstName",
    "lastName",
    "email",
    "title",
    "phoneNumber",
    "faxNumber",
    "address1",
    "address2",
    "city",
    "state",
    "zipCode",
    "locationId",
    "claimsAccess",
    "billingAccess",
    "memberChangesAccess",
    "documentsAccess",
    "paymentsAccess",
    "locationManagementType",
  ]);

  const managedLocationsHasChanged =
    !c1.managedLocationIds || !c2.managedLocationIds
      ? c1.managedLocationIds !== c2.managedLocationIds
      : !areArraysEqual(c1.managedLocationIds, c2.managedLocationIds);
  return !(isEqual(c1, c2).hasChanged || managedLocationsHasChanged);
};

export const contactToContactInput = (contact: Contact): ContactInput => {
  const { createdAt, createdBy, updatedBy, managedLocations, location, ...rest } = contact;
  return {
    ...rest,
    managedLocationIds: managedLocations?.map((l) => l.id),
    locationId: location?.id,
    billingAccess: contact.billingAccess ?? undefined,
    claimsAccess: contact.claimsAccess ?? undefined,
    memberChangesAccess: contact.memberChangesAccess ?? undefined,
    documentsAccess: contact.documentsAccess ?? undefined,
    paymentsAccess: contact.paymentsAccess ?? undefined,
    locationManagementType: contact.locationManagementType ?? undefined,
  };
};

export const newContactInput = (): ContactInput => ({
  policyId: null,
  firstName: "",
  lastName: "",
  email: "",
  title: null,
  phoneNumber: null,
  faxNumber: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zipCode: null,
  type: "PRIMARY_WEB_ADMIN",
});

export const locationManagementTypeMap = new Map<LocationManagementType, string>([
  ["ALL", "All locations"],
  ["LIMITED", "Limited locations"],
]);

export const contactSunLifeAccesses: readonly (keyof Pick<
  Contact,
  "claimsAccess" | "billingAccess" | "paymentsAccess" | "documentsAccess" | "memberChangesAccess"
>)[] = [
  "claimsAccess",
  "billingAccess",
  "paymentsAccess",
  "documentsAccess",
  "memberChangesAccess",
] as const;

export type ContactSunLifeAccess = (typeof contactSunLifeAccesses)[number];

export const sunLifeAccessMap = new Map<ContactSunLifeAccess, string>([
  ["claimsAccess", "Claims"],
  ["billingAccess", "Bills"],
  ["paymentsAccess", "Payments"],
  ["documentsAccess", "Policy documents"],
  ["memberChangesAccess", "Manage employees"],
]);

export const getContactSunLifeAccesses = (contact: Contact) => {
  return contactSunLifeAccesses.filter((key) => contact[key]);
};

const postSigningIcEditableFields: ContactFields[] = [
  "firstName",
  "lastName",
  "title",
  "email",
  "phoneNumber",
  "location",
  "locationId",
  "locationManagementType",
  "accessesForPolicyIds",
  "billingAccess",
  "claimsAccess",
  "documentsAccess",
  "paymentsAccess",
  "memberChangesAccess",
];

export const relevantChangesForContactFields = (
  contactIds: ContactId[],
  changeSnapshot: DEIFChangeSnapshot,
  contactFields: ContactFields[] = postSigningIcEditableFields,
) => {
  const planAdminChangeDetailInfoList = contactIds
    .flatMap((cid) => {
      if (!changeSnapshot.Contact[cid]) return [];

      const changeDetailRecords = contactFields.map((fieldName) => {
        const changeDetailRecord = changeSnapshot.Contact[cid]?.[fieldName];
        return changeDetailRecord;
      });
      return changeDetailRecords;
    })
    .filter(rejectNullableValues);

  return planAdminChangeDetailInfoList;
};

export type ExpectedDBContact = DBContact & {
  location: DBLocation | null;
  updatedByUser?: DBUser | null;
  contactLocations?: (DBContactLocation & {
    location: DBLocation;
  })[];
};
