import { setHotjarUserId } from "client/src/utils/hotjar";
import { useMemo } from "react";
import { RouteData } from "shared/config/routeData";
import { buildRBAC } from "shared/rbac/rbac";
import { useGetAuthUserStatus } from "../auth/oktaAuth";
import { setSentryUser } from "../utils/sentry";
import { identifyZendeskUser } from "../utils/zendesk";
import type { Location } from "react-router-dom";

export const getLoginUrl = (location?: Location) => {
  const { pathname, search } = location || window.location;

  const publicPaths = [
    "/logout",
    RouteData.index.getPath(),
    RouteData.notFound.getPath(),
    RouteData.forgot.getPath(),
    RouteData.activate.getPath(),
    RouteData.reactivate.getPath(),
    RouteData.reset.getPath(),
    RouteData.maintenance.getPath(),
  ];
  const pathnameWithSearch = pathname + search;

  if (pathname === RouteData.login.getPath()) {
    return pathnameWithSearch;
  } else if (publicPaths.includes(pathname)) {
    const loginUrl = RouteData.login.getPath();
    return loginUrl;
  } else {
    const loginUrl =
      RouteData.login.getPath() + "?redirect=" + encodeURIComponent(pathnameWithSearch);
    return loginUrl;
  }
};

export const useSlobAuth = () => {
  const auth = useGetAuthUserStatus();

  return useMemo(() => {
    const authUser = auth.data?.authUser ?? null;
    if (authUser) {
      const { id, name, email, phoneNumber } = authUser;
      identifyZendeskUser(name, email, phoneNumber);
      setSentryUser({ id: id || undefined, email });
      setHotjarUserId(id || null);
    }
    return {
      isLoading: auth.isLoading,
      authUser,
      isAuthenticated: auth.data?.isAuthenticated,
      accessCheck: buildRBAC(authUser),
    };
  }, [auth.isLoading, auth.data]);
};
