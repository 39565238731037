import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect, useRef } from "react";

import { compareQueryKey, useSlobMutation, useSlobQuery } from "./query";
import { blobDocumentToObjectUrl, useDownloadDocument } from "./utils";

import type { JsonToTypeMapper } from "./query";
import type { ClientId } from "shared/types/Client";
import type { DocumentDownloadUrl } from "shared/types/Document";
import type { RecipientView } from "shared/types/Docusign";
import type {
  AddRecipientInput,
  CreateEmbeddedViewOutput,
  OnboardingForm,
  OnboardingFormId,
  OnboardingFormLockStatus,
  OnboardingFormWithMetadata,
  ReadyToSignOnboardingFormsMetadata,
  ReassignRecipientInput,
  TemplateLookup,
} from "shared/types/OnboardingForm";
import type { OnboardingFormDocumentId } from "shared/types/OnboardingFormDocument";
import type { RecipientId, RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";

const jsonRecipientToRecipient: JsonToTypeMapper<ReadonlyArray<RecipientWithMetadata>> = (
  recipients,
) => {
  return recipients.map((recipient) => ({
    ...recipient,
    metadata: {
      ...recipient.metadata,
      lastAction: {
        ...recipient.metadata.lastAction,
        date: recipient.metadata.lastAction.date
          ? new Date(recipient.metadata.lastAction.date)
          : null,
      },
    },
  }));
};

export const jsonOnboardingFormToOnboardingForm: JsonToTypeMapper<
  ReadonlyArray<OnboardingFormWithMetadata>
> = (onboardingForms) => {
  return onboardingForms.map((onboardingForm) => ({
    ...onboardingForm,
    metadata: {
      ...onboardingForm.metadata,
      recipients: jsonRecipientToRecipient(onboardingForm.metadata.recipients),
    },
    createdAt: new Date(onboardingForm.createdAt),
    updatedAt: new Date(onboardingForm.updatedAt),
    waitingForWebhook: onboardingForm.waitingForWebhook
      ? new Date(onboardingForm.waitingForWebhook)
      : null,
    deletedAt: onboardingForm.deletedAt ? new Date(onboardingForm.deletedAt) : null,
    declinedDateTime: onboardingForm.declinedDateTime
      ? new Date(onboardingForm.declinedDateTime)
      : null,
    voidedDateTime: onboardingForm.voidedDateTime ? new Date(onboardingForm.voidedDateTime) : null,
  }));
};

export const useGetOnboardingForms = (clientId: ClientId) => {
  const query = useSlobQuery<ReadonlyArray<OnboardingFormWithMetadata>>({
    method: "get",
    path: `/api/clients/${clientId}/onboarding-forms`,
    map: jsonOnboardingFormToOnboardingForm,
    options: {
      refetchInterval: (query) => {
        const onboardingForms = query.state.data;
        const hasFormWaitingFormWebhook = !!onboardingForms?.some((f) => f.waitingForWebhook);
        return hasFormWaitingFormWebhook ? 6000 : false;
      },
    },
  });
  return query;
};

export const jsonReadyToSignToReadyToSignMeta: JsonToTypeMapper<
  ReadyToSignOnboardingFormsMetadata
> = (metadata) => {
  return {
    ...metadata,
    recipient: {
      ...metadata.recipient,
      createdAt: new Date(metadata.recipient.createdAt),
      updatedAt: new Date(metadata.recipient.updatedAt),
      deletedAt: metadata.recipient.deletedAt ? new Date(metadata.recipient.deletedAt) : null,
      lastEditDateTime: metadata.recipient.lastEditDateTime
        ? new Date(metadata.recipient.lastEditDateTime)
        : null,
      deliveredDateTime: metadata.recipient.deliveredDateTime
        ? new Date(metadata.recipient.deliveredDateTime)
        : null,
      signedDateTime: metadata.recipient.signedDateTime
        ? new Date(metadata.recipient.signedDateTime)
        : null,
      declinedDateTime: metadata.recipient.declinedDateTime
        ? new Date(metadata.recipient.declinedDateTime)
        : null,
    },
  };
};

export const useGetReadyToSignOnboardingFormsRecipients = (
  clientId: ClientId,
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const query = useSlobQuery<ReadyToSignOnboardingFormsMetadata[]>({
    method: "get",
    path: `/api/clients/${clientId}/onboarding-forms/ready-to-sign-recipients`,
    map: (c) => c.map(jsonReadyToSignToReadyToSignMeta),
    options: { placeholderData: keepPreviousData, enabled },
  });
  return query;
};

export type AsyncCreateRecipientEmbeddedView = ReturnType<
  typeof useCreateRecipientEmbeddedView
>["mutateAsync"];

export const useCreateRecipientEmbeddedView = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    void,
    CreateEmbeddedViewOutput,
    `/api/clients/:clientId/onboarding-forms/:onboardingFormId/embedded-view/:recipientId`
  >({
    method: "post",
    path: `/api/clients/:clientId/onboarding-forms/:onboardingFormId/embedded-view/:recipientId`,
    options: {
      async onSuccess(_, variables) {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey([
            "get",
            `/api/clients/${variables.params.clientId}/onboarding-forms`,
          ]),
        });
      },
    },
    map: (recipientView) => recipientView,
  });
};

export const useGetRecipientCorrectionView = (
  clientId: ClientId | undefined,
  onboardingFormId: OnboardingFormId,
) => {
  return useSlobQuery<RecipientView>({
    method: "get",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/correction-view`,
    options: {
      enabled: Boolean(clientId) && Boolean(onboardingFormId),
      gcTime: 0,
    },
    map: (recipientView) => recipientView,
  });
};

export const useTemplateLookup = (clientId: ClientId, templateId: string) =>
  useSlobQuery<TemplateLookup>({
    method: "get",
    options: { enabled: false, retry: 0 },
    path: `/api/clients/${clientId}/onboarding-forms/template/${templateId}`,
    map: (template) => template,
  });

export const useCreateOnboardingForm = (clientId: ClientId) => {
  return useSlobMutation<{ templateId: string }, OnboardingForm>({
    method: "post",
    path: `/api/clients/${clientId}/onboarding-forms`,
  });
};

export type SyncOnboardingFormFunc = ReturnType<typeof useSyncOnboardingForm>["mutateAsync"];

export const useSyncOnboardingForm = (clientId: ClientId, onboardingFormId: OnboardingFormId) => {
  const queryClient = useQueryClient();
  return useSlobMutation<void, OnboardingForm>({
    method: "post",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/sync-envelope`,
    options: {
      onSuccess: async function () {
        await queryClient.invalidateQueries({
          queryKey: ["get", `/api/clients/${clientId}/setup`],
        });
      },
    },
  });
};

export const useEmbeddedViewAction = (
  clientId: ClientId,
  onboardingFormId: OnboardingFormId,
  recipientId: RecipientId,
) => {
  return useSlobMutation<{ event: string }, boolean>({
    method: "put",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/embedded-view-action/${recipientId}`,
  });
};

export const useDownloadOnboardingForm = (
  clientId: ClientId,
  onboardingFormId: OnboardingFormId,
) => {
  return useSlobQuery<DocumentDownloadUrl>({
    method: "get",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/download`,
    requestConfig: { responseType: "blob" },
    mapBlob: blobDocumentToObjectUrl,
    options: {
      enabled: false,
      gcTime: 0,
    },
  });
};

export type OnboardingFormDownloadDocumentFunc = ReturnType<
  typeof useOnboardingFormDownloader
>["downloadDocument"];

export function useOnboardingFormDownloader(
  clientId: ClientId,
  onboardingFormId: OnboardingFormId,
) {
  const {
    isInitialLoading: isInitialLoadingDocumentURL,
    isFetching: isFetchingDocumentURL,
    error: errorDocumentURL,
    data: documentURL,
    refetch: refetchDocumentUrl,
  } = useDownloadOnboardingForm(clientId, onboardingFormId);

  useDownloadDocument(documentURL);

  return {
    isDownloading: isInitialLoadingDocumentURL || isFetchingDocumentURL,
    errorLoadingDocument: errorDocumentURL,
    downloadDocument: refetchDocumentUrl,
  };
}

export function useOnboardingFormIndividualDocumentDownload(
  clientId: ClientId,
  onboardingFormId: OnboardingFormId,
) {
  const [onboardingFormDocumentId, setOnboardingFormDocumentId] =
    useState<OnboardingFormDocumentId | null>(null);

  const {
    isInitialLoading: isInitialLoadingDocumentURL,
    isFetching: isFetchingDocumentURL,
    error: errorDocumentURL,
    data: documentURL,
  } = useSlobQuery<DocumentDownloadUrl>({
    method: "get",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/download/${onboardingFormDocumentId}`,
    requestConfig: { responseType: "blob" },
    mapBlob: blobDocumentToObjectUrl,
    options: {
      enabled: !!onboardingFormDocumentId,
      gcTime: 0,
    },
  });

  useDownloadDocument(documentURL);

  return {
    isIndividualDocDownloading: isInitialLoadingDocumentURL || isFetchingDocumentURL,
    errorLoadingIndividualDocument: errorDocumentURL,
    downloadIndividualDocument: setOnboardingFormDocumentId,
  };
}

export const useDeleteOnboardingForm = (clientId: ClientId, onboardingFormId: OnboardingFormId) => {
  const queryClient = useQueryClient();

  return useSlobMutation<void, boolean>({
    method: "delete",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}`,
    options: {
      onSuccess: async function () {
        await queryClient.invalidateQueries({
          queryKey: ["get", `/api/clients/${clientId}/onboarding-forms`],
        });
      },
    },
  });
};

export const useReassignRecipients = (clientId: ClientId, onboardingFormId: OnboardingFormId) => {
  return useSlobMutation<ReassignRecipientInput, boolean>(
    {
      method: "put",
      path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/reassign-recipient`,
      options: {
        retry: false,
      },
    },
    {
      // This request has a big dependency of the envelope number of documents and size.
      // If the envelope has a lot of documents it require like ~40 seconds from DocuSign
      timeout: 60000,
    },
  );
};

export const useAddRecipientToForm = (clientId: ClientId, onboardingFormId: OnboardingFormId) => {
  return useSlobMutation<AddRecipientInput, boolean>({
    method: "post",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/recipient`,
  });
};

export const useFillFormProgressBar = (enable: boolean) => {
  const [progressBarValue, setProgressBarValue] = useState(0);
  const isRunningRef = useRef(false);
  const isProgressBarRunning = progressBarValue > 0 && progressBarValue < 100;
  const intervalDelay = 1000;

  useEffect(() => {
    if (!enable && isRunningRef.current) {
      setProgressBarValue(99);

      const endTimeout = setTimeout(() => {
        isRunningRef.current = false;
        setProgressBarValue(100);
      }, intervalDelay + 100);

      return () => clearTimeout(endTimeout);
    }

    if (enable && !isRunningRef.current) {
      isRunningRef.current = true;
      const progressTimeout = setInterval(
        () =>
          setProgressBarValue((currentValue) => {
            if (currentValue >= 100) {
              clearInterval(progressTimeout);
            }

            return currentValue + 2;
          }),
        intervalDelay,
      );
      return () => clearInterval(progressTimeout);
    }
  }, [enable]);

  return { progressBarValue, isProgressBarRunning };
};

export const useGetOnboardingFormLockStatus = (
  clientId: ClientId,
  onboardingFormId: OnboardingFormId,
) => {
  return useSlobQuery<OnboardingFormLockStatus>({
    method: "get",
    path: `/api/clients/${clientId}/onboarding-forms/${onboardingFormId}/lockStatus`,
    options: {
      enabled: false,
      gcTime: 0,
    },
    map: (onboardingFormLockStatus) => onboardingFormLockStatus,
  });
};
