import { timezoneAdjustedMoment } from "client/src/utils/moment";
import moment from "moment";
import numeral from "numeral";

/**
 * Formats a number as a percentage.
 * @param value The number to format as a percentage.
 * @param precision The number of decimal places to include.
 * @returns The formatted percentage.
 */
export const formatPercentage = (value: number, precision = 0) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  const result = formatter.format(value / 100);
  return result;
};

export const formatCurrency = (value?: unknown) => numeral(value).format("$0,0");
export const formatCurrencyWithCents = (value?: unknown) => numeral(value).format("$0,0.00");
export const formatCurrencyWithConditionalCents = (value: number) => {
  return value % 1 === 0 ? formatCurrency(value) : formatCurrencyWithCents(value);
};

export const formatMonthDayDate = (value?: moment.MomentInput) => moment(value).format("MM/D");
export const formatLongMonthDayDate = (value?: moment.MomentInput) =>
  timezoneAdjustedMoment(moment(value)).format("MMMM D");
export const formatLongMonthDayLongDate = (value?: moment.MomentInput) =>
  moment(value).format("MMMM Do");
export const formatYearDate = (value?: moment.MomentInput) => moment(value).format("YYYY");
export const formatLongMonthDayYearDate = (value?: moment.MomentInput) =>
  moment(value).format("MMMM D, YYYY");
