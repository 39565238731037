import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { compareQueryKey, useSlobMutation, useSlobQuery } from "client/src/hooks/query";
import type { JsonToTypeMapper, ResponseError } from "client/src/hooks/query";
import type { ClientId } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type {
  CreateEmployeeClassFromQPSInput,
  MapAndSyncQPSClassesInput,
  QPSClass,
  QPSPlanDesignInfo,
} from "shared/types/QPSClass";

const jsonQPSPlanDesignInfoToQPSPlanDesignInfo: JsonToTypeMapper<QPSPlanDesignInfo> = (
  planDesignInfo,
) => planDesignInfo;

export const jsonQPSClassToQPSClass: JsonToTypeMapper<QPSClass> = (qpsClass) => {
  return {
    ...qpsClass,
    createdAt: new Date(qpsClass.createdAt),
    updatedAt: new Date(qpsClass.updatedAt),
    deletedAt: qpsClass.deletedAt ? new Date(qpsClass.deletedAt) : null,
  };
};

export function useSyncWithQPS() {
  const query = useSlobMutation<void, void, `/api/clients/:clientId/sync-qps`>({
    method: "post",
    path: `/api/clients/:clientId/sync-qps`,
  });
  return query;
}

export type GetQPSPlanDesignInfo = ReturnType<typeof useGetQPSPlanDesignInfo>["refetch"];

export function useGetQPSPlanDesignInfo(clientId: ClientId, { enabled }: { enabled: boolean }) {
  return useSlobQuery<QPSPlanDesignInfo>({
    method: "get",
    path: `/api/clients/${clientId}/qps/plan-design-info`,
    map: (response) => jsonQPSPlanDesignInfoToQPSPlanDesignInfo(response),
    options: {
      placeholderData: keepPreviousData,
      enabled,
      refetchOnWindowFocus: false,
    },
  });
}

export type CreateEmployeeClassFromQPSFunc = ReturnType<
  typeof useCreateEmployeeClassFromQPSBasicClasses
>["mutateAsync"];

export function useCreateEmployeeClassFromQPSBasicClasses() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    CreateEmployeeClassFromQPSInput,
    EmployeeClass,
    `/api/clients/:clientId/qps/employee-classes`,
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/qps/employee-classes`,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
  return query;
}

export type MapAndSyncQPSClassesFunc = ReturnType<typeof useMapAndSyncQPSClasses>["mutateAsync"];

export function useMapAndSyncQPSClasses() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    MapAndSyncQPSClassesInput,
    void,
    `/api/clients/:clientId/qps/map-&-sync`
  >({
    method: "post",
    path: `/api/clients/:clientId/qps/map-&-sync`,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/map-&-sync`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
  return query;
}
