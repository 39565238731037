export let vitePreloadError = false;

export function handlePreloadErrors() {
  // To handle when hosting deletes the assets from previous deployments
  // https://vitejs.dev/guide/build#load-error-handling
  window.addEventListener("vite:preloadError", (_event) => {
    vitePreloadError = true;
    window.location.reload();
  });
}
