import type { JsonToTypeMapper } from "../query";
import type { ExplorerPage, PublicExplorerPage } from "shared/types/ExplorerPage";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

export const jsonExplorerPageToExplorerPage: JsonToTypeMapper<ExplorerPage> = (page) => {
  return {
    ...page,
    createdAt: new Date(page.createdAt),
    updatedAt: new Date(page.updatedAt),
    deletedAt: page.deletedAt ? new Date(page.deletedAt) : null,
    startsAt: page.startsAt ? new Date(page.startsAt) : null,
    expiresAt: page.expiresAt ? new Date(page.expiresAt) : null,
    openEnrollmentStart: page.openEnrollmentStart ? new Date(page.openEnrollmentStart) : null,
    openEnrollmentEnd: page.openEnrollmentEnd ? new Date(page.openEnrollmentEnd) : null,
    benefits: page.benefits
      ? page.benefits.map((benefit) => jsonExplorerPageBenefitToExplorerPageBenefit(benefit))
      : [],
    benAdminPlatform: page.benAdminPlatform
      ? {
          ...page.benAdminPlatform,
          createdAt: new Date(page.benAdminPlatform.createdAt),
          updatedAt: new Date(page.benAdminPlatform.updatedAt),
        }
      : null,
  };
};

export const jsonPublicExplorerPageToPublicExplorerPage: JsonToTypeMapper<PublicExplorerPage> = (
  page,
) => {
  return {
    ...page,
    createdAt: new Date(page.createdAt),
    updatedAt: new Date(page.updatedAt),
    deletedAt: page.deletedAt ? new Date(page.deletedAt) : null,
    startsAt: page.startsAt ? new Date(page.startsAt) : null,
    expiresAt: page.expiresAt ? new Date(page.expiresAt) : null,
    openEnrollmentStart: page.openEnrollmentStart ? new Date(page.openEnrollmentStart) : null,
    openEnrollmentEnd: page.openEnrollmentEnd ? new Date(page.openEnrollmentEnd) : null,
    benefits: page.benefits
      ? page.benefits.map((benefit) => jsonExplorerPageBenefitToExplorerPageBenefit(benefit))
      : [],
    benAdminPlatform: page.benAdminPlatform
      ? {
          ...page.benAdminPlatform,
          createdAt: new Date(page.benAdminPlatform.createdAt),
          updatedAt: new Date(page.benAdminPlatform.updatedAt),
        }
      : null,
    newExplorerPageStartDate: page.newExplorerPageStartDate
      ? new Date(page.newExplorerPageStartDate)
      : null,
    newExplorerPageEndDate: page.newExplorerPageEndDate
      ? new Date(page.newExplorerPageEndDate)
      : null,
    newExplorerPagePlanYear: page.newExplorerPagePlanYear,
    prevExplorerPageId: page.prevExplorerPageId,
    prevExplorerPageVanitySlug: page.prevExplorerPageVanitySlug,
    prevExplorerPageCustomBranding: page.prevExplorerPageCustomBranding,
    prevExplorerPageExpiry: page.prevExplorerPageExpiry
      ? new Date(page.prevExplorerPageExpiry)
      : null,
    prevExplorerPagePlanYear: page.prevExplorerPagePlanYear,
  };
};

export const jsonExplorerPageBenefitToExplorerPageBenefit: JsonToTypeMapper<ExplorerPageBenefit> = (
  benefit,
) => {
  return {
    ...benefit,
    createdAt: new Date(benefit.createdAt),
    updatedAt: new Date(benefit.updatedAt),
    deletedAt: benefit.deletedAt ? new Date(benefit.deletedAt) : null,
    financialFSAMoneyRolloverDate: benefit.financialFSAMoneyRolloverDate
      ? new Date(benefit.financialFSAMoneyRolloverDate)
      : null,
  };
};
