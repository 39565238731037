import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { Plan } from "shared/types/Plan";

export function getTerminationOfInsuranceRelevantPlans(clientPlans: Plan[]) {
  const relevantPlans = clientPlans.filter((clientPlan) => {
    const benefitTypeToInclude: BenefitTypeEIF[] = ["DENTAL", "VISION", "ASO_DENTAL"];
    const isIncluded = benefitTypeToInclude.includes(clientPlan.benefitType);
    return isIncluded;
  });
  return relevantPlans;
}
