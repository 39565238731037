import clsx from "clsx";
import * as styles from "./error.module.less";

import type { ReactNode } from "react";

type ErrorMessageProps = {
  id?: string;
  size?: "middle" | "large";
  children: ReactNode;
};

export const ErrorMessage = ({ children, size = "middle", ...rest }: ErrorMessageProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        styles.error,
        size === "middle" && styles.sizeMiddle,
        size === "large" && styles.sizeLarge,
      )}
    >
      {children}
    </div>
  );
};

export const genericErrorCopy = `Oops! Something went wrong. Please refresh the page and try again. If that doesn’t help, email support@onboard.sunlife.com`;

export const genericErrorCopy2 = `Oops! Something went wrong. Please try again. If that doesn’t help, email support@onboard.sunlife.com`;

export const GenericErrorCopy = (
  <>
    Oops! Something went wrong. Please refresh the page and try again. If that doesn’t help, email{" "}
    <a href="mailto:support@onboard.sunlife.com">support@onboard.sunlife.com</a>.
  </>
);

export const GenericErrorCopy2 = (
  <>
    Oops! Something went wrong. Please try again. If that doesn’t help, email{" "}
    <a href="mailto:support@onboard.sunlife.com">support@onboard.sunlife.com</a>.
  </>
);
