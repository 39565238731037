import { useIsBenEx } from "client/src/hooks/ExplorerPage/useIsBenEx";
import { useLayoutEffect } from "react";

/**
 * The useBenExCustomBranding hook is used to manually show custom branding to the Benefits Explorer pages once _ready_.
 */
export const useBenExCustomBranding = () => {
  const isBenEx = useIsBenEx();

  useLayoutEffect(() => {
    if (isBenEx) document.body.style.opacity = "0";
  }, [isBenEx]);

  const ready = () => {
    // eslint-disable-next-line react-compiler/react-compiler -- Needed
    document.body.style.transition = "opacity 0.5s ease";
    document.body.style.opacity = "1";
  };

  return { ready };
};
