import { Spinner } from "client/src/components/Spinner/Spinner";
import clsx from "clsx";
import React from "react";

import { type To, Link } from "react-router-dom";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import * as styles from "./button.module.less";

declare const ButtonHTMLTypes: ["submit", "button", "reset"];
export declare type ButtonHTMLType = (typeof ButtonHTMLTypes)[number];
export type ButtonSize = "xtra-small" | "small" | "middle" | "large" | undefined;

export type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "text"
  | "text-only"
  | "text-only-light"
  | "text-only-link"
  | "text-only-link-light"
  | "danger"
  | "danger-tertiary"
  | "default"
  | "danger-text-only"
  | undefined;

type BaseButtonProps = {
  id?: string;
  type?: ButtonType;
  icon?: React.ReactNode;
  size?: ButtonSize;
  children?: React.ReactNode;
  role?: "button" | "link";
  iconPosition?: "left" | "right";
  disabled?: boolean;
  /** Removes `min-width` making button of size of text with only its padding as internal space.*/
  shrink?: boolean;
  /** Adds width 100% making button fill its container. */
  block?: boolean;
  /** Call to Action adds min-width 200px. Prominent in places like child page modules and modals. */
  action?: boolean;
  rounded?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  download?: string;
  style?: React.CSSProperties;
  /** @deprecated Do not use class directly */
  className?: string; // @todo: remove after dropdown work. AntD dropdown needs to change button class to work properlly
};

type DefaultButtonProps = BaseButtonProps & {
  htmlType?: "button" | "submit" | "reset";
  loading?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  form?: string;

  to?: never;
  newTab?: never;
  href?: never;
};

type RouterButtonProps = BaseButtonProps & {
  /** To be used with react-router routes. For external links, use `href`. */
  to: To;
  state?: unknown;
  newTab?: boolean;
  baseClasses?: string;

  href?: never;
  htmlType?: never;
  form?: never;
  loading?: never;
  onKeyDown?: never;
  "aria-label"?: string;
};

type ExternalLinkButtonProps = BaseButtonProps & {
  /** To be used with external routes. For react-router routes, use `to`. */
  href: string;
  newTab?: boolean;
  baseClasses?: string;

  to?: never;
  htmlType?: never;
  form?: never;
  loading?: never;
  onKeyDown?: never;
};

export type ButtonProps = DefaultButtonProps | RouterButtonProps | ExternalLinkButtonProps;

const RouterButton = (props: RouterButtonProps) => {
  const {
    icon,
    children,
    disabled,
    onClick,
    to,
    state,
    newTab,
    baseClasses,
    iconPosition,
    "aria-label": ariaLabel,
  } = props;

  const handleOnClick: React.MouseEventHandler<HTMLElement> = (e) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    onClick?.(e);
  };

  return (
    <Link
      to={to}
      aria-disabled={disabled}
      className={clsx(styles.linkComponent, baseClasses)}
      target={newTab ? "_blank" : undefined}
      onClick={handleOnClick}
      state={state}
      aria-label={ariaLabel}
    >
      <span className={styles.content}>
        {icon && iconPosition === "left" && (
          <span className={clsx(styles.icon, "mr-4")}>{icon}</span>
        )}
        {children}
        {icon && iconPosition === "right" && (
          <span className={clsx(styles.icon, "ml-4")}>{icon}</span>
        )}
      </span>
    </Link>
  );
};

const ExternalLinkButton = (props: ExternalLinkButtonProps) => {
  const { icon, children, disabled, onClick, href, newTab, baseClasses, iconPosition } = props;

  const handleOnClick: React.MouseEventHandler<HTMLElement> = (e) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    onClick?.(e);
  };

  return (
    <a
      href={href}
      aria-disabled={disabled}
      className={clsx(styles.linkComponent, baseClasses)}
      target={newTab ? "_blank" : undefined}
      onClick={handleOnClick}
      rel="noopener noreferrer"
    >
      <span className={styles.content}>
        {icon && iconPosition === "left" && (
          <span className={clsx(styles.icon, "mr-4")}>{icon}</span>
        )}
        {children}
        {icon && iconPosition === "right" && (
          <span className={clsx(styles.icon, "ml-4")}>{icon}</span>
        )}
      </span>
    </a>
  );
};

export const Button = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      type = "default",
      size = "small",
      htmlType = "button",
      role,
      form,
      icon,
      iconPosition = "left",
      shrink,
      block,
      action,
      children,
      rounded,
      disabled,
      loading,
      onClick,
      onKeyDown,
      to,
      href,
      newTab,
      style,
      className,
      ...rest
    } = props;

    const isDisabled = disabled || !!loading;
    const isTypeText =
      type === "text" ||
      type === "text-only-link" ||
      type === "text-only-link-light" ||
      type === "text-only" ||
      type === "text-only-light" ||
      type === "danger-text-only";
    const shouldShrink = shrink == null ? isTypeText : shrink;
    const iconLeft = iconPosition === "left";
    const iconRight = iconPosition === "right";

    const baseClasses = clsx(
      styles.button,
      getTypeClass(type),
      getSizeClass(size),
      shouldShrink && styles.shrink,
      block && styles.block,
      rounded && styles.rounded,
      action && styles.callToAction,
      className,
    );

    if (to) return <RouterButton baseClasses={baseClasses} {...props} />;
    if (href) return <ExternalLinkButton baseClasses={baseClasses} {...props} />;

    const handleOnClick: React.MouseEventHandler<HTMLElement> = (e) => {
      if (isDisabled) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      onClick?.(e);
    };

    return (
      <button
        ref={ref}
        aria-disabled={isDisabled || undefined}
        onClick={handleOnClick}
        type={htmlType}
        role={role}
        form={form}
        className={baseClasses}
        onKeyDown={onKeyDown}
        style={style}
        {...rest}
      >
        <span className={styles.content}>
          {!isTypeText && (
            <>
              {loading && (
                <span className={clsx(styles.loading)}>
                  <Spinner
                    size={getSpinnerSize(size)}
                    initialColor="transparent"
                    color={getSpinnerColor(type)}
                    opaque
                  />
                </span>
              )}
              {icon && iconLeft && <span className={clsx(styles.icon, "mr-4")}>{icon}</span>}
            </>
          )}
          {isTypeText && (
            <>
              {icon && iconLeft && !loading && (
                <span className={clsx(styles.icon, "mr-4")}>{icon}</span>
              )}
              {loading && (
                <span className={clsx(styles.icon, "mr-4")}>
                  <Spinner
                    size={getSpinnerSize(size)}
                    initialColor="transparent"
                    color={getSpinnerColor(type)}
                    opaque
                  />
                </span>
              )}
            </>
          )}
          {children}
          {icon && iconRight && !loading && (
            <span className={clsx(styles.icon, "ml-4")}>{icon}</span>
          )}
        </span>
      </button>
    );
  },
);

const getTypeClass = (type: ButtonType) => {
  switch (type) {
    case undefined:
    case "default":
    case "tertiary":
      return styles.tertiary;
    case "primary":
      return styles.primary;
    case "secondary":
      return styles.secondary;
    case "danger":
      return styles.danger;
    case "danger-tertiary":
      return styles.dangerTertiary;
    case "text":
      return styles.text;
    case "text-only-link":
      return styles.textOnlyLink;
    case "text-only-link-light":
      return styles.textOnlyLinkLight;
    case "text-only":
      return styles.textOnly;
    case "text-only-light":
      return styles.textOnlyLight;
    case "danger-text-only":
      return styles.dangerTextOnly;
    default:
      exhaustiveCheck(type);
      break;
  }
};

const getSizeClass = (size: ButtonSize) => {
  switch (size) {
    case "xtra-small":
      return styles.xtraSmall;
    case undefined:
    case "small":
      return styles.small;
    case "middle":
      return styles.middle;
    case "large":
      return styles.large;
    default:
      exhaustiveCheck(size);
      break;
  }
};

const getSpinnerSize = (size: ButtonSize) => {
  switch (size) {
    case "xtra-small":
    case undefined:
    case "small":
      return "xxsmall";
    case "middle":
    case "large":
      return "xsmall";
    default:
      exhaustiveCheck(size);
      break;
  }
};

const getSpinnerColor = (type: ButtonType) => {
  switch (type) {
    case undefined:
    case "default":
    case "tertiary":
    case "text":
    case "text-only-link":
    case "text-only-link-light":
    case "text-only":
    case "text-only-light":
      return "tertiary";
    case "primary":
      return "primary";
    case "secondary":
      return "secondary";
    case "danger":
      return "danger";
    case "danger-text-only":
    case "danger-tertiary":
      return "dangerTertiary";
    default:
      exhaustiveCheck(type);
      break;
  }
};
