import { SlobPopover } from "client/src/components/SlobPopover/SlobPopover";
import { GenericErrorCopy } from "../Error/ErrorMessage";
import { Spinner } from "../Spinner/Spinner";
import { Body3 } from "../Typography/Typography";

import type { ReactNode } from "react";
import type { User } from "shared/types/User";

export type UpdatedByPopoverProps = {
  loading?: boolean;
  error?: unknown;
  updatedByUser: User | null | undefined;
  updatedAt: Date | null | undefined;
  children?: ReactNode;
};

export function UpdatedByPopover({
  loading,
  error,
  updatedByUser,
  updatedAt,
  children,
}: UpdatedByPopoverProps) {
  return (
    <SlobPopover
      variant="secondary"
      content={
        <>
          {loading && (
            <div className="text-center">
              <Spinner size="small" /> <br />
              <Body3>Loading update data...</Body3>
            </div>
          )}

          {!loading && error && <Body3 redMedium>{GenericErrorCopy}</Body3>}

          {!loading &&
            !error &&
            (updatedByUser ? (
              <>
                <Body3 as="div">
                  Updated by {updatedByUser.firstName} {updatedByUser.lastName}
                </Body3>
                <Body3 as="div">({updatedByUser.email})</Body3>
                <Body3 as="div">
                  {updatedAt?.toLocaleDateString()} {updatedAt?.toLocaleTimeString()}
                </Body3>
              </>
            ) : (
              <Body3>Updated by the Sun Life team</Body3>
            ))}
        </>
      }
      side="bottom"
    >
      <button className="btn-reset" aria-label="Click to see tracking information">
        {children}
      </button>
    </SlobPopover>
  );
}
