import { useRef, useEffect } from "react";

export function useCloseOnLeaveFocus(props: {
  popoverVisible: boolean;
  setPopoverVisible: (visible: boolean) => void;
}) {
  const { popoverVisible, setPopoverVisible } = props;

  const setPopoverVisibleRef = useRef<(visible: boolean) => void>();
  useEffect(() => {
    setPopoverVisibleRef.current = setPopoverVisible;
  }, [setPopoverVisible]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!popoverVisible) {
      return;
    }

    function onFocusIn() {
      const containerContainsCurrentlyFocusedElement = containerRef.current?.contains(
        document.activeElement,
      );
      if (!containerContainsCurrentlyFocusedElement) {
        setPopoverVisibleRef.current?.(false);
      }
    }

    const useCapture = true;
    document.addEventListener("focusin", onFocusIn, useCapture);

    return () => {
      document.removeEventListener("focusin", onFocusIn);
    };
  }, [popoverVisible]);

  return containerRef;
}
