import { useLogout } from "client/src/auth/oktaAuth";
import { ErrorBanner } from "client/src/components/Banner/ErrorBanner";

import { Loading } from "client/src/components/Loading/Loading";
import { NavBarSkeleton } from "client/src/components/NavBar/NavBar";
import { useIsBenEx } from "client/src/hooks/ExplorerPage/useIsBenEx";
import { useSlobAuth } from "client/src/hooks/auth";
import { useGetActiveRouteData } from "client/src/hooks/route";
import { useGetServiceStatus } from "client/src/hooks/serviceCheck";
import { RouteData, VIEW_TYPE } from "shared/config/routeData";
import { getIsInternalUser } from "shared/rbac/rbac";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";

import { AuthNavPage } from "./AuthNavPage";
import { BasicNav } from "./BasicNav";
import { BenAdminNavPage } from "./BenAdminNavPage";
import { BrokerNavPage } from "./BrokerNav";
import { ExplorerNavPage } from "./ExplorerNav";
import { HubNavPage } from "./HubNav";
import { InternalNavPage } from "./InternalNav";
import * as styles from "./navigation.module.less";
import type { HubNavRouteType } from "./HubNav";
import type { LogoutPostType } from "client/src/auth/oktaAuth";

export type logOutType = LogoutPostType | (() => Promise<void>);

export const NavBarLoading = () => {
  const isBenEx = useIsBenEx();
  return (
    <>
      <nav>
        {isBenEx ? null /* As BenEx pages can be custom branded, we don't want to render anything before checking custom branding on client */ : (
          <NavBarSkeleton />
        )}
      </nav>
      <main>
        <Loading />
      </main>
    </>
  );
};

export const NavBarPage = () => {
  const activeRouteData = useGetActiveRouteData();

  const { authUser, isLoading } = useSlobAuth();
  const { mutateAsync: logOut } = useLogout();
  const isInternalUser = getIsInternalUser(authUser);

  if (isLoading) {
    return <NavBarLoading />;
  }

  let navBar;
  switch (activeRouteData?.viewType) {
    case VIEW_TYPE.auth:
      navBar = <AuthNavPage />;
      break;

    case VIEW_TYPE.internal: {
      navBar = <InternalNavPage logOut={logOut} />;
      break;
    }
    case VIEW_TYPE.hub: {
      const clientId = activeRouteData.params.clientId;
      if (!clientId) {
        return null;
      }
      const welcomeRoutePath = RouteData.welcome.getPath(clientId);
      const routeType: HubNavRouteType =
        welcomeRoutePath === activeRouteData.path ? "welcome" : "normal";
      navBar = <HubNavPage clientId={clientId} logOut={logOut} routeType={routeType} />;
      break;
    }
    case VIEW_TYPE.broker: {
      const clientId = activeRouteData.params.clientId;
      navBar = <BrokerNavPage clientId={clientId} logOut={logOut} />;
      break;
    }
    case VIEW_TYPE.benAdmin: {
      const clientId = activeRouteData.params.clientId;
      navBar = <BenAdminNavPage clientId={clientId} logOut={logOut} />;
      break;
    }
    case VIEW_TYPE.basic: {
      navBar = <BasicNav logOut={logOut} />;
      break;
    }
    case VIEW_TYPE.clear: {
      navBar = <BasicNav />;
      break;
    }
    case VIEW_TYPE.explorer: {
      const explorerPageId = activeRouteData.params.explorerPageId;
      if (!explorerPageId) {
        return null;
      }
      navBar = <ExplorerNavPage pageKey={explorerPageId} />;
      break;
    }
    case VIEW_TYPE.outside_signer: {
      const clientId = activeRouteData.params.clientId;
      if (!clientId) {
        return null;
      }
      navBar = <BasicNav />;
      break;
    }
    default:
      if (!activeRouteData) {
        break;
      }
      exhaustiveCheck(activeRouteData.viewType);
  }

  return (
    <>
      <nav className={activeRouteData?.viewType === VIEW_TYPE.explorer ? styles.sticky : undefined}>
        {isInternalUser && <ServiceStatus />}
        {navBar}
      </nav>
    </>
  );
};

const ServiceStatus = () => {
  const { data: serviceStatus } = useGetServiceStatus();

  if (serviceStatus?.jira === false) {
    return (
      <ErrorBanner message="Jira is currently down. Some functionality in Onboard might be unavailable at this time." />
    );
  }
  return null;
};
