import { Button } from "client/src/components/Button/Button";
import { FormInput } from "client/src/components/Form/Input";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Modal } from "client/src/components/Modal/Modal";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useTranslation } from "client/src/i18n";
import { hasFormikError } from "client/src/utils/hasFormikError";
import { LocationStateData } from "shared/data/LocationState";
import { LocationStateCodes } from "shared/types/Location";
import { leadGenerationRequestValidation } from "shared/validation/explorerPage";

import type { Pretty } from "shared/types/Helper";
import type { InferType } from "yup";

type ExplorerLeadGenerationModalProps = {
  visible: boolean;
  showConfirmation: boolean;
  isLoading: boolean;
  onSave: (lang: Pretty<InferType<typeof leadGenerationRequestValidation>>) => Promise<void>;
  onCancel?: () => void;
};

export type ExplorerLeadGenerationCopyStructure = {
  heading: string;
  content: string;
  confirmHeading: string;
  confirmContent: string;
  submit: string;
  cancel: string;
  close: string;
};

export const ExplorerLeadGenerationModal = ({
  visible,
  showConfirmation,
  isLoading,
  onSave,
  onCancel,
}: ExplorerLeadGenerationModalProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerLeadGeneration" });
  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      city: "",
      state: "",
      phone: "",
      externalUser: "",
      employerName: "",
      brokerName: null,
    },
    validationSchema: leadGenerationRequestValidation,
    onSubmit: async (values) => {
      try {
        await onSave(values);
        formik.resetForm();
      } catch (e) {
        if (typeof e === "string") formik.setFieldError("email", e);
      }
    },
  });
  return (
    <Modal
      open={visible}
      footer={null}
      title={(showConfirmation ? t("confirmHeading") : t("heading")) ?? ""}
      onCancel={onCancel}
    >
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={16}>
          {!showConfirmation && (
            <>
              <Row>
                <Col>
                  <StackY dist={24} style={{ width: "100%" }}>
                    <Body3>{t("content")}</Body3>
                    <FormInput
                      label="Email"
                      value={formik.values.email || ""}
                      maxLength={191}
                      name="email"
                      touched={formik.touched.email}
                      showRequired={true}
                      onChange={formik.handleChange}
                      error={hasFormikError(formik, "email") ? "Email is required" : undefined}
                      disabled={formik.isSubmitting}
                    />
                    <FormInput
                      label="First name"
                      value={formik.values.firstName || ""}
                      maxLength={191}
                      name="firstName"
                      touched={formik.touched.firstName}
                      showRequired={true}
                      onChange={formik.handleChange}
                      error={
                        hasFormikError(formik, "firstName") ? "First name is required" : undefined
                      }
                      disabled={formik.isSubmitting}
                    />
                    <FormInput
                      label="Last name"
                      value={formik.values.lastName || ""}
                      maxLength={191}
                      name="lastName"
                      touched={formik.touched.lastName}
                      showRequired={true}
                      onChange={formik.handleChange}
                      error={
                        hasFormikError(formik, "lastName") ? "Last name is required" : undefined
                      }
                      disabled={formik.isSubmitting}
                    />
                    <FormInput
                      label="City"
                      value={formik.values.city || ""}
                      maxLength={191}
                      name="city"
                      touched={formik.touched.city}
                      showRequired={true}
                      onChange={formik.handleChange}
                      error={hasFormikError(formik, "city") ? "City is required" : undefined}
                      disabled={formik.isSubmitting}
                    />
                    <SlobSelect
                      name="state"
                      placeholder="State"
                      showRequired={true}
                      value={formik.values.state || null}
                      options={LocationStateCodes.map((stateCode) => ({
                        label: LocationStateData[stateCode].displayName,
                        value: stateCode,
                      }))}
                      onChange={(val) => {
                        void formik.setFieldValue("state", val.value);
                      }}
                      error={hasFormikError(formik, "state") ? "State is required" : undefined}
                      touched={true}
                      fillWidth
                    />
                    <FormInput
                      label="Phone"
                      value={formik.values.phone || ""}
                      maxLength={191}
                      name="phone"
                      touched={formik.touched.phone}
                      showRequired={true}
                      onChange={formik.handleChange}
                      error={hasFormikError(formik, "phone") ? "Phone is required" : undefined}
                      disabled={formik.isSubmitting}
                    />
                    <RadioGroup
                      name="externalUser"
                      label="I am the:"
                      value={formik.values.externalUser}
                      onChange={async (e) => {
                        await formik.setFieldValue("externalUser", e.target.value);
                      }}
                      disabled={formik.isSubmitting}
                      touched={formik.touched.externalUser}
                      error={hasFormikError(formik, "externalUser") && "Please select an option"}
                      options={[
                        { value: "employer", label: "Employer" },
                        { value: "broker", label: "Broker" },
                      ]}
                    />
                    {formik.values.externalUser === "broker" && (
                      <FormInput
                        label="Broker name"
                        value={formik.values.brokerName || ""}
                        maxLength={191}
                        name="brokerName"
                        touched={formik.touched.brokerName}
                        showRequired={true}
                        onChange={formik.handleChange}
                        error={
                          hasFormikError(formik, "brokerName")
                            ? "Broker name is required"
                            : undefined
                        }
                        disabled={formik.isSubmitting}
                      />
                    )}
                    {formik.values.externalUser && (
                      <FormInput
                        label={
                          formik.values.externalUser === "broker" ? "Client name" : "Employer name"
                        }
                        value={formik.values.employerName || ""}
                        maxLength={191}
                        name="employerName"
                        touched={formik.touched.employerName}
                        showRequired={true}
                        onChange={formik.handleChange}
                        error={
                          hasFormikError(formik, "employerName")
                            ? formik.values.externalUser === "broker"
                              ? "Client name is required"
                              : "Employer name is required"
                            : undefined
                        }
                        disabled={formik.isSubmitting}
                      />
                    )}
                  </StackY>
                </Col>
              </Row>
              <Row justify="space-between" style={{ width: "100%" }}>
                <Col>
                  <StackX dist={16}>
                    <Button type="primary" size="middle" htmlType="submit" disabled={isLoading}>
                      {t("submit")}
                    </Button>
                    <Button size="middle" onClick={onCancel}>
                      {t("cancel")}
                    </Button>
                  </StackX>
                </Col>
              </Row>
            </>
          )}
          {showConfirmation && (
            <>
              <Row>
                <Col>
                  <Body3>{t("confirmContent")}</Body3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="primary" size="middle" onClick={onCancel}>
                    {t("close")}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </StackY>
      </form>
    </Modal>
  );
};
