import { useEffect } from "react";

import type { BlobToTypeMapper } from "./query";
import type { DocumentDownloadUrl } from "shared/types/Document";

export const downloadFile = (objectUrl: string, filename: string) => {
  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  link.click();
  link.remove();

  // delay revokeObjectUrl for Firefox
  setTimeout(() => {
    window.URL.revokeObjectURL(objectUrl);
  }, 250);
};

export function useDownloadDocument(documentDownloadURL: DocumentDownloadUrl | undefined): void {
  useEffect(() => {
    if (documentDownloadURL !== undefined) {
      downloadFile(documentDownloadURL.objectUrl, documentDownloadURL.filename);
    }
  }, [documentDownloadURL]);
}

// these method relies on the browser specific .createObjectURL method and needs to be mocked outside of e2e tests
export const blobDocumentToObjectUrl: BlobToTypeMapper<DocumentDownloadUrl> = (data, headers) => {
  const contentType = headers["content-type"];
  if (contentType == null) {
    throw new Error("Response missing content-type header");
  }
  const cdHeader: string | null = headers["content-disposition"];
  if (cdHeader == null) {
    throw new Error("Response missing content-disposition header");
  }

  const rawfilenameFromCdHeader = cdHeader.split("attachment; filename=")[1];
  if (!rawfilenameFromCdHeader) {
    throw new Error(
      "Response has malformed content-dispostion header; missing `attachment; filename=`",
    );
  }
  const filename = rawfilenameFromCdHeader.replace(/[/"\\]/g, "");

  // set the blob's mime-type explicitly for non-Chrome browsers
  const blob = new Blob([data], { type: contentType });
  const objectUrl = window.URL.createObjectURL(blob);
  return { filename, objectUrl };
};

type RenameFileOptions = { prefix?: string; postfix?: string; extension?: string };
export const renameFile = (name: string, options: RenameFileOptions): string => {
  const fragments = name.split(".");
  const extension = fragments.pop() || "";
  return (
    (options.prefix || "") +
    fragments.join(".") +
    (options.postfix || "") +
    "." +
    (options.extension || extension)
  );
};
