import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronCircleRight,
  faExternalLinkSquareAlt,
  faGlobe,
  faHeart as faHeartSolid,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackX } from "client/src/components/Spacing/Spacing";

import { ExplorerLanguageModal } from "client/src/domain/ExplorerPages/Modals/ClientPage/ExplorerLanguageModal";
import { ExplorerLeadGenerationModal } from "client/src/domain/ExplorerPages/Modals/ClientPage/ExplorerLeadGenerationModal";
import { useIsStuck } from "client/src/domain/ExplorerPages/utils";
import { useBenefitsData } from "client/src/hooks/ExplorerPage/useBenefitsData";
import { useGetPublicExplorerPageById } from "client/src/hooks/ExplorerPage/useExplorerPages";
import { useLeadGeneration } from "client/src/hooks/ExplorerPage/useLeadGeneration";
import { useExplorerPageLocation } from "client/src/hooks/useExplorerPageLocation";
import {
  useDHMOByLocation,
  useEmployeeLocation,
  useFavoritePlans,
  useFavoritePlansByLocation,
} from "client/src/hooks/useSlobLocalStorage";
import { useTranslation } from "client/src/i18n";
import { getBenefitsExplorerPath } from "client/src/utils/benefits-explorer";
import { getCSSVariableValue } from "client/src/utils/getCSSVariableValue";
import { getShowExplorerPageEnrollButton } from "client/src/utils/getShowExplorerPageEnrollButton";
import { timezoneAdjustedMoment } from "client/src/utils/moment";
import { PrintHidden } from "client/src/utils/print";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TopBanner } from "../Banner/TopBanner";
import { InternalLinkButton } from "../Button/InternalLinkButton";
import { Body1, Body3, Body5, Eyebrow, H4 } from "../Typography/Typography";

import * as styles from "./explorerNavBar.module.less";

import type { Dispatch, FC, MouseEventHandler, SetStateAction } from "react";
import type { PublicExplorerPage } from "shared/types/ExplorerPage";

export type ExplorerNavBarCopyStructure = {
  benAdminCallToAction: string;
  personalPlanCallToAction: string;
  benAdminCallToAction_externalLink: string;
  benAdminCallToActionNoBenefits: string;
  demoBannerBody: string;
  enrollmentStatus: string;
  english: string;
  spanish: string;
  leadGenerationBody: string;
  leadGenerationLink: string;
};

type ExplorerNavBarProps = {
  personalBenefitsPlanLink: string;
  pageKey: string;
};

const useNavBarRef = () => useRef(null);

export const ExplorerNavBar = ({ personalBenefitsPlanLink, pageKey }: ExplorerNavBarProps) => {
  const location = useExplorerPageLocation();
  const navigate = useNavigate();
  const currentDemoStatus = location.state?.demoEnrollmentStatus || "pre";
  const { t, i18n } = useTranslation({ keyPrefix: "ExplorerNavBar" });

  const { mutateAsync: sendLeadGenerationEmail, isPending } = useLeadGeneration();

  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [showLeadGenerationModal, setShowLeadGenerationModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { data: explorerPage } = useGetPublicExplorerPageById(pageKey, currentDemoStatus);
  const customBranding =
    !!explorerPage?.benefitsExplorerCustomBranding &&
    !!explorerPage.customBrandingFeatureToggle &&
    !!explorerPage.customBranding;
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();
  const dhmoBenefitData = explorerPage?.benefits?.filter((ben) => ben.planType === "DHMO");
  const newMexicoPrepaidDHMOData = useDHMOByLocation(
    employeePrepaidAndChatLocation,
    dhmoBenefitData,
  );
  const breakpoint = useBreakpoint();
  const isMobile = !breakpoint.md;
  const benefitsData = useBenefitsData(explorerPage, employeePrepaidAndChatLocation);

  const ref = useNavBarRef();
  const isSticky = useIsStuck(ref);

  const pageIsActive =
    !explorerPage?.maintenanceMode &&
    moment().isAfter(timezoneAdjustedMoment(moment(explorerPage?.startsAt)));

  const benefitsCount = benefitsData?.length ?? 0;

  const languageSelected = i18n.language === "es" ? "es" : "en";

  const benAdminUrl = explorerPage?.benAdminPlatformUrl ?? undefined;

  const showMobileEnrollButton =
    isMobile && explorerPage && getShowExplorerPageEnrollButton(explorerPage);

  return (
    <div
      ref={ref}
      className={clsx(
        styles.navBar,
        isSticky && styles.shadow,
        isMobile && styles.mobile,
        breakpoint,
      )}
    >
      {explorerPage && !explorerPage.isLive && (
        <>
          <TopBanner mobile={isMobile}>
            <StackX dist={16}>
              <Body5 colorPrimary>{t("demoBannerBody")}</Body5>
              {!explorerPage.newExplorerPageId && (
                <>
                  <Button
                    type="text-only-link"
                    shrink={false}
                    onClick={() => {
                      navigate(location, {
                        state: { demoEnrollmentStatus: "pre" },
                        replace: true,
                      });
                    }}
                  >
                    {t("enrollmentStatus", { currentDemoStatus: "pre-" })}
                  </Button>
                  <Button
                    type="text-only-link"
                    shrink={false}
                    onClick={() => {
                      navigate(location, {
                        state: { demoEnrollmentStatus: "active" },
                        replace: true,
                      });
                    }}
                  >
                    {t("enrollmentStatus", { currentDemoStatus: "mid-" })}
                  </Button>
                  <Button
                    type="text-only-link"
                    shrink={false}
                    onClick={() => {
                      navigate(location, {
                        state: { demoEnrollmentStatus: "post" },
                        replace: true,
                      });
                    }}
                  >
                    {t("enrollmentStatus", { currentDemoStatus: "post-" })}
                  </Button>
                </>
              )}
            </StackX>
          </TopBanner>
          <TopBanner mobile={isMobile}>
            <Body5 colorPrimary>{t("leadGenerationBody")}</Body5>
            <Button type="text" shrink={false} onClick={() => setShowLeadGenerationModal(true)}>
              {t("leadGenerationLink")}
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                style={{ marginLeft: "5px" }}
              ></FontAwesomeIcon>
            </Button>
          </TopBanner>
        </>
      )}

      <Row className={clsx(styles.navContent, isMobile && styles.mobile)}>
        <Col lg={10} xl={8}>
          <Brand
            pageId={explorerPage?.vanitySlug ?? pageKey}
            pageSlug={explorerPage?.vanitySlug}
            description={
              newMexicoPrepaidDHMOData ? "United Dental Care of New Mexico, Inc." : undefined
            }
            hideSunLifeLogo={explorerPage?.hideSunLifeLogo}
            customBranding={customBranding}
          />
        </Col>
        {explorerPage && pageIsActive && (
          <Col lg={14} xl={16}>
            <ActiveNav
              pageId={explorerPage.id}
              benefitsCount={benefitsCount}
              personalBenefitsPlanLink={personalBenefitsPlanLink}
              onSwitchLanguageClick={() => setShowLanguageModal(true)}
              explorerPage={explorerPage}
              customBranding={customBranding}
            />
          </Col>
        )}
      </Row>

      {showMobileEnrollButton && (
        <div className={styles.signInButton}>
          <Button block type="secondary" onClick={() => window.open(benAdminUrl, "_blank")}>
            {t("benAdminCallToAction")}
            <FontAwesomeIcon className="ml-8" icon={faExternalLinkSquareAlt} />
          </Button>
        </div>
      )}

      {explorerPage?.languageFeatureToggle &&
        isMobile &&
        !explorerPage.largeClientFeatureToggle && (
          <Row className={styles.languageContent} justify="center">
            <Col>
              Language Preference:
              <Button type="text" onClick={() => setShowLanguageModal(true)}>
                <FontAwesomeIcon className={styles.languageIcon} icon={faGlobe} />
                {languageSelected === "en" ? t("english") : t("spanish")}
              </Button>
            </Col>
          </Row>
        )}

      <ExplorerLanguageModal
        visible={showLanguageModal}
        initialLanguage={languageSelected}
        onSave={async (lang) => {
          await i18n.changeLanguage(lang.language);
          setShowLanguageModal(false);
        }}
        onCancel={() => setShowLanguageModal(false)}
      ></ExplorerLanguageModal>
      <ExplorerLeadGenerationModal
        visible={showLeadGenerationModal}
        showConfirmation={showConfirmation}
        isLoading={isPending}
        onSave={async (values) => {
          await sendLeadGenerationEmail({ data: values });
          setShowConfirmation(true);
        }}
        onCancel={() => {
          setShowConfirmation(false);
          setShowLeadGenerationModal(false);
        }}
      ></ExplorerLeadGenerationModal>
    </div>
  );
};

type BrandProps = {
  pageId: string;
  pageSlug?: string | null;
  description?: string;
  hideSunLifeLogo?: boolean;
  customBranding?: boolean;
};

const Brand: FC<BrandProps> = (props) => {
  const { pageId, pageSlug, description, hideSunLifeLogo = false, customBranding = false } = props;
  const breakpoint = useBreakpoint();
  const isMobile = !breakpoint.md;

  const Heading = isMobile ? Body3 : hideSunLifeLogo ? H4 : Body1;
  const benexColor = hideSunLifeLogo && customBranding ? "greyDark" : "blue";
  const pageUrl = getBenefitsExplorerPath(pageId, pageSlug);

  return (
    <>
      <Row justify={breakpoint.md ? "start" : "center"}>
        {!hideSunLifeLogo && (
          <Col
            className={clsx(styles.logoCol, isMobile && styles.mobile)}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <Link to={pageUrl}>
              <img
                height={isMobile ? 26 : 38}
                width={isMobile ? 96 : 141}
                style={{ position: "relative", top: isMobile ? "-4px" : undefined }}
                src="/SunLife_RGB.svg"
                alt="Benefits Explorer"
              />
            </Link>
          </Col>
        )}
        <Col flex="auto" style={{ top: !isMobile ? "8px" : "1px" }}>
          <div
            role={"button"}
            tabIndex={0}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            onKeyDown={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            style={{ cursor: "pointer" }}
          >
            <Link to={pageUrl}>
              <Heading {...{ [benexColor]: true }}>Benefits Explorer</Heading>
            </Link>
          </div>
        </Col>
      </Row>
      {description && (
        <Row className="pl-8">
          <Col flex="auto">
            <div
              role={"button"}
              tabIndex={0}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              onKeyDown={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              style={{ cursor: "pointer" }}
            >
              <Link to={pageUrl}>
                {isMobile ? <Body5 blue>{description}</Body5> : <Body1 blue>{description}</Body1>}
              </Link>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

type ActiveNavProps = {
  pageId: string;
  benefitsCount: number;
  personalBenefitsPlanLink: string;
  onSwitchLanguageClick: MouseEventHandler<HTMLElement>;
  explorerPage?: PublicExplorerPage;
  customBranding: boolean;
};

const ActiveNav: FC<ActiveNavProps> = (props) => {
  const {
    pageId,
    benefitsCount,
    personalBenefitsPlanLink,
    onSwitchLanguageClick,
    explorerPage,
    customBranding,
  } = props;

  const breakpoint = useBreakpoint();
  const isMobile = !breakpoint.md;

  const [favoritePlans] = useFavoritePlans(pageId);
  const [iconIsHighlighted] = useFavIconAnimation(favoritePlans);
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();
  const dhmoBenefitData = explorerPage?.benefits?.filter((ben) => ben.planType === "DHMO");
  const filteredFavPlansByPrepaidLocation = useFavoritePlansByLocation(
    favoritePlans,
    dhmoBenefitData,
    employeePrepaidAndChatLocation,
  );

  const { t, i18n } = useTranslation({ keyPrefix: "ExplorerNavBar" });
  const languageSelected = i18n.language === "es" ? "es" : "en";

  const showEnrollButton =
    explorerPage?.benAdminPlatformUrl &&
    !isMobile &&
    moment().isBetween(
      timezoneAdjustedMoment(moment(explorerPage.openEnrollmentStart)),
      timezoneAdjustedMoment(moment(explorerPage.openEnrollmentEnd).add({ day: 1 })),
    );

  const useExternalFormsLink = explorerPage?.benAdminPlatform?.name === "I don’t use a platform";

  const isPageExpiresInFuture = explorerPage?.expiresAt
    ? moment().isBefore(timezoneAdjustedMoment(moment(explorerPage.expiresAt)))
      ? true
      : false
    : true;

  return (
    <>
      <Row className={styles.activePageNavContainer}>
        {isPageExpiresInFuture && (
          <Col className={styles.personalBenefitsPlanLinkCol}>
            {benefitsCount > 0 && (
              <PrintHidden>
                <InternalLinkButton
                  type="link-inline-bold"
                  size="middle"
                  to={personalBenefitsPlanLink}
                >
                  <Row align="middle" gutter={12}>
                    <Col style={{ position: "relative" }}>
                      <FontAwesomeIcon
                        icon={filteredFavPlansByPrepaidLocation.length ? faHeartSolid : faHeart}
                        className={clsx(
                          styles.saveIcon,
                          customBranding && styles.saveIconNeutral,
                          iconIsHighlighted && styles.saveIconBump,
                          isMobile && styles.mobile,
                        )}
                      />
                      {isMobile && (
                        <div className={styles.saveIconNumber}>
                          <Eyebrow white>{filteredFavPlansByPrepaidLocation.length}</Eyebrow>
                        </div>
                      )}
                    </Col>
                    {!isMobile && (
                      <Col
                        className={clsx(
                          styles.favoritePlanContainer,
                          customBranding && styles.favoritePlanContainerNeutral,
                        )}
                      >
                        {t("personalPlanCallToAction", {
                          count: filteredFavPlansByPrepaidLocation.length,
                        })}
                      </Col>
                    )}
                  </Row>
                </InternalLinkButton>
              </PrintHidden>
            )}
          </Col>
        )}
        {explorerPage?.languageFeatureToggle &&
          !isMobile &&
          !explorerPage.largeClientFeatureToggle && (
            <Col className={styles.languageContainer}>
              <Button type="text" onClick={onSwitchLanguageClick}>
                <FontAwesomeIcon
                  className={clsx(
                    styles.languageIcon,
                    customBranding ? styles.languageIconNeutral : null,
                  )}
                  icon={faGlobe}
                />
                {languageSelected === "en" ? t("english") : t("spanish")}
              </Button>
            </Col>
          )}
        {showEnrollButton && (
          <Col>
            <Button
              type="tertiary"
              style={{
                backgroundColor: getCSSVariableValue("--color-gray-100"),
                outline: "0",
                color: getCSSVariableValue("--color-tertiary-900"),
              }}
              onClick={() =>
                explorerPage.benAdminPlatformUrl &&
                window.open(explorerPage.benAdminPlatformUrl, "_blank")
              }
            >
              {benefitsCount === 0
                ? t("benAdminCallToActionNoBenefits")
                : useExternalFormsLink
                ? t("benAdminCallToAction_externalLink")
                : t("benAdminCallToAction")}
              <FontAwesomeIcon className="ml-8" icon={faExternalLinkSquareAlt} />
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

const useFavIconAnimation = (favPlans: string[]): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [iconIsHighlighted, setIconIsHighlighted] = useState(false);

  useEffect(() => {
    if (favPlans.length === 0) {
      return;
    }
    setIconIsHighlighted(true);

    const timer = setTimeout(() => {
      setIconIsHighlighted(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [favPlans]);
  return [iconIsHighlighted, setIconIsHighlighted];
};
