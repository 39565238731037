import { Portal } from "@reach/portal";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Loading } from "client/src/components/Loading/Loading";
import { Settings } from "luxon";
import React from "react";
import "./app.less";

import { AppRouter } from "./Router";
import { LoadingError } from "./components/Error/LoadingError";
import { getQueryClient } from "./hooks/query";
import { ConfigurationProvider } from "./hooks/useConfig";
import { LocalStorageProvider } from "./hooks/useSlobLocalStorage";

Settings.defaultLocale = "en";
const queryClient = getQueryClient();
export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary fallback={Fallback}>
        <ConfigurationProvider>
          <LocalStorageProvider>
            <React.Suspense fallback={<Loading />}>
              <AppRouter />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <Portal type="react-query-devtools-portal">
                <div className="ReactQueryDevtools_toggleButton">
                  <ReactQueryDevtools buttonPosition="bottom-left" />
                </div>
              </Portal>
            </React.Suspense>
          </LocalStorageProvider>
        </ConfigurationProvider>
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  );
};

const Fallback: Sentry.FallbackRender = (props) => {
  const { error } = props;
  return (
    <>
      {/* This empty div is necessary so that the LoadingError vertically fills the page */}
      <div />
      <LoadingError type="page" error={error} />
    </>
  );
};
