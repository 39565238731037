import { Skeleton } from "antd";
import clsx from "clsx";
import { useCallback } from "react";

import { useGetClientByID } from "../../hooks/client";
import { useGetActiveRouteData } from "../../hooks/route";
import { useTrackElementClicked } from "../../utils/analytics";
import { getImplementationContactInfo } from "../../utils/getImplementationContactInfo";
import { Anchor } from "../Anchor/Anchor";

import * as styles from "./supportFooter.module.less";

import type { QueryStatus } from "@tanstack/react-query";

export function SupportFooter() {
  const activeRouteData = useGetActiveRouteData();
  const clientId = activeRouteData?.params.clientId ?? "";
  const isAuthRequiredPage = activeRouteData?.authType === "required";
  const { status, data: client } = useGetClientByID(clientId, {
    enabled: isAuthRequiredPage,
  });
  const trackElementClicked = useTrackElementClicked(client);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Support Footer",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );
  const {
    implementationConsultantFullName,
    implementationConsultantPhone,
    implementationConsultantEmail,
  } = getImplementationContactInfo(client);

  const isOnboardingComplete = Boolean(client?.releaseStatus === "LIVE");

  if (!isAuthRequiredPage) {
    return null;
  }

  return (
    <SupportFooterPresentation
      status={status}
      isOnboardingComplete={isOnboardingComplete}
      implementationConsultantFullName={implementationConsultantFullName}
      implementationConsultantPhone={implementationConsultantPhone}
      implementationConsultantEmail={implementationConsultantEmail}
      track={track}
    />
  );
}

type SupportFooterPresentationProps = {
  status: QueryStatus;
  isOnboardingComplete: boolean | undefined;
  implementationConsultantFullName: string | undefined;
  implementationConsultantPhone: string | undefined;
  implementationConsultantEmail: string | undefined;
  track: (buttonLabel: string) => void;
};

export function SupportFooterPresentation(props: SupportFooterPresentationProps) {
  const {
    status,
    isOnboardingComplete,
    implementationConsultantFullName,
    implementationConsultantPhone,
    implementationConsultantEmail,
    track,
  } = props;

  return (
    <div className={styles.supportFooterContainer}>
      <div className={clsx(styles.content, "py-48 px-32")}>
        <p className="h2">Need Help?</p>

        <div className={styles.columns}>
          <div>
            {isOnboardingComplete ? (
              <OnboardHelpCenter track={track} />
            ) : (
              <>
                {status === "pending" && (
                  <>
                    <Skeleton active paragraph={false} title={{ width: 140 }} />

                    <Skeleton active paragraph={false} title={{ width: 500 }} />
                    <Skeleton active paragraph={false} title={{ width: 170 }} />
                  </>
                )}

                {status === "error" && <OnboardHelpCenter track={track} />}

                {status === "success" && (
                  <>
                    {implementationConsultantFullName && (
                      <p className="body2">{implementationConsultantFullName}</p>
                    )}

                    <p>
                      Your personal Implementation Consultant and source for answers about plans,
                      pricing, forms and enrollments.
                    </p>

                    {(implementationConsultantPhone || implementationConsultantEmail) && (
                      <address className="body2">
                        {implementationConsultantPhone && (
                          <p>
                            <Anchor
                              variant="bold"
                              href={`tel:${implementationConsultantPhone}`}
                              onClick={() => track("implementation consultant phone")}
                            >
                              {implementationConsultantPhone}
                            </Anchor>
                          </p>
                        )}
                        {implementationConsultantEmail && (
                          <Anchor
                            variant="bold"
                            href={`mailto:${implementationConsultantEmail}`}
                            onClick={() => track("implementation consultant email")}
                            target="_blank"
                          >
                            {implementationConsultantEmail}
                          </Anchor>
                        )}
                      </address>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          {!isOnboardingComplete && status !== "error" && (
            <div>
              <OnboardHelpCenter track={track} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function OnboardHelpCenter({ track }: { track: (buttonLabel: string) => void }) {
  return (
    <div>
      <p className="body2">Onboard Help Center</p>

      <p>Get the answers you need to the most common support questions.</p>

      <Anchor
        variant="bold"
        href="https://onboard-help.sunlifeconnect.com/hc/en-us"
        target="_blank"
        onClick={() => track("Support Library")}
      >
        Visit the Help Center
      </Anchor>
    </div>
  );
}
