import * as styles from "./errorBanner.module.less";
import type { FC } from "react";

type ErrorBannerProps = {
  message: string;
};

export const ErrorBanner: FC<ErrorBannerProps> = ({ message }) => (
  <div className={styles.errorBanner}>{message}</div>
);
