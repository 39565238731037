import { SlobRadio } from "client/src/components/Form/SlobRadio/SlobRadio";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";

import { useSlobId } from "../../hooks/useSlobId";
import { Body2, Body3 } from "../Typography/Typography";

import { InputErrorMessage } from "./InputErrorMessage";

import * as styles from "./RadioGroup.module.less";

import type { ElementLabel } from "client/src/types/ElementLabel";
import type { ComponentProps } from "react";

type RadioValue = ComponentProps<typeof SlobRadio>["value"] | boolean;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- .
export type RadioOnChange = (e: React.ChangeEvent<HTMLInputElement & { value?: any }>) => void;

export type RadioGroupProps<Value extends RadioValue> = {
  name: string;
  value: Value | null | undefined;
  onChange: RadioOnChange;
  disabled: boolean;
  touched?: boolean;
  error: string | false | undefined;
  direction?: "horizontal" | "vertical";
  horizontalLabel?: boolean;
  options: {
    value: Value;
    label: React.ReactNode;
    disabled?: boolean;
    content?: React.ReactNode;
  }[];
} & ElementLabel;

export function RadioGroup<Value extends RadioValue>(props: RadioGroupProps<Value>) {
  const {
    name,
    label,
    value,
    onChange,
    disabled,
    options,
    touched,
    error,
    horizontalLabel = false,
    direction = "horizontal",
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
  } = props;

  // html element id's cannot have spaces, names however can, so remove any spaces to create a valid id
  const id = useSlobId({ prefix: name.replaceAll(" ", "_") });

  const labelId = `${id}__description`;
  const errorId = touched && !!error ? `${id}__errormessage` : undefined;

  const labelWrapper = (
    <div id={labelId}>{typeof label === "string" ? <Body2>{label}</Body2> : label}</div>
  );

  const radioGroupWrapper = (
    <RadioGroupWrapper
      name={name}
      value={value}
      direction={direction}
      onChange={onChange}
      disabled={disabled}
      options={options}
      touched={touched}
      error={error}
    />
  );

  return (
    <div
      role="radiogroup"
      aria-labelledby={label ? labelId : ariaLabelledBy}
      aria-label={ariaLabel}
      aria-invalid={Boolean(touched && error)}
      aria-errormessage={touched && error ? errorId : undefined}
      className="stack-y-16"
    >
      {horizontalLabel ? (
        <Row justify="space-between">
          <Col>{labelWrapper}</Col>
          <Col>{radioGroupWrapper}</Col>
        </Row>
      ) : (
        <>
          {label && labelWrapper}
          {radioGroupWrapper}
        </>
      )}

      <div>
        <div aria-live="assertive">
          {touched && !!error && (
            <div className="mt-16">
              <InputErrorMessage id={errorId} error={error} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

type RadioGroupWrapperProps<Value extends RadioValue> = Omit<
  RadioGroupProps<Value>,
  "ariaLabel" | "label"
>;

function RadioGroupWrapper<Value extends RadioValue>(props: RadioGroupWrapperProps<Value>) {
  const { name, value, onChange, disabled, options, direction, touched, error } = props;

  const optionsWrapperClasses = clsx(
    styles.radioGroupOptions,
    direction === "vertical" && styles.radioGroupOptions__vertical,
  );

  return (
    <div className={optionsWrapperClasses}>
      {options.map((o, i) => (
        <div key={i}>
          <label className={styles.label}>
            <SlobRadio
              name={name}
              value={typeof o.value === "boolean" ? o.value.toString() : o.value}
              disabled={o.disabled || disabled}
              checked={value === o.value}
              error={touched && !!error}
              onChange={(originalEvent) => {
                const event = {
                  ...originalEvent,
                  target: {
                    ...originalEvent.target,
                    name: originalEvent.target.name,
                    checked: originalEvent.target.checked,
                    value: o.value,
                  },
                };
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
                onChange(event as typeof originalEvent);
              }}
            />

            {typeof o.label === "string" ? <Body3>{o.label}</Body3> : <div>{o.label}</div>}
          </label>

          {o.content}
        </div>
      ))}
    </div>
  );
}
