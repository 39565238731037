import clsx from "clsx";

import NewTabIcon from "../Icons/NewTabIcon.svg?react";

import * as styles from "./anchor.module.less";

import type { AnchorHTMLAttributes, ReactNode } from "react";

type Props = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "className" | "rel"> & {
  disabled?: boolean;
  variant?: "bold" | "boldSmall" | "bodyText";
  newTabIcon?: boolean;
  white?: boolean;
  red?: boolean;
  children: NonNullable<ReactNode>;
};

export function Anchor({
  variant,
  newTabIcon = true,
  white,
  red,
  disabled,
  href,
  ...props
}: Props) {
  const elementProps = {
    className: clsx(
      styles.anchor,
      variant === "bold" && styles.bold,
      variant === "boldSmall" && styles.boldSmall,
      variant === "bodyText" && styles.bodyText,
      white && styles.white,
      red && styles.red,
      disabled && styles.disabled,
    ),
    children: (
      <>
        {props.children}

        {props.target && props.target === "_blank" && newTabIcon && (
          <>
            {" "}
            <NewTabIcon />
          </>
        )}
      </>
    ),
  };

  if (disabled) {
    return (
      <a {...elementProps} aria-disabled role="link">
        {elementProps.children}
      </a>
    );
  }

  return (
    <a {...props} {...elementProps} href={href} rel="noopener noreferrer">
      {elementProps.children}
    </a>
  );
}
