import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { compareQueryKey, useSlobMutation, useSlobQuery } from "./query";
import type { JsonToTypeMapper, ResponseError } from "./query";
import type { ClientId } from "shared/types/Client";
import type { Pretty } from "shared/types/Helper";
import type { Location } from "shared/types/Location";
import type { locationInputValidation } from "shared/validation/location";
import type { InferType } from "yup";

export const useGetLocations = (clientId: ClientId) =>
  useSlobQuery<Location[]>({
    method: "get",
    path: `/api/clients/${clientId}/locations`,
    map: (locations) => locations.map(jsonLocationToLocation),
  });

export const useCreateLocation = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Pretty<InferType<typeof locationInputValidation>>,
    Location,
    "/api/clients/:clientId/locations",
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/locations`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/locations`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
};

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Pretty<InferType<typeof locationInputValidation>>,
    Location,
    "/api/clients/:clientId/locations/:locationId",
    ResponseError,
    unknown
  >({
    method: "put",
    path: `/api/clients/:clientId/locations/:locationId`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/locations`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
};

export const jsonLocationToLocation: JsonToTypeMapper<Location> = (location) => {
  return {
    ...location,
    createdAt: location.createdAt ? new Date(location.createdAt) : new Date(),
    updatedAt: location.updatedAt ? new Date(location.updatedAt) : new Date(),
    deletedAt: location.deletedAt ? new Date(location.deletedAt) : null,
  };
};
