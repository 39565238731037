import { coverageToBenefitType, salaryBasedCoverages } from "shared/types/SlfCoverages";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ValueOf } from "shared/types/Helper";
import type { LocationStateCode } from "shared/types/Location";
import type { Plan } from "shared/types/Plan";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

// #region EIF

export const BenefitTypesEIF = [
  "MEDICAL",
  "ACCIDENT",
  "CRITICAL_ILLNESS",
  "HOSPITAL_INDEMNITY",
  "CANCER",
  "ISSUE_AGE_STD",
  "GAP",
  "DENTAL",
  "VISION",
  "SHORT_TERM_DISABILITY",
  "LONG_TERM_DISABILITY",
  "LIFE",
  "DEP_LIFE",
  "FINANCIAL",
  "OTHER",
  "BASIC_ADND",
  "VOL_LIFE",
  "VOL_STD",
  "VOL_LTD",
  "VOL_ADND",
  "STANDALONE_VOL_ADND",
  "ASO_DENTAL",
  "DENTAL_DHMO",
  "BASIC_DEP_ADND",
  "SPOUSE_VOL_LIFE",
  "CHILDREN_VOL_LIFE",
  "SPOUSE_VOL_ADND",
  "CHILDREN_VOL_ADND",
  "STANDALONE_SPOUSE_VOL_ADND",
  "STANDALONE_CHILDREN_VOL_ADND",
  "STANDALONE_FAMILY_VOL_ADND",
  "PFML_CT_ASO",
  "PFML_CT_FULLY_INS",
  "PFML_MA_ASO",
  "PFML_MA_FULLY_INS",
  "PFML_WA_ASO",
  "PFML_CO_ASO",
  "PFML_CO_FULLY_INS",
  "PFML_OR_ASO",
  "PFML_OR_FULLY_INS",
  "AL_DHMO",
  "AZ_DHMO",
  "CA_DHMO",
  "CO_DHMO",
  "FL_DHMO",
  "GA_DHMO",
  "IL_DHMO",
  "KS_DHMO",
  "KY_DHMO",
  "MI_DHMO",
  "MO_DHMO",
  "NE_DHMO",
  "NJ_DHMO",
  "NM_DHMO",
  "NY_DHMO",
  "OH_DHMO",
  "OK_DHMO",
  "PA_DHMO",
  "TN_DHMO",
  "TX_DHMO",
  "UT_DHMO",
  "WI_DHMO",
  "FLI",
  "DBL",
  "PFL",
  "TDB",
  "TDI",
] as const;

export type BenefitTypeEIF = ValueOf<typeof BenefitTypesEIF>;

export type DisabilityContributionBenefitType = Extract<
  BenefitTypeEIF,
  "SHORT_TERM_DISABILITY" | "LONG_TERM_DISABILITY"
>;
export const disabilityContributionBenefitTypes: DisabilityContributionBenefitType[] = [
  "SHORT_TERM_DISABILITY",
  "LONG_TERM_DISABILITY",
];

export type LifeADDContributionBenefitType = Extract<
  BenefitTypeEIF,
  "LIFE" | "DEP_LIFE" | "BASIC_ADND"
>;
export const lifeADDContributionBenefitTypes: LifeADDContributionBenefitType[] = [
  "LIFE",
  "DEP_LIFE",
  "BASIC_ADND",
];

export type VoluntaryBenefitType = Extract<
  BenefitTypeEIF,
  | "VOL_LIFE"
  | "SPOUSE_VOL_LIFE"
  | "CHILDREN_VOL_LIFE"
  | "VOL_ADND"
  | "SPOUSE_VOL_ADND"
  | "CHILDREN_VOL_ADND"
  | "STANDALONE_VOL_ADND"
>;

export const voluntaryBenefitTypes: VoluntaryBenefitType[] = [
  "VOL_LIFE",
  "SPOUSE_VOL_LIFE",
  "CHILDREN_VOL_LIFE",
  "VOL_ADND",
  "SPOUSE_VOL_ADND",
  "CHILDREN_VOL_ADND",
  "STANDALONE_VOL_ADND",
];

export type SupplementalHealthBenefitType = Extract<
  BenefitTypeEIF,
  "ACCIDENT" | "CANCER" | "CRITICAL_ILLNESS" | "HOSPITAL_INDEMNITY"
>;
export const supplementalHealthBenefitTypes: SupplementalHealthBenefitType[] = [
  "ACCIDENT",
  "CANCER",
  "CRITICAL_ILLNESS",
  "HOSPITAL_INDEMNITY",
];

export type PFMLBenefitType = Extract<
  BenefitTypeEIF,
  | "PFML_CT_ASO"
  | "PFML_CT_FULLY_INS"
  | "PFML_MA_ASO"
  | "PFML_MA_FULLY_INS"
  | "PFML_WA_ASO"
  | "PFML_CO_ASO"
  | "PFML_CO_FULLY_INS"
  | "PFML_OR_ASO"
  | "PFML_OR_FULLY_INS"
>;
export const pfmlBenefitTypes: PFMLBenefitType[] = [
  "PFML_CT_ASO",
  "PFML_CT_FULLY_INS",
  "PFML_MA_ASO",
  "PFML_MA_FULLY_INS",
  "PFML_WA_ASO",
  "PFML_CO_ASO",
  "PFML_CO_FULLY_INS",
  "PFML_OR_ASO",
  "PFML_OR_FULLY_INS",
];

export const pfmlASOBenefitTypes = [
  "PFML_CT_ASO",
  "PFML_MA_ASO",
  "PFML_WA_ASO",
  "PFML_CO_ASO",
  "PFML_OR_ASO",
] as const;
export const pfmlASOBenefitTypesSet = new Set(pfmlASOBenefitTypes);

export type PFMLASOBenefitType = ValueOf<typeof pfmlASOBenefitTypes>;

export type PFMLFullyInsuredBenefitType = Extract<
  PFMLBenefitType,
  "PFML_CT_FULLY_INS" | "PFML_MA_FULLY_INS" | "PFML_CO_FULLY_INS" | "PFML_OR_FULLY_INS"
>;

export const pfmlFullyInsuredBenefitTypes: PFMLFullyInsuredBenefitType[] = [
  "PFML_CT_FULLY_INS",
  "PFML_MA_FULLY_INS",
  "PFML_CO_FULLY_INS",
  "PFML_OR_FULLY_INS",
];

export type LifeAndDisabilityBenefitType = Extract<
  BenefitTypeEIF,
  | "SHORT_TERM_DISABILITY"
  | "LONG_TERM_DISABILITY"
  | "LIFE"
  | "DEP_LIFE"
  | "BASIC_ADND"
  | "VOL_STD"
  | "VOL_LTD"
  | "VOL_LIFE"
  | "VOL_ADND"
  | "STANDALONE_VOL_ADND"
>;

const lifeAndDisabilityBenefitTypes: LifeAndDisabilityBenefitType[] = [
  "SHORT_TERM_DISABILITY",
  "LONG_TERM_DISABILITY",
  "LIFE",
  "DEP_LIFE",
  "BASIC_ADND",
  "VOL_STD",
  "VOL_LTD",
  "VOL_LIFE",
  "VOL_ADND",
  "STANDALONE_VOL_ADND",
];

type DHMOBenefitTypeState<T extends LocationStateCode> = `${T}_DHMO`;

export type DHMOBenefitType = Extract<
  BenefitTypeEIF,
  | DHMOBenefitTypeState<"AL">
  | DHMOBenefitTypeState<"AZ">
  | DHMOBenefitTypeState<"CA">
  | DHMOBenefitTypeState<"CO">
  | DHMOBenefitTypeState<"FL">
  | DHMOBenefitTypeState<"GA">
  | DHMOBenefitTypeState<"IL">
  | DHMOBenefitTypeState<"KS">
  | DHMOBenefitTypeState<"KY">
  | DHMOBenefitTypeState<"MI">
  | DHMOBenefitTypeState<"MO">
  | DHMOBenefitTypeState<"NE">
  | DHMOBenefitTypeState<"NJ">
  | DHMOBenefitTypeState<"NM">
  | DHMOBenefitTypeState<"NY">
  | DHMOBenefitTypeState<"OH">
  | DHMOBenefitTypeState<"OK">
  | DHMOBenefitTypeState<"PA">
  | DHMOBenefitTypeState<"TN">
  | DHMOBenefitTypeState<"TX">
  | DHMOBenefitTypeState<"UT">
  | DHMOBenefitTypeState<"WI">
>;
export const dhmoBenefitTypes: DHMOBenefitType[] = [
  "AL_DHMO",
  "AZ_DHMO",
  "CA_DHMO",
  "CO_DHMO",
  "FL_DHMO",
  "GA_DHMO",
  "IL_DHMO",
  "KS_DHMO",
  "KY_DHMO",
  "MI_DHMO",
  "MO_DHMO",
  "NE_DHMO",
  "NJ_DHMO",
  "NM_DHMO",
  "NY_DHMO",
  "OH_DHMO",
  "OK_DHMO",
  "PA_DHMO",
  "TN_DHMO",
  "TX_DHMO",
  "UT_DHMO",
  "WI_DHMO",
];

export type BenefitTypesSupportedInClassBuilder = Extract<
  BenefitTypeEIF,
  | "DENTAL"
  | "ASO_DENTAL"
  | "VISION"
  | LifeAndDisabilityBenefitType
  | SupplementalHealthBenefitType
  | DHMOBenefitType
  | "FLI"
>;

export const benefitTypesSupportedInClassBuilder: BenefitTypesSupportedInClassBuilder[] = [
  "DENTAL",
  "ASO_DENTAL",
  "VISION",
  ...lifeAndDisabilityBenefitTypes,
  ...supplementalHealthBenefitTypes,
  ...dhmoBenefitTypes,
  "FLI",
];

// Contribution Benefit Types Begin

// The history of these tiered contributions is a bit convoluted,
// see the comment in schema.prisma at the `EmployeeClassPlan` model
// for more information

// Dental and Vision were originally four tier Benefits, but were converted to three tier.
// Although we will still support the legacy four tiers if they were input as such initially.
export type ThreeTierIndividualContributionBenefitType = Extract<
  BenefitTypeEIF,
  "DENTAL" | "VISION" | "ASO_DENTAL" | DHMOBenefitType
>;
export const threeTierIndividualContributionBenefitTypes: ThreeTierIndividualContributionBenefitType[] =
  ["DENTAL", "VISION", "ASO_DENTAL", ...dhmoBenefitTypes];

export type FourTierIndividualContributionBenefitType = Extract<
  BenefitTypeEIF,
  "HOSPITAL_INDEMNITY"
>;
export const fourTierIndividualContributionBenefitTypes: FourTierIndividualContributionBenefitType[] =
  ["HOSPITAL_INDEMNITY"];

export type TieredContributionBenefitTypes = ThreeTierIndividualContributionBenefitType;

export const tieredContributionBenefitTypes: TieredContributionBenefitTypes[] = [
  ...threeTierIndividualContributionBenefitTypes,
];

export type ContributionBenefitTypes =
  | TieredContributionBenefitTypes
  | SupplementalHealthBenefitType
  | DisabilityContributionBenefitType
  | LifeADDContributionBenefitType
  | DHMOBenefitType
  | "VOL_STD"
  | "VOL_LTD";

export const contributionBenefitTypes: ContributionBenefitTypes[] = [
  ...tieredContributionBenefitTypes,
  ...supplementalHealthBenefitTypes,
  ...disabilityContributionBenefitTypes,
  ...lifeADDContributionBenefitTypes,
  ...dhmoBenefitTypes,
  "VOL_STD",
  "VOL_LTD",
];

export type WaitingPeriodBenefitTypes = ContributionBenefitTypes | VoluntaryBenefitType;

export const waitingPeriodBenefitTypes = [...contributionBenefitTypes, ...voluntaryBenefitTypes];

export const getIsWaitingPeriodBenefitType = (
  s: BenefitTypeEIF,
): s is WaitingPeriodBenefitTypes => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = waitingPeriodBenefitTypes.includes(s as WaitingPeriodBenefitTypes);
  return isIt;
};

export type PremiumPaymentBenefitTypes = Extract<
  BenefitTypeEIF,
  SupplementalHealthBenefitType | DisabilityContributionBenefitType | "VOL_STD" | "VOL_LTD"
>;

export const premiumPaymentBenefitTypes: PremiumPaymentBenefitTypes[] = [
  ...supplementalHealthBenefitTypes,
  ...disabilityContributionBenefitTypes,
  "VOL_STD",
  "VOL_LTD",
];

export type StatutoryBenefitType = Extract<BenefitTypeEIF, "TDI" | "TDB" | "DBL" | "PFL">;

export const statutoryBenefitTypes: StatutoryBenefitType[] = ["TDI", "TDB", "DBL", "PFL"];

export type NonConfigurationBenefitType = Extract<
  BenefitTypeEIF,
  "ACCIDENT" | "CANCER" | "CRITICAL_ILLNESS" | "HOSPITAL_INDEMNITY" | StatutoryBenefitType
>;

export type ClaimsCheckBenefitType = Extract<
  BenefitTypeEIF,
  "SHORT_TERM_DISABILITY" | "VOL_STD" | "VOL_LTD" | StatutoryBenefitType | PFMLBenefitType
>;
export const claimsCheckBenefitTypes: ClaimsCheckBenefitType[] = [
  "SHORT_TERM_DISABILITY",
  "VOL_STD",
  "VOL_LTD",
  "PFML_CT_ASO",
  "PFML_CT_FULLY_INS",
  "PFML_MA_ASO",
  "PFML_MA_FULLY_INS",
  "PFML_WA_ASO",
  "PFML_CO_ASO",
  "PFML_CO_FULLY_INS",
  "PFML_OR_ASO",
  "PFML_OR_FULLY_INS",
  "TDB",
  "DBL",
  "PFL",
];

// Contribution Benefit Types End

export const isLifeAndDisabilityBenefitType = (
  s: BenefitTypeEIF,
): s is LifeAndDisabilityBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = lifeAndDisabilityBenefitTypes.includes(s as LifeAndDisabilityBenefitType);
  return isIt;
};

export const getIsPFMLFullyInsuredBenefitType = (
  s: BenefitTypeEIF,
): s is PFMLFullyInsuredBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = pfmlFullyInsuredBenefitTypes.includes(s as PFMLFullyInsuredBenefitType);
  return isIt;
};

export const getIsDHMOBenefitType = (s: BenefitTypeEIF): s is DHMOBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = dhmoBenefitTypes.includes(s as DHMOBenefitType);
  return isIt;
};

export const isBenefitTypeEIF = (s: string): s is BenefitTypeEIF => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = BenefitTypesEIF.includes(s as BenefitTypeEIF);
  return isIt;
};

export const getIsTieredContributionBenefitType = (
  s: BenefitTypeEIF,
): s is TieredContributionBenefitTypes => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = tieredContributionBenefitTypes.includes(s as TieredContributionBenefitTypes);
  return isIt;
};

export const getIsDisabilityContributionBenefitType = (
  s: BenefitTypeEIF,
): s is DisabilityContributionBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = disabilityContributionBenefitTypes.includes(s as DisabilityContributionBenefitType);
  return isIt;
};

export const getIsClaimsCheckBenefitType = (s: BenefitTypeEIF): s is ClaimsCheckBenefitType => {
  const isIt = claimsCheckBenefitTypes.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as ClaimsCheckBenefitType,
  );
  return isIt;
};

export const getIsLifeADDContributionBenefitType = (
  s: BenefitTypeEIF,
): s is LifeADDContributionBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = lifeADDContributionBenefitTypes.includes(s as LifeADDContributionBenefitType);
  return isIt;
};

export const getIsContributionBenefitType = (s: BenefitTypeEIF): s is ContributionBenefitTypes => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = contributionBenefitTypes.includes(s as ContributionBenefitTypes);
  return isIt;
};

export const getIsSupplementalHealthBenefitType = (
  s: BenefitTypeEIF,
): s is SupplementalHealthBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = supplementalHealthBenefitTypes.includes(s as SupplementalHealthBenefitType);
  return isIt;
};

export const getIsPFMLBenefitType = (s: BenefitTypeEIF): s is PFMLBenefitType => {
  const isIt = pfmlBenefitTypes.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as PFMLBenefitType,
  );
  return isIt;
};

export const getIsPFMLASOBenefitType = (s: BenefitTypeEIF): s is PFMLASOBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = pfmlASOBenefitTypes.includes(s as PFMLASOBenefitType);
  return isIt;
};

export const getIsBenefitSupportedInClassBuilder = (
  s: BenefitTypeEIF,
  plans: Plan[],
): s is BenefitTypesSupportedInClassBuilder => {
  const isIt = benefitTypesSupportedInClassBuilder.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as BenefitTypesSupportedInClassBuilder,
  );

  if (isIt && s === "FLI") {
    const fliPlan = plans.find((plan) => plan.benefitType === "FLI");
    return fliPlan?.salaryBased ?? false;
  }

  return isIt;
};

export const getIsFourTierIndividualContributionBenefitType = (
  s: BenefitTypeEIF,
): s is FourTierIndividualContributionBenefitType => {
  const isIt = fourTierIndividualContributionBenefitTypes.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as FourTierIndividualContributionBenefitType,
  );
  return isIt;
};

export const getIsThreeTierContributionBenefitType = (
  s: BenefitTypeEIF,
): s is ThreeTierIndividualContributionBenefitType => {
  const isIt = threeTierIndividualContributionBenefitTypes.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as ThreeTierIndividualContributionBenefitType,
  );
  return isIt;
};

export const getIsPremiumPaymentBenefitTypes = (s: string): s is PremiumPaymentBenefitTypes => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = premiumPaymentBenefitTypes.includes(s as PremiumPaymentBenefitTypes);
  return isIt;
};

export const getIsVoluntaryBenefitTypes = (s: string): s is VoluntaryBenefitType => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = voluntaryBenefitTypes.includes(s as VoluntaryBenefitType);
  return isIt;
};

export const benefitTypesSorter = (a: BenefitTypeEIF, b: BenefitTypeEIF) => {
  const order: BenefitTypeEIF[] = [
    "DENTAL",
    "VISION",
    "SHORT_TERM_DISABILITY",
    "LONG_TERM_DISABILITY",
    "LIFE",
    "DEP_LIFE",
    "BASIC_ADND",
    "VOL_STD",
    "VOL_LTD",
    "VOL_LIFE",
    "VOL_ADND",
    "STANDALONE_VOL_ADND",
    "PFML_CT_ASO",
    "PFML_CT_FULLY_INS",
    "PFML_MA_ASO",
    "PFML_MA_FULLY_INS",
    "PFML_WA_ASO",
    "PFML_CO_ASO",
    "PFML_CO_FULLY_INS",
    "PFML_OR_ASO",
    "PFML_OR_FULLY_INS",
    "TDI",
    "TDB",
    "DBL",
    "PFL",
  ];

  if (order.includes(a) && order.includes(b)) return order.indexOf(a) - order.indexOf(b);
  if (order.includes(a)) return -1;
  if (order.includes(b)) return 1;
  return a.localeCompare(b);
};

export const coverageSorter = (
  a: SlfCoverageLongName | null | undefined,
  b: SlfCoverageLongName | null | undefined,
) => {
  if (a == null) return 1;
  if (b == null) return -1;
  const benefitType_a = coverageToBenefitType[a];
  const benefitType_b = coverageToBenefitType[b];
  return benefitTypesSorter(benefitType_a, benefitType_b);
};

export function getSalaryBasedClassPlans(employeeClass: EmployeeClass | undefined) {
  if (!employeeClass) return [];

  const salaryBasedBenefitTypes = salaryBasedCoverages.map(
    (coverage) => coverageToBenefitType[coverage],
  );

  const salaryBasedClassPlans = employeeClass.employeeClassPlans.filter((classPlan) => {
    const isSalaryBasedBenefitType = salaryBasedBenefitTypes.includes(classPlan.plan.benefitType);
    return classPlan.plan.salaryBased && isSalaryBasedBenefitType;
  });

  return salaryBasedClassPlans;
}

export const nonConfigurationBenefitTypes: NonConfigurationBenefitType[] = [
  "ACCIDENT",
  "CANCER",
  "CRITICAL_ILLNESS",
  "HOSPITAL_INDEMNITY",
  "DBL",
  "PFL",
  "TDB",
  "TDI",
];

export const getIsNonConfigurationBenefitType = (
  s: BenefitTypeEIF,
): s is NonConfigurationBenefitType => {
  const isIt = nonConfigurationBenefitTypes.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as NonConfigurationBenefitType,
  );
  return isIt;
};

export const getIsStatutoryBenefitType = (s: BenefitTypeEIF): s is StatutoryBenefitType => {
  const isIt = statutoryBenefitTypes.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as StatutoryBenefitType,
  );
  return isIt;
};

// #endregion EIF

// #region Benefit types Plan level config

export type BenefitTypesPlanLevelConfig = PFMLBenefitType | StatutoryBenefitType;

export const isPlanLevelSetupBenefitType = (s: string): s is BenefitTypesPlanLevelConfig => {
  const planLevelSetupBenefitTypes = [...pfmlBenefitTypes, ...statutoryBenefitTypes];
  const isIt = planLevelSetupBenefitTypes.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    s as BenefitTypesPlanLevelConfig,
  );
  return isIt;
};

// #region Benefits Explorer

export const BenefitTypesBenEx = [
  "MEDICAL",
  "ACCIDENT",
  "CRITICAL_ILLNESS",
  "HOSPITAL_INDEMNITY",
  "CANCER",
  "GAP",
  "DENTAL",
  "VISION",
  "SHORT_TERM_DISABILITY",
  "LONG_TERM_DISABILITY",
  "LIFE",
  "FINANCIAL",
  "OTHER",
] as const;

export type BenefitTypeBenEx = ValueOf<typeof BenefitTypesBenEx>;

export const BenefitTypeBenExData: Record<BenefitTypeBenEx, { displayName: string }> = {
  MEDICAL: { displayName: "Medical" },
  DENTAL: { displayName: "Dental" },
  VISION: { displayName: "Vision" },
  CRITICAL_ILLNESS: { displayName: "Critical Illness" },
  ACCIDENT: { displayName: "Accident" },
  HOSPITAL_INDEMNITY: { displayName: "Hospital Indemnity" },
  CANCER: { displayName: "Cancer" },
  GAP: { displayName: "Gap" },
  SHORT_TERM_DISABILITY: { displayName: "Short-Term Disability" },
  LONG_TERM_DISABILITY: { displayName: "Long-Term Disability" },
  LIFE: { displayName: "Life" },
  OTHER: { displayName: "Additional Benefits" },
  FINANCIAL: { displayName: "Financial Products" },
};

export const isBenefitTypeBenEx = (s: string): s is BenefitTypeBenEx => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = BenefitTypesBenEx.includes(s as BenefitTypeBenEx);
  return isIt;
};

// #endregion Benefits Explorer
