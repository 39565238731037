import clsx from "clsx";
import { Link } from "react-router-dom";
import type { ButtonSize } from "client/src/components/Button/Button";

import type { MouseEventHandler } from "react";
import type { Location, Path } from "react-router-dom";

type ButtonType = "link" | "link-inline" | "link-inline-bold";
export interface InternalLinkButtonProps {
  to: string | Partial<Path> | Location;
  state?: unknown;
  type?: ButtonType;
  size?: ButtonSize;
  narrow?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  target?: string;
  children?: React.ReactNode;
}

// This button was created to solve issues around full-page reloads when navigating between pages
// Additionally, it's styling is specific to the internal view so may not work with the current visual design for other pages
export const InternalLinkButton: React.FC<InternalLinkButtonProps> = (props) => {
  const type = props.type || "default";
  const {
    type: themeType,
    className: classNameProvided,
    size,
    narrow,
    disabled,
    children,
    to,
    state,
    target,
    onClick,
    ...rest
  } = props;

  const classNameFinal =
    type === "link" || type === "link-inline" || type === "link-inline-bold"
      ? clsx(
          "ant-btn",
          handleType("link"),
          classNameProvided,
          handleSize(size),
          type === "link-inline" && "link-inline",
          type === "link-inline-bold" && "link-inline-bold",
          narrow && "slob-btn-narrow",
        )
      : clsx(
          "linkButton",
          disabled && "linkButton-disabled",
          "ant-btn",
          handleType(type),
          handleSize(size),
          classNameProvided,
        );

  const commonProps = {
    ...rest,
    className: classNameFinal,
    children: (
      <>
        <span className={clsx("ant-btn-content")}>{children}</span>

        {!disabled && target === "_blank" && <span className="sr-only">(opens in a new tab)</span>}
      </>
    ),
  };

  if (disabled) {
    return <button {...commonProps} aria-disabled />;
  }

  const linkProps = {
    to,
    target,
    onClick,
    state,
  };

  return <Link {...linkProps} {...commonProps} />;
};

type Type =
  | "primary"
  | "secondary"
  | "tertiary"
  | "default"
  | "link"
  | "link-inline"
  | "link-inline-bold-deprecated"
  | undefined;

export const handleType = (type: Type) => {
  if (!type || type === "tertiary") {
    return "ant-btn-default";
  }
  return `ant-btn-${type}`;
};

type Size = "xtra-small" | "small" | "middle" | "large" | undefined;

export const handleSize = (size: Size) => {
  switch (size) {
    case "large":
      return "ant-btn-lg";
    case "small":
      return "ant-btn-sm";
    case "xtra-small":
      return "ant-btn-xs";
    case "middle":
      return "";
    default:
      return "ant-btn-sm";
  }
};
