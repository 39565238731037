import React from "react";
import ReactDOM from "react-dom/client";
import "./polyfills";

import { App } from "./App";
import { initYourBenefitsExplorer } from "./utils/benefits-explorer";
import { initDatadog } from "./utils/datadog";
import { getRootElement } from "./utils/getRootElement";
import { initHojar } from "./utils/hotjar";
import { initOnboard } from "./utils/onboard";
import { handlePreloadErrors } from "./utils/preloadError";
import { initSentry } from "./utils/sentry";

handlePreloadErrors();
initOnboard();
initYourBenefitsExplorer();
initDatadog();
initSentry();
initHojar();

function renderApp() {
  const container = getRootElement();
  if (!container) {
    throw new Error(`Root element not found`);
  }
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

renderApp();
