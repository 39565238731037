import clsx from "clsx";

import * as styles from "./circle.module.less";

import type { HTMLAttributes } from "react";

type Props = Omit<HTMLAttributes<HTMLDivElement>, "className"> & {
  variant?: "white" | "blue" | "yellow" | "yellowLight";
  thin?: boolean;
  grow?: boolean;
};

export function Circle({ variant = "white", thin, grow, ...rest }: Props) {
  return (
    <div
      {...rest}
      className={clsx(styles.circle, styles[variant], thin && styles.thin, grow && styles.grow)}
    />
  );
}
