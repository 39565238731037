import clsx from "clsx";

import { Children, isValidElement, Fragment, createElement } from "react";
import type { HTMLAttributes, ReactNode } from "react";

export const distances = [1, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 128] as const;

export type StackProps = HTMLAttributes<HTMLDivElement> & {
  dist: (typeof distances)[number];
  wrap?: boolean;
  split?: ReactNode;
};

/**
 * React consider fragment as children.
 * The purpose is to flatter the Fragment children to match the same output we will have in the HTML
 */
const flatFragmentChildrenWithSplit = (
  childrenNodes: ReactNode[],
  split?: ReactNode,
): ReactNode[] => {
  const flatChildren = childrenNodes.reduce<ReactNode[]>((flatChildren, child, index) => {
    if (isValidElement(child) && child.type === Fragment) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- required
      const fragmentChildren = child.props.children;

      // If the fragment has more than one child then we need to flat the fragment child
      if (Children.count(fragmentChildren) > 1) {
        const flatFragmentChildren = flatFragmentChildrenWithSplit(fragmentChildren, split);
        return flatChildren.concat(flatFragmentChildren);
      }

      // In case the fragment has only one child we will use the child directly
      child = fragmentChildren;
    }

    flatChildren.push(child);
    if (split != null && index < childrenNodes.length - 1) {
      flatChildren.push(split);
    }

    return flatChildren;
  }, []);

  return flatChildren;
};

const wrapChildrenInContainer = (children: ReactNode[], direction: "vertical" | "horizontal") => {
  return Children.map(children, (child) => {
    const elementName = direction === "vertical" ? "stack-y-item" : "stack-x-item";
    return child ? createElement(elementName, { children: child }) : null;
  });
};

export function StackX({ dist, children, split, wrap = true, ...rest }: StackProps) {
  const props = {
    ...rest,
    className: clsx(`stack-x-${dist}`, rest.className ?? null, "stack-row"),
  };

  if (!wrap) {
    return <div {...props}>{children}</div>;
  }

  const childrenNodes = Children.toArray(children);
  const flatChildren = flatFragmentChildrenWithSplit(childrenNodes, split);
  const childrenToRender = wrapChildrenInContainer(flatChildren, "horizontal");

  return (
    <div {...rest} className={clsx(`stack-x-${dist}`, rest.className ?? null, "stack-row")}>
      {childrenToRender}
    </div>
  );
}

export function StackY({ dist, children, split, wrap = true, ...rest }: StackProps) {
  const props = {
    ...rest,
    className: clsx(`stack-y-${dist}`, rest.className ?? null),
  };

  if (!wrap) {
    return <div {...props}>{children}</div>;
  }

  const childrenNodes = Children.toArray(children);
  const flatChildren = flatFragmentChildrenWithSplit(childrenNodes, split);
  const childrenToRender = wrapChildrenInContainer(flatChildren, "vertical");

  return <div {...props}>{childrenToRender}</div>;
}
