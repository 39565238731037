import { timezoneAdjustedMoment } from "client/src/utils/moment";
import moment from "moment";
import type { ExplorerPage } from "shared/types/ExplorerPage";

export function getShowExplorerPageEnrollButton(explorerPage: ExplorerPage): boolean {
  return (
    explorerPage.benAdminPlatformUrl != null &&
    moment().isBetween(
      timezoneAdjustedMoment(moment(explorerPage.openEnrollmentStart)),
      timezoneAdjustedMoment(moment(explorerPage.openEnrollmentEnd).add({ day: 1 })),
    )
  );
}
