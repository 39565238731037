import { isYourBenefitsExplorer } from "client/src/utils/benefits-explorer";
import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

export const match = (isExact: boolean, pathname: string) => {
  const routes = Object.values(RouteData).filter((route) =>
    isYourBenefitsExplorer() ? route.viewType === "explorer" : true,
  );
  for (const route of routes) {
    const matchItem = matchPath(
      {
        path: route.getPathTemplate(),
        end: isExact,
        caseSensitive: false,
      },
      pathname,
    );
    if (matchItem) {
      const params = matchItem.params;
      return {
        path: matchItem.pathname,
        zendeskLabels: "getZendeskLabels" in route ? route.getZendeskLabels(params) : [],
        friendlyName: route.friendlyName,
        viewType: route.viewType,
        authType: route.authType,
        params,
      };
    }
  }
};

export const useGetActiveRouteData = () => {
  const { pathname } = useLocation();

  // Some of the child routes with params are a non-exact match
  return useMemo(() => match(true, pathname) ?? match(false, pathname), [pathname]);
};
