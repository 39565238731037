import { getExplorerPageURL } from "client/src/domain/ExplorerPages/utils";
import { viteConfig } from "client/src/utils/viteConfig";
import { RouteData } from "shared/config/routeData";
import slugify from "slugify";
import type { ExplorerPageId, ExplorerPageVanitySlug } from "shared/types/ExplorerPage";

/**
 * Check if the current host is the custom "Your Benefits Explorer".
 * @returns {boolean} True if the current host is the benefits explorer, false otherwise.
 */
export const isYourBenefitsExplorer = (): boolean => {
  return window.location.host.includes(viteConfig.VITE_BENEFITS_EXPLORER_BASE_URL);
};

export function initYourBenefitsExplorer() {
  if (isYourBenefitsExplorer()) {
    document.title = "Your Benefits Explorer";
  }
}

/**
 * Return the path for a benefits explorer page.
 * @param pageId The page ID.
 * @param pageSlug The page slug.
 * @returns The path.
 */
export const getBenefitsExplorerPath = (
  pageId: ExplorerPageId,
  pageSlug?: ExplorerPageVanitySlug,
) => {
  const pageKey = pageSlug ? slugify(pageSlug, { lower: true }) : pageId;
  return isYourBenefitsExplorer()
    ? RouteData.benefitsExplorer.getPath(pageKey)
    : RouteData.benefitsExplorerInternal.getPath(pageKey);
};

/**
 * Return the URL for a benefits explorer page.
 * @param pageId The page ID.
 * @param pageSlug The page slug.
 * @param pageCustomBranding Whether the page has custom branding.
 * @returns The URL.
 */
export const getBenefitsExplorerURL = (
  pageId: ExplorerPageId,
  pageSlug: ExplorerPageVanitySlug,
  pageCustomBranding: boolean,
) => {
  return pageSlug
    ? getExplorerPageURL(pageSlug, true, pageCustomBranding)
    : getExplorerPageURL(pageId, false, pageCustomBranding);
};
