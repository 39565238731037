import { useEffect } from "react";

export function usePreventBodyScrollWhenVisible(enabled: boolean | undefined) {
  useEffect(() => {
    if (enabled) {
      const style = document.createElement("style");
      style.dataset.info = "slob-drawer-prevent-body-scroll";
      style.appendChild(
        document.createTextNode(`
        html body {
            overflow-y: hidden;
            width: calc(100% - 15px);
        }
        `),
      );
      document.head.appendChild(style);

      return () => {
        document.head.removeChild(style);
      };
    }
  }, [enabled]);
}
