import * as Yup from "yup";

// This email validation comes from Yup@1.2.0, with a minor upgrade to better handle domains

const rEmail =
  // eslint-disable-next-line no-useless-escape -- Taken from HTML spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

declare module "yup" {
  interface StringSchema {
    /**
     * @deprecated Use emailV1() instead
     */
    email(message?: string): StringSchema;
    emailV1(message?: string): StringSchema;
  }
}
Yup.addMethod(Yup.string, "emailV1", function (message) {
  return this.max(191, message || "Please enter email address no longer than 191 characters").test({
    name: "email",
    message: message || "Please enter a valid email address",
    test: (value) => {
      const tld = value?.split(".").pop() || "";
      return (
        !value ||
        (!value.startsWith(".") &&
          !value.includes(".@") &&
          !value.includes("..") &&
          tld.length <= 20 &&
          rEmail.test(value))
      );
    },
  });
});

export { Yup };
