import type { ValueOf } from "shared/types/Helper";

export const SemanticErrorCodes = [
  "ALREADY_HAS_CLASSES",
  "EMBEDDED_SCRIPTS",
  "FILE_ZIPPING_TIMEOUT",
  "BLOCKED_BY_CLOUDFLARE",
] as const;

export type SemanticErrorCode = ValueOf<typeof SemanticErrorCodes>;

export interface SlobServerError {
  code?: SemanticErrorCode;
  [prop: string]: unknown;
}

export function isSlobServerError(data: unknown): data is SlobServerError {
  const isIt =
    data != null &&
    typeof data === "object" &&
    "code" in data &&
    typeof data.code === "string" &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    SemanticErrorCodes.includes(data.code as SemanticErrorCode);
  return isIt;
}
