import { captureException } from "@sentry/react";
import { useMutation, useQuery, QueryClient } from "@tanstack/react-query";
import axios from "axios";
import defaultsDeep from "lodash.defaultsdeep";
import {
  SCHEDULED_MAINTENANCE_MESSAGE,
  UNSCHEDULED_MAINTENANCE_MESSAGE,
} from "shared/config/routeData";
import { INVALID_RECOVERY_TOKEN } from "shared/data/Auth";
import { isSlobServerError } from "shared/types/Error";
import { genericErrorCopy, genericErrorCopy2 } from "../components/Error/ErrorMessage";

import { getLoginUrl } from "./auth";
import { useHubConfiguration } from "./useConfig";
import type {
  MutationFunction,
  MutationKey,
  Query,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { AxiosRequestConfig, AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";
import type React from "react";
import type { SemanticErrorCode, SlobServerError } from "shared/types/Error";

type JsonPrimitive = number | string | boolean | null;
interface JsonArray extends Array<Json> {}
type JsonObject = { [Key in string | number]?: Json };
export type Json = JsonPrimitive | JsonObject | JsonArray;

export type ResponseToJson<T> = T extends JsonPrimitive
  ? T
  : T extends Date
  ? string
  : T extends Record<keyof unknown, unknown>
  ? {
      [K in keyof T]: ResponseToJson<T[K]>;
    }
  : T extends ReadonlyArray<infer R>
  ? ReadonlyArray<ResponseToJson<R>>
  : never;

// Required to send cookies on each request
axios.defaults.withCredentials = true;

export type Options = ConstructorParameters<typeof QueryClient>[0];

export const getQueryClient = (options: Options = {}) => {
  const queryClientOptions: ConstructorParameters<typeof QueryClient>[0] = {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
        retry: (failureCount, error) => {
          if (failureCount > 2) {
            return false;
          }
          if (ResponseError.isResponseError(error)) {
            // Allow retries for:
            return (
              error.status === 502 || // BadGateway
              error.status === 503 || // ServiceUnavailable
              error.status === 504 || // GatewayTimeout
              error.status === 429 || // TooManyRequests
              error.status === 499 // BadGateway (Client Closed Request)
            );
          }
          return true;
        },
        retryDelay: (attempt) => attempt * 1000,
      },
      mutations: {
        retry: (failureCount, error) => {
          // retrying data saving is generally a bad idea, but there are exceptions
          if (failureCount > 2) {
            return false;
          }
          if (ResponseError.isResponseError(error)) {
            // when opening multiple Onboard tabs fast enough, there is a chance that
            // mark as viewed (for documents and DocuSign) will try to save something
            // before common csrf token/cookie are established
            return error.message === "invalid csrf token";
          }
          return false;
        },
        retryDelay: (failureCount) => {
          return failureCount * 3000;
        },
      },
    },
  };
  const queryClient = new QueryClient(defaultsDeep(options, queryClientOptions));
  return queryClient;
};

type HttpQueryMethod = "get" | "head" | "options";

export type SlobQueryType<Response, TError> = {
  method: HttpQueryMethod;
  path: string;
  queryId?: string;
  options?: Omit<UseQueryOptions<Response, TError>, "queryKey" | "queryFn">;
  map?: JsonToTypeMapper<Response>;
  requestConfig?: AxiosRequestConfig;
  mapBlob?: BlobToTypeMapper<Response>;
  shouldRequireAuth?: boolean;
};

export type JsonToTypeMapper<T> = (data: ResponseToJson<T>) => T;
export type BlobToTypeMapper<T> = (
  data: Blob,
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders,
) => T;

export class RequestError extends Error {
  status: number;
  message: string;

  static isRequestError(err: unknown): err is RequestError {
    return err instanceof RequestError;
  }
  constructor(status: number, message: string) {
    super();
    this.status = status;
    this.message = message;
    this.name = "RequestError";
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export class ResponseError extends Error {
  status: number;
  data: unknown;
  correlationId: string;

  static isResponseError(err: unknown): err is ResponseError {
    return err instanceof ResponseError || !!(typeof err === "object" && err && "data" in err);
  }

  static isSlobServerError(data: unknown): data is SlobServerError {
    const isIt = isSlobServerError(data);
    return isIt;
  }

  static dataHasErrorDetails(data: unknown): data is { errorDetails: unknown } {
    const hasErrorDetails = data != null && typeof data === "object" && "errorDetails" in data;
    return hasErrorDetails;
  }

  static getUserFacingErrorMessage<T extends React.ReactElement | string>(
    error: unknown,
    fallbackMessgae: T,
  ) {
    const message = ResponseError.isResponseError(error)
      ? error.status >= 400 && error.status < 500 && error.message
        ? error.message
        : fallbackMessgae
      : fallbackMessgae;
    return message;
  }

  constructor(status: number, data: unknown, correlationId: string) {
    super();
    this.name = "ResponseError";
    this.status = status;
    this.data = data;
    this.correlationId = correlationId;
    if (typeof data === "string") {
      this.message = data;
    } else if (data && typeof data === "object" && "message" in data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions -- I already asserted that `message` exists
      this.message = (data as any).message;
    }

    Object.setPrototypeOf(this, ResponseError.prototype);
  }
}

export function errorToMessage(error: unknown): string {
  if (ResponseError.isResponseError(error)) {
    console.log(error.data);
    if (typeof error.data === "string" && error.data.length > 0) {
      return error.data;
    }
    if (error.data) {
      return JSON.stringify(error.data);
    }
    if (error.message) {
      return error.message;
    }
  }
  if (typeof error === "string") {
    return error;
  }
  return genericErrorCopy;
}

const isMaintenanceMessage = (data: unknown) =>
  data === UNSCHEDULED_MAINTENANCE_MESSAGE || data === SCHEDULED_MAINTENANCE_MESSAGE;

const axiosInstance = axios.create({ baseURL: "/" });
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const { status, data, headers } = error.response;
        const correlationId: string = headers["x-correlation-id"] || "";
        const contentType: string | undefined = headers["content-type"];
        const isApplicationJson = contentType == null || contentType.includes("application/json");

        if (status === 503 && isMaintenanceMessage(data)) {
          window.location.href = getLoginUrl();
        } else if (status === 401 && data !== INVALID_RECOVERY_TOKEN) {
          console.log("Aborting from Unauthorized request");
          window.location.href = getLoginUrl();
        } else if (String(data).includes("Sorry, you have been blocked")) {
          captureException(error);

          const data: {
            message: string;
            code: SemanticErrorCode;
          } = {
            message:
              "Sorry, you have been blocked. " +
              "This website is using a security service to protect itself from online attacks. " +
              "The action you just performed triggered the security solution.",
            code: "BLOCKED_BY_CLOUDFLARE",
          };
          const responseError = new ResponseError(status, data, correlationId);
          return Promise.reject(responseError);
        } else if (!isApplicationJson) {
          const responseError = new ResponseError(status, genericErrorCopy2, correlationId);
          return Promise.reject(responseError);
        } else {
          const responseError = new ResponseError(status, data, correlationId);
          console.dir(responseError);
          return Promise.reject(responseError);
        }
      } else if (error.request) {
        if (error instanceof axios.CanceledError) {
          return Promise.reject(error);
        } else {
          console.error(error);
          return Promise.reject(new RequestError(0, "No response received!"));
        }
      }
    }
    return Promise.reject(error);
  },
);

export const querySuccess = <Data>(
  data: Data,
  defaults: UseQueryResult<Data, ResponseError>,
): UseQueryResult<Data, ResponseError> => ({
  ...defaults,
  data,
  // help TS figure out successful state
  isLoading: false,
  isPending: false,
  isSuccess: true,
  isError: false,
  isLoadingError: false,
  isRefetchError: false,
  isRefetching: false,
  status: "success",
  error: null,
});

export const getQueryArgs = <Response = never, TError = ResponseError>({
  queryId,
  method,
  path,
  requestConfig,
  mapBlob,
  map,
  options,
}: SlobQueryType<Response, TError>) => {
  const select = options?.select;

  const queryKey = queryId ? [method, path, queryId] : [method, path];

  const queryFn = async () => {
    const options: AxiosRequestConfig = {
      ...requestConfig,
    };

    const promise = mapBlob
      ? axiosInstance[method]<Blob>(path, options).then((r) => mapBlob(r.data, r.headers))
      : map
      ? axiosInstance[method]<ResponseToJson<Response>>(path, options).then((r) => map(r.data))
      : null;

    if (promise == null) {
      throw new Error("Must define map or mapBlob");
    }

    return promise;
  };

  return { queryKey, queryFn, select, options };
};

/** Use for queries (GET) */
export const useSlobQuery = <Response = never, TError = ResponseError>(
  args: SlobQueryType<Response, TError>,
) => {
  const { options = {} } = args;
  const { queryKey, queryFn } = getQueryArgs(args);
  const query = useQuery<Response, TError>({ queryKey, queryFn, ...options });
  return query;
};

type HttpMutationMethod = "delete" | "post" | "put" | "patch";

export type PathBodyToVariables<Path extends string, Body, Query> = (Body extends undefined | void
  ? { data?: never }
  : { data: Body }) &
  // eslint-disable-next-line @typescript-eslint/ban-types -- we need {} here
  ({} extends ParseRouteParams<Path>
    ? { params?: never; query?: Query }
    : { params: ParseRouteParams<Path>; query?: Query });

export type ResponseToMutationReturnType<Response> = {
  data: Response;
  isSuccess: boolean;
  isError: boolean;
};

type SlobMutationType<TData, Body, TError, Path extends string, TContext, Query> = {
  method: HttpMutationMethod;
  path: Path;
  query?: Record<string, string>;
  mutationKey?: MutationKey;
  options?: UseMutationOptions<
    ResponseToMutationReturnType<TData>,
    TError,
    PathBodyToVariables<Path, Body, Query>,
    TContext
  >;
  map?: JsonToTypeMapper<TData>;
  headers?: { [header: string]: string };
};

/** Use for mutations (POST, PUT, PATCH, DELETE) */
export const useSlobMutation = <
  Body = void,
  TData = never,
  Path extends string = string,
  TError = ResponseError,
  TContext = unknown,
  Query = never,
>(
  {
    method,
    path,
    mutationKey,
    options = {},
    map,
    headers,
  }: SlobMutationType<TData, Body, TError, Path, TContext, Query>,
  axiosRequestConfig: Partial<AxiosRequestConfig> = {},
) => {
  const { CSRF_TOKEN } = useHubConfiguration();
  const mutationFn: MutationFunction<
    ResponseToMutationReturnType<TData>,
    PathBodyToVariables<Path, Body, Query>
  > = async (variables) => {
    const options: AxiosRequestConfig = {
      ...axiosRequestConfig,
      params: variables.query,
      headers: {
        ...headers,
        "x-csrf-token": CSRF_TOKEN,
      },
    };
    const parsedPath = variables.params
      ? path.replace(
          /\/:(\w+)/g,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-non-null-assertion -- we trust TypeScript that the type of param is the keyof of params
          (_, param) => `/${variables.params![param as keyof typeof variables.params]}`,
        )
      : path;

    // axios delete method call does not have data in method call
    const promise =
      method === "delete"
        ? axiosInstance[method]<ResponseToJson<TData>>(parsedPath, {
            ...options,
            data: variables.data,
          })
        : axiosInstance[method]<ResponseToJson<TData>>(parsedPath, variables.data, options);

    const ret = promise.then((r) => {
      const isSuccess = r.status >= 200 && r.status <= 300;
      const isError = !isSuccess;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- n/a
      const data = map ? map(r.data) : (r.data as TData);
      // useMutate.mutateAsync just returns this object (unlike useQuery)
      return {
        data,
        isSuccess,
        isError,
      };
    });

    return ret;
  };

  return useMutation({ mutationFn, mutationKey, ...options });
};

export const compareQueryId = (queryId: string) => (query: Query) =>
  Array.isArray(query.queryKey) && query.queryKey.some((v: string) => v === queryId);

export const compareQueryKey =
  ([method, path]: [method: HttpQueryMethod | HttpMutationMethod, path: string]) =>
  (query: Query) =>
    Array.isArray(query.queryKey) &&
    query.queryKey[0] === method &&
    typeof query.queryKey[1] === "string" &&
    query.queryKey[1].startsWith(path);

type ParseRouteParamsKeys<Path> = string extends Path
  ? string
  : Path extends `${string}/:${infer Param}/${infer Rest}`
  ? Param | ParseRouteParamsKeys<`/${Rest}`>
  : Path extends `${string}/:${infer Param}`
  ? Param
  : never;

type ParseRouteParams<Path> = Record<ParseRouteParamsKeys<Path>, string | number>;

export type GetMutateAsyncType<T> = T extends (...args: unknown[]) => { mutateAsync: infer R }
  ? R
  : never;

/**
 * @description Function parameters in union are contravariant. This transformation makes them covariant.
 */
export type ContravariantToCovariantMutation<T extends MutationFunctions> = (
  ...args: MutationParameters<T>
) => MutationReturnType<T>;
type MutationFunctions = ReadonlyArray<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is needed
  (...args: unknown[]) => UseMutationResult<any, any, any, any>
>;
type MutationParameters<T extends MutationFunctions> = {
  [K in keyof T & number]: Parameters<GetMutateAsyncType<T[K]>>;
}[number];
type MutationReturnType<T extends MutationFunctions> = {
  [K in keyof T & number]: ReturnType<GetMutateAsyncType<T[K]>>;
}[number];
