import {
  getIsPFMLBenefitType,
  getIsPFMLFullyInsuredBenefitType,
  getIsPFMLASOBenefitType,
} from "shared/types/BenefitTypes";
import { benefitTypeToCoverage, getCoverageHasMonthlyClaim } from "shared/types/SlfCoverages";
import type { Plan } from "shared/types/Plan";

export function getPlansWhereCanSetMonthlyClaimsLocations(clientPlans: Plan[]) {
  const relevantPlans = clientPlans.filter((clientPlan) => {
    const coverageHasMonthlyClaim = getCoverageHasMonthlyClaim(
      benefitTypeToCoverage[clientPlan.benefitType],
    );
    const isPFMLBenefitType = getIsPFMLBenefitType(clientPlan.benefitType);
    const isPFMLFullyInsuredBenefitType = getIsPFMLFullyInsuredBenefitType(clientPlan.benefitType);
    const isPFMLASOBenefitType = getIsPFMLASOBenefitType(clientPlan.benefitType);
    const relevantNonPfmlPlan = coverageHasMonthlyClaim && !isPFMLBenefitType;
    const relevantPfmlPlan =
      coverageHasMonthlyClaim &&
      (isPFMLFullyInsuredBenefitType ||
        (isPFMLASOBenefitType && clientPlan.pfmlOption === "ADVICE_PLUS"));
    return relevantNonPfmlPlan || relevantPfmlPlan;
  });
  return relevantPlans;
}
