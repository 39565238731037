import { useRef } from "react";

let id = 0;
function getId() {
  return (++id).toString();
}

export function useSlobId(props: { prefix?: string }) {
  // DOM id attributes that start with a number are not valid,
  // so we add an "_" here at the start to prevent that
  const idRef = useRef("_" + (props.prefix || "") + getId());
  return idRef.current;
}
