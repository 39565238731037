import clsx from "clsx";

import * as styles from "./print.module.less";

type PrintWrapperProps = {
  block?: boolean;
  expanded?: boolean;
  inline?: boolean;
};
export const PrintOnly = ({
  block,
  expanded,
  inline,
  children,
}: React.PropsWithChildren<PrintWrapperProps>) => (
  <div
    className={clsx(
      styles.printOnly,
      block && styles.block,
      expanded && styles.expanded,
      inline && styles.inline,
    )}
  >
    {children}
  </div>
);

export const PrintHidden = ({
  block,
  expanded,
  inline,
  children,
}: React.PropsWithChildren<PrintWrapperProps>) => (
  <div
    className={clsx(
      styles.printHidden,
      block && styles.block,
      expanded && styles.expanded,
      inline && styles.inline,
    )}
  >
    {children}
  </div>
);

export const PrintPageBreak = () => <div className={styles.pageBreak} />;

export const AvoidPageBreak = (props: { children: React.ReactNode }) => (
  <div className={styles.breakInsideAvoid} children={props.children} />
);
