import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import { useCallback } from "react";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { NavBar, NavBarSkeleton } from "../../components/NavBar/NavBar";
import { SlobNavLink } from "../../components/SlobLink/SlobLink";
import { StackX } from "../../components/Spacing/Spacing";
import { SupportFooter } from "../../components/SupportFooter/SupportFooter";
import { useSlobAuth } from "../../hooks/auth";
import { useGetClientByID } from "../../hooks/client";
import { useCloseOnPressEscape } from "../../hooks/useCloseOnPressEscape";
import { useExpandCollapseTransitionReducer } from "../../hooks/useExpandCollapseTransitionReducer";
import { useTrackElementClicked } from "../../utils/analytics";
import { PrintHidden } from "../../utils/print";
import { useSetZendeskEmployerNameField } from "../../utils/zendesk";

import { NavItemPopover } from "./NavItemPopover";
import * as styles from "./hubNav.module.less";
import type { logOutType } from "./NavBarPage";

import type { ClientId } from "shared/types/Client";

export type HubNavRouteType = "normal" | "welcome" | "outside-signer";

type HubNavPageProps = {
  logOut: logOutType;
  clientId: ClientId;
  routeType: HubNavRouteType;
};

export const HubNavPage = ({ logOut, clientId, routeType }: HubNavPageProps) => {
  const { data: client, isLoading } = useGetClientByID(clientId);
  const { authUser } = useSlobAuth();
  const isWelcomeRoute = routeType === "welcome";
  const isOutsideSignerRoute = routeType === "outside-signer";

  const trackElementClicked = useTrackElementClicked(client);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Top navigation",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );
  const logOutClick = useCallback(async () => {
    track("Sign Out");
    await logOut({
      data: {},
    });
  }, [track, logOut]);

  useSetZendeskEmployerNameField(client?.id, client?.ticketId, client?.name);

  const {
    transitionState: supportBannerTransitionState,
    setTransitionState: setSupportBannerTransitionState,
  } = useExpandCollapseTransitionReducer();
  const supportInteractible =
    supportBannerTransitionState === "expanding" || supportBannerTransitionState === "expanded";

  useCloseOnPressEscape({
    popoverVisible: supportInteractible,
    setPopoverVisible: (_visible) => setSupportBannerTransitionState("toggle"),
  });

  if (isLoading) {
    return <NavBarSkeleton />;
  }

  if (client?.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  const userName = authUser?.name || "";

  const getHelpLink = (
    <PrintHidden inline>
      <Button // @todo: FP-3498: move to NavLink component (yellow underline)
        size="middle"
        type="text-only-link"
        className={styles.getHelp}
        onClick={() => {
          track("Get Help");
          setSupportBannerTransitionState("toggle");
        }}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
        Get Help
      </Button>
    </PrintHidden>
  );

  const rightLinks = isOutsideSignerRoute ? (
    <StackX dist={48}>{getHelpLink}</StackX>
  ) : (
    <StackX dist={48}>
      {!isWelcomeRoute && (
        <>
          <PrintHidden inline>
            <SlobNavLink end to={RouteData.homeHub.getPath(clientId)} onClick={() => track("Home")}>
              Home
            </SlobNavLink>
          </PrintHidden>

          <PrintHidden inline>
            <SlobNavLink
              to={RouteData.clientTaskDetail.getPath(clientId, "enrollment-resources")}
              onClick={() => track("Employee & Employer Resources")}
            >
              Employee & Employer Resources
            </SlobNavLink>
          </PrintHidden>

          {getHelpLink}
        </>
      )}

      <PrintHidden inline>
        {isWelcomeRoute ? (
          <Button size="middle" type="text-only-link" onClick={logOutClick}>
            Sign Out
          </Button>
        ) : (
          <NavItemPopover
            clientId={client?.id}
            clientName={client?.name}
            userName={userName}
            email={authUser?.email}
            logOut={logOut}
            track={track}
          />
        )}
      </PrintHidden>
    </StackX>
  );

  return (
    <div>
      <NavBar
        homeLink={RouteData.homeHub.getPath(clientId)}
        clientId={clientId}
        rightLinks={rightLinks}
      />

      {!isWelcomeRoute && (
        <FocusTrap
          active={supportInteractible}
          focusTrapOptions={{ escapeDeactivates: false, allowOutsideClick: true }}
        >
          <div>
            <div
              className={clsx(
                styles.support,
                supportBannerTransitionState === "collapsed" && styles.collapsed,
                supportBannerTransitionState === "expanding" && styles.expanding,
                supportBannerTransitionState === "expanded" && styles.expanded,
              )}
              aria-hidden={!supportInteractible}
              onTransitionEnd={(e) => {
                if (e.target === e.currentTarget) {
                  setSupportBannerTransitionState("end");
                }
              }}
            >
              <hr />
              <SupportFooter />
            </div>

            <div
              className={clsx(
                styles.mask,
                supportBannerTransitionState === "collapsed" && styles.collapsed,
                supportBannerTransitionState === "expanding" && styles.expanding,
                supportBannerTransitionState === "expanded" && styles.expanded,
              )}
              onClick={() => setSupportBannerTransitionState("toggle")}
              onKeyUp={() => setSupportBannerTransitionState("toggle")}
              role="button"
              aria-label="Close support information"
              tabIndex={-1}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
};
