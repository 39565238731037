import Hotjar from "@hotjar/browser";

import { isLocalDev, isTest } from "shared/utils/config";
import type { UserId } from "shared/types/User";

export function initHojar() {
  if (isLocalDev || isTest) {
    console.log("Hotjar is disabled in local development.");
    return;
  }
  const siteId = 3351579;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}

let existingHotjarUserId: UserId | null = null;

export const setHotjarUserId = (userId: UserId | null) => {
  if (isLocalDev || isTest || !Hotjar.isReady()) {
    return;
  }
  const isDifferent = existingHotjarUserId !== userId;

  if (isDifferent && userId !== null) {
    existingHotjarUserId = userId;
    Hotjar.identify(userId, {});
  }
};
