import { DateTime } from "luxon";
import { assertIsDefined } from "shared/utils/utils";
import { monthDaySchema } from "shared/validation/monthDaySchema";

export function getIsValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export const isDateInThePast = (date: Date) => {
  const now = new Date();
  return now > date;
};

export const getDaysFromToday = (date: Date) => {
  const luxonInstance = DateTime.fromJSDate(date, { zone: "UTC" });
  const today = DateTime.utc().startOf("day");
  const diff = luxonInstance.diff(today, "days");
  const diffDays = Math.floor(diff.days);
  return diffDays;
};

export function isBusinessDay(dateTime: DateTime) {
  // 6 - Saturday, 7 - Sunday
  return dateTime.weekday < 6;
}
function isSunday(dateTime: DateTime) {
  // 7 - Sunday
  return dateTime.weekday === 7;
}

export const getBusinessDaysFromToday = (date: Date) => {
  const luxonInstance = DateTime.fromJSDate(date, { zone: "UTC" });
  const today = DateTime.utc().startOf("day");
  const diff = luxonInstance.diff(today, "days");
  const diffAbsolute = Math.abs(diff.days);
  const pastDate = diff.days < 0;
  // Without doing this adjustment, when starting on a weekend with a date in the past, we implicitly count that as a business day incorrectly.
  const diffAccountingForWeekendStart =
    !isBusinessDay(today) && pastDate ? diff.days + 1 : diff.days;
  let numberOfWeekendDaysBetweenStartAndEnd = 0;
  for (let i = 1; i < diffAbsolute + 1; i++) {
    const relevantDay = pastDate ? today.minus({ days: i }) : today.plus({ days: i });
    if (!isBusinessDay(relevantDay)) {
      numberOfWeekendDaysBetweenStartAndEnd++;
    }
  }

  // When doing the Sunday -> Saturday calculation, the `numberOfWeekendDaysBetweenStartAndEnd` adjustments we do break this one specific case
  if (isSunday(today) && diff.days === -1) {
    return diffAccountingForWeekendStart;
  }

  // This augments the diff to remove the weekend days from the calculation
  if (pastDate) {
    return diffAccountingForWeekendStart + numberOfWeekendDaysBetweenStartAndEnd;
  } else {
    return diffAccountingForWeekendStart - numberOfWeekendDaysBetweenStartAndEnd;
  }
};

export const getDaysFromDates = (dateStart: Date, dateEnd: Date) => {
  const luxonInstance = DateTime.fromJSDate(dateStart, { zone: "UTC" }).startOf("day");
  const diff = luxonInstance.diff(
    DateTime.fromJSDate(dateEnd, { zone: "UTC" }).startOf("day"),
    "days",
  );
  return diff.days;
};

export const isToday = (date: Date) => {
  const daysFromToday = getDaysFromToday(date);
  return daysFromToday === 0;
};

export const getIsCoverageActive = (policyEffective: Date | null | undefined) => {
  const isCoverageActive = policyEffective
    ? isToday(policyEffective) || isDateInThePast(policyEffective)
    : null;
  return isCoverageActive;
};

export const getDateStringFromShortString = (monthAndDay: string): string => {
  // Fix year to make sure Feb 29 is a valid date
  return DateTime.fromFormat(monthAndDay + "/2004", "MM/dd/yyyy").toFormat("MMMM dd");
};

/**
 * Given a `Date` object, returns another `Date` object
 * in the next business day.
 *
 * e.g. Wed Jul 27 2022 10:36:27 => Thu Jul 28 2022 10:36:27
 * e.g. Fri Jul 29 2022 10:36:27 => Mon Aug 01 2022 10:36:27
 *
 * @param date
 * @returns
 */
export function getNextBusinessDay(date: Date) {
  const dateDate = date.getDate();
  const dateDay = date.getDay();

  const nextBusinessDay = new Date(date);

  if (dateDay === 5 || dateDay === 6) {
    // Friday and Saturday
    nextBusinessDay.setDate(dateDate + 3);
  } else if (dateDay === 0) {
    // Sunday
    nextBusinessDay.setDate(dateDate + 2);
  } else {
    nextBusinessDay.setDate(dateDate + 1);
  }

  return nextBusinessDay;
}

export function getIsTodayAfterNextBusinessDayOf(date: Date) {
  const nextBusinessDay = getNextBusinessDay(date);
  const now = new Date();
  const isTodayAfterNextBusinessDayOf = now > nextBusinessDay;
  return isTodayAfterNextBusinessDayOf;
}

/**
 * Returns true if the difference between the dates exceeds 30 days.
 * Inputs must be in format MM/DD.
 * iF endDate appears to be before startDate (e.g. start: 12/10, end: 01/25),
 * assume that the endDate is in the following year.
 *
 * @param startDate MM/DD
 * @param endDate MM/DD
 */
export function getExceeds30Days(startDate: string, endDate: string) {
  try {
    monthDaySchema.validateSync(startDate);
    monthDaySchema.validateSync(endDate);
  } catch {
    return null;
  }

  const [startDateMonth, startDateDay] = startDate.split("/");
  const [endDateMonth, endDateDay] = endDate.split("/");

  assertIsDefined(startDateMonth, "startDateMonth");
  assertIsDefined(startDateDay, "startDateDay");
  assertIsDefined(endDateMonth, "endDateMonth");
  assertIsDefined(endDateDay, "endDateDay");

  // any non-leap year
  // following year must also be non-leap
  const referenceYear = 2001;

  const startDateLuxon = DateTime.fromObject({
    year: referenceYear,
    month: Number.parseInt(startDateMonth, 10),
    day: Number.parseInt(startDateDay, 10),
  });
  const endDateLuxon = DateTime.fromObject({
    year: referenceYear,
    month: Number.parseInt(endDateMonth, 10),
    day: Number.parseInt(endDateDay, 10),
  });

  const diff =
    endDateLuxon >= startDateLuxon
      ? endDateLuxon.diff(startDateLuxon, ["days"])
      : endDateLuxon.set({ year: referenceYear + 1 }).diff(startDateLuxon, ["days"]);

  const exceeds30Days = diff.days > 30;
  return exceeds30Days;
}

type DateRange = {
  startDate: Date;
  endDate: Date;
};

export const getPreviousMonthRange = (inputDate: Date): DateRange => {
  const date = new Date(inputDate.getTime());
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);

  const startDate = new Date(date.getTime());

  date.setMonth(date.getMonth() + 1);
  date.setDate(0);

  const endDate = new Date(date.getTime());

  return {
    startDate,
    endDate,
  };
};

/**
 * Returns the date in MM/DD format
 */
export const formatDateToMMDD = (date: Date): string =>
  date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
  });

/**
 * Returns the date in YYYY-MM-DD format
 */
export const formatDateOnlyToISOString = (date: Date): string =>
  DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
