import { faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import Info from "../../components/Icons/Info.svg?react";
import WarningInfo from "../../components/Icons/WarningInfo.svg?react";

import * as styles from "./alertBanner.module.less";

import type { ReactNode, ReactElement } from "react";

export type Variant = "info" | "error" | "success" | "warning";

type BannerProps = {
  icon?: ReactNode;
  variant: Variant;
  message: ReactElement;
  loading?: boolean;
  "w-full"?: boolean;
};

const variantStyle: Record<BannerProps["variant"], string> = {
  info: styles.info,
  error: styles.error,
  success: styles.success,
  warning: styles.warning,
};

const variantIcon: Record<BannerProps["variant"], ReactElement> = {
  info: <Info />,
  error: <FontAwesomeIcon icon={faExclamationTriangle} />,
  success: <FontAwesomeIcon icon={faCheckCircle} />,
  warning: <WarningInfo />,
};

export function AlertBanner({ icon, variant, message, loading, "w-full": wFull }: BannerProps) {
  const _icon = icon == null ? variantIcon[variant] : icon === false ? null : icon;

  return (
    <div
      className={clsx(
        styles.banner,
        variantStyle[variant],
        loading && styles.loading,
        wFull && "w-full",
      )}
    >
      <div className={clsx(styles.icon, "mr-12")}>{_icon}</div>

      {message}
    </div>
  );
}
