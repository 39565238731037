import * as Yup from "yup";

const monthDayRegex = /^\d{1,2}\/\d{1,2}$/;

const monthDayMatchesArguments = [monthDayRegex, "Date needs to be in the format MM/DD"] as const;

const monthDayTestOptions = {
  name: "provided day does not exceed days in month",
  message: "Not a valid date",
  test: (value: string | null | undefined) => {
    // We'll let the ".required()" validation handle an empty value
    if (!value) {
      const isValid = true;
      return isValid;
    }

    // We'll let the ".matches()" validation handle an incorrect format
    const isFormatCorrect = monthDayRegex.test(value);
    if (!isFormatCorrect) {
      const isValid = true;
      return isValid;
    }

    // Now we focus on what this validation is about,
    // that the given month has the amount of days provided

    const [monthStr, dayStr] = value.split("/");

    if (monthStr == null || dayStr == null) return false;

    const month = Number.parseInt(monthStr, 10);
    const day = Number.parseInt(dayStr, 10);

    const REFERENCE_YEAR = 2024; // 2024 is a leap year so it will allow Feb 29
    const lastDayOfMonth = new Date(REFERENCE_YEAR, month, 0).getDate();

    const isMonthValid = month > 0 && month <= 12;
    const isDayValid = day > 0 && day <= lastDayOfMonth;

    const isValid = isMonthValid && isDayValid;
    return isValid;
  },
};

export const monthDaySchema = Yup.string()
  .nullable()
  .required("Please provide a response")
  .matches(...monthDayMatchesArguments)
  .test(monthDayTestOptions)
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema.notRequired(),
  });
