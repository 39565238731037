import { useSlobQuery } from "./query";
import type { ServiceCheckStatus } from "shared/types/ServiceCheck";

export const useGetServiceStatus = () =>
  useSlobQuery<ServiceCheckStatus>({
    method: "get",
    path: `/api/service-status`,
    map: (response) => response,
    options: {
      gcTime: 5 * 60 * 1000, // 5 minutes
    },
  });
