import * as styles from "./pageContent.module.less";

import type { FC } from "react";

export const PageContent: FC<{ style?: React.CSSProperties; children?: React.ReactNode }> = ({
  children,
  style,
}) => (
  <div className={styles.pageContentContainer} style={style}>
    {children}
  </div>
);
