import { useSlobQuery } from "client/src/hooks/query";
import type { AdditionalCompensation } from "@prisma/client";
import type { JsonToTypeMapper } from "client/src/hooks/query";
import type { ClientId } from "shared/types/Client";

export const jsonAdditionalCompensation: JsonToTypeMapper<AdditionalCompensation> = (
  additionalCompensation,
) => {
  return {
    ...additionalCompensation,
    createdAt: new Date(additionalCompensation.createdAt),
    updatedAt: new Date(additionalCompensation.updatedAt),
    deletedAt: additionalCompensation.deletedAt ? new Date(additionalCompensation.deletedAt) : null,
  };
};

export type GetAdditionalCompensationsQuery = Pick<
  ReturnType<typeof useGetAdditionalCompensations>,
  "isPending" | "data"
>;

export function useGetAdditionalCompensations(
  clientId: ClientId,
  { enabled }: { enabled: boolean } = { enabled: true },
) {
  const query = useSlobQuery<AdditionalCompensation[]>({
    method: "get",
    path: `/api/clients/${clientId}/additional-compensations`,
    map: (ec) => ec.map(jsonAdditionalCompensation),
    options: { enabled },
  });

  return query;
}
