import type { Task } from "shared/data/Tasks";

export function getShouldDisplayDueDate({ dueDate, status }: Task): boolean {
  if (!dueDate) {
    return false;
  }

  if (
    status === "Not Started" ||
    status === "In Progress" ||
    status === "NIGO" ||
    status === "Upcoming"
  ) {
    return true;
  }

  if (status === "In Review" || status === "Done") {
    return false;
  }

  return false;
}
