import { RouteData } from "shared/config/routeData";

import { NavBar } from "../../components/NavBar/NavBar";
import { SlobLink } from "../../components/SlobLink/SlobLink";
import { useActivationTokenExpired } from "../../hooks/user";

import * as styles from "./authNav.module.less";

export const AuthNavPage = () => {
  const { isExpired } = useActivationTokenExpired();
  return (
    <>
      <nav className={styles.authNav}>
        <div className={styles.header}>
          {isExpired ? (
            <NavBar
              homeLink={RouteData.login.getPath()}
              rightLinks={<SlobLink to={RouteData.login.getPath()}>Sign In</SlobLink>}
            />
          ) : (
            <NavBar homeLink={RouteData.login.getPath()} rightLinks={null} />
          )}
        </div>
      </nav>
    </>
  );
};
