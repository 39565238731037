import React from "react";
import type { ReactNode } from "react";

export function getIsEmptySpan(node: ReactNode) {
  if (React.isValidElement(node)) {
    const isSpan = node.type === "span";

    const propsLength =
      node.props && typeof node.props === "object" ? Object.keys(node.props).length : 0;

    const isEmptySpan = isSpan && propsLength === 0;
    return isEmptySpan;
  }
  return false;
}
