import clsx from "clsx";

import * as styles from "./topBanner.module.less";

type TopBannerProps = {
  children?: React.ReactNode;
  mobile?: boolean;
};

export const TopBanner = ({ children, mobile }: TopBannerProps) => {
  return (
    <div className={clsx(styles.topBanner, mobile && styles.mobile)}>
      <span className={styles.text}>{children}</span>
    </div>
  );
};
