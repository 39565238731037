import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { LoadingError } from "./LoadingError";
import type { FallbackRender } from "@sentry/react";
import type { Location } from "react-router-dom";

const generateUrl = (location: Location) => location.pathname + location.search + location.hash;

const eventToUrlMap: Map<string, string> = new Map();

export const BoundaryError: FallbackRender = ({ resetError, error, eventId }) => {
  const location = useLocation();

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    const eventUrl = eventToUrlMap.get(eventId);
    const url = generateUrl(location);

    if (!eventUrl) {
      eventToUrlMap.set(eventId, url);
    } else {
      if (eventUrl !== url) {
        eventToUrlMap.delete(eventId);
        resetError();
      }
    }
  }, [location, eventId, resetError]);

  return <LoadingError type="page" error={error} />;
};
