import { Button } from "client/src/components/Button/Button";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";

import BackArrow from "../Icons/BackArrow.svg?react";
import { Body2 } from "../Typography/Typography";

import * as styles from "./back.module.less";

import type { MouseEventHandler } from "react";

type BackProps = {
  to: string | null;
  children?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  variant?: "blue" | "yellow";
};

export function Back({ to, children, onClick, variant = "blue" }: BackProps) {
  return (
    <Body2 blue={variant === "blue"} yellow={variant === "yellow"}>
      {to == null ? (
        <span
          className={clsx(
            styles.back,
            "stack-x-8",
            variant === "yellow" && styles.yellow,
            styles.disabled,
          )}
        >
          <BackArrow />
          <span>{children}</span>
        </span>
      ) : (
        <Link
          to={to}
          className={clsx(styles.back, "stack-x-4", variant === "yellow" && styles.yellow)}
          onClick={onClick}
        >
          <BackArrow />
          <span>{children}</span>
        </Link>
      )}
    </Body2>
  );
}

export function BackDynamic({
  fallbackTo,
}: {
  /**
   * The URL to fall back to if there's nothing in the browser history.
   * This can happen if the currnt page was opened in a new tab or window.
   */
  fallbackTo: string;
}) {
  const navigate = useNavigate();

  if (window.history.length === 1) {
    return <Back to={fallbackTo}>Back</Back>;
  }

  return (
    <Button
      type="text-only"
      role="link"
      icon={<BackArrow />}
      size="middle"
      onClick={() => navigate(-1)}
    >
      Back
    </Button>
  );
}
