import { isDEIFChangeSnapshotEntity } from "shared/types/Change";
import { eifSubStepIds } from "shared/types/EIF";
import { getChangeDetailInfoListForSubStep } from "shared/utils/EIF/getChangeDetailInfoList";
import { isEqual } from "shared/utils/utils";
import type {
  ChangeWithDetails,
  DEIFChangeSnapshot,
  ChangeDetailInfo,
  DEIFSnapshotEntity,
  ChangeDetailRecordWithMetadata,
  ChangeMetadata,
} from "shared/types/Change";
import type { Client } from "shared/types/Client";

const getChangeDetailInfo = (changeLogs: ChangeWithDetails[]): Record<string, ChangeDetailInfo> => {
  const changeDetailRecord: Record<string, ChangeDetailInfo> = {};

  for (const changeLog of changeLogs) {
    for (const changeDetail of changeLog.changeDetails) {
      // If the attribute doesn't exist in the record, add it
      if (!changeDetailRecord[changeDetail.attributeKey]) {
        changeDetailRecord[changeDetail.attributeKey] = {
          status: changeDetail.status,
          currentValue: {
            value: changeDetail.value,
            user: changeLog.createdByUser,
            date: changeDetail.createdAt,
          },
          previousValue: {
            value: changeDetail.previousValue,
            date: changeDetail.createdAt,
          },
        };
      } else if (changeDetailRecord[changeDetail.attributeKey]) {
        // If the current value is newer than the previous value, update the current value
        if (
          changeDetailRecord[changeDetail.attributeKey] &&
          changeDetail.createdAt.getTime() >
            (changeDetailRecord[changeDetail.attributeKey]?.currentValue.date.getTime() ?? 0)
        ) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- already checked above
          changeDetailRecord[changeDetail.attributeKey]!.currentValue = {
            value: changeDetail.value,
            user: changeLog.createdByUser,
            date: changeDetail.createdAt,
          };
        }

        // If the previous value is newer than the current value, update the previous value
        if (
          changeDetailRecord[changeDetail.attributeKey] &&
          changeDetail.createdAt.getTime() <
            (changeDetailRecord[changeDetail.attributeKey]?.previousValue.date.getTime() ?? 0)
        ) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- already checked above
          changeDetailRecord[changeDetail.attributeKey]!.previousValue = {
            value: changeDetail.previousValue,
            date: changeDetail.createdAt,
          };
        }
      }
    }
  }

  for (const attribute in changeDetailRecord) {
    if (
      isEqual(
        changeDetailRecord[attribute]?.currentValue.value,
        changeDetailRecord[attribute]?.previousValue.value,
      )
    ) {
      delete changeDetailRecord[attribute];
    }

    if (
      (changeDetailRecord[attribute]?.currentValue.value === false &&
        changeDetailRecord[attribute].previousValue.value === null) ||
      (changeDetailRecord[attribute]?.currentValue.value === null &&
        changeDetailRecord[attribute].previousValue.value === false)
    ) {
      delete changeDetailRecord[attribute];
    }
  }

  const entityCreatedDuringICEdit =
    changeDetailRecord.createdAt?.currentValue.value &&
    changeDetailRecord.createdAt.previousValue.value === null;

  const entityDeletedDuringICEdit =
    changeDetailRecord.deletedAt?.currentValue.value &&
    changeDetailRecord.deletedAt.previousValue.value === null;

  if (entityCreatedDuringICEdit && entityDeletedDuringICEdit) {
    // If an entity was created and then deleted during the same IC edit, there are no relevant changes
    return {};
  } else {
    return changeDetailRecord;
  }
};

const getRelationalChangeDetailInfo = (
  changeLogs: ChangeWithDetails[],
): Record<string, ChangeDetailRecordWithMetadata<string>> => {
  const changeLogById: Record<string, ChangeWithDetails[]> = {};

  for (const changeLog of changeLogs) {
    if (changeLogById[changeLog.entityId]) {
      changeLogById[changeLog.entityId]?.push(changeLog);
    } else {
      changeLogById[changeLog.entityId] = [changeLog];
    }
  }

  const changeDetailRecord: Record<string, ChangeDetailRecordWithMetadata<string>> = {};
  for (const entityId in changeLogById) {
    const change = changeLogById[entityId];
    if (!change) continue;
    const changeDetailInfo = getChangeDetailInfo(change);
    if (Object.values(changeDetailInfo).length === 0) continue;
    changeDetailRecord[entityId] = changeDetailInfo;
    if (change[0]?.metadata) {
      const changeDetail = changeDetailRecord[entityId];
      changeDetail.metadata = change[0].metadata;
    }
  }

  return changeDetailRecord;
};

export const getDEIFChangeSnapshot = (changeLogs: ChangeWithDetails[]): DEIFChangeSnapshot => {
  const changeLists: Record<DEIFSnapshotEntity, ChangeWithDetails[]> = {
    Client: [],
    Policy: [],
    EmployeeClass: [],
    EmployeeClassPlan: [],
    Contact: [],
    Bill: [],
    BillLocation: [],
    ContactLocation: [],
    Location: [],
    Plan: [],
    MonthlyClaimsReportMailingLocation: [],
    Subsidiary: [],
  };

  for (const changeLog of changeLogs) {
    if (isDEIFChangeSnapshotEntity(changeLog.entity)) {
      changeLists[changeLog.entity].push(changeLog);
    }
  }

  return {
    Client: getChangeDetailInfo(changeLists["Client"]),
    Policy: getRelationalChangeDetailInfo(changeLists["Policy"]),
    EmployeeClass: getRelationalChangeDetailInfo(changeLists["EmployeeClass"]),
    EmployeeClassPlan: getRelationalChangeDetailInfo(changeLists["EmployeeClassPlan"]),
    Contact: getRelationalChangeDetailInfo(changeLists["Contact"]),
    Location: getRelationalChangeDetailInfo(changeLists["Location"]),
    Bill: getRelationalChangeDetailInfo(changeLists["Bill"]),
    BillLocation: getRelationalChangeDetailInfo(changeLists["BillLocation"]),
    ContactLocation: getRelationalChangeDetailInfo(changeLists["ContactLocation"]),
    Plan: getRelationalChangeDetailInfo(changeLists["Plan"]),
    MonthlyClaimsReportMailingLocation: getRelationalChangeDetailInfo(
      changeLists["MonthlyClaimsReportMailingLocation"],
    ),
    Subsidiary: getRelationalChangeDetailInfo(changeLists["Subsidiary"]),
  };
};

export function getAreTherePendingChanges(args: {
  changeSnapshot: DEIFChangeSnapshot;
  client: Client;
}) {
  const { changeSnapshot, client } = args;

  for (const eifSubStepId of eifSubStepIds) {
    const list = getChangeDetailInfoListForSubStep({
      eifSubStepId,
      changeSnapshot,
      client,
      contacts: client.contacts,
      clientPlans: client.plans,
      bills: client.bills,
      deletedBills: client.deletedBills,
      policies: client.policies,
      subsidiaries: client.subsidiaries,
    });
    const anyPendingChanges = list.some(
      (item) => item && "status" in item && item.status === "pending",
    );
    if (anyPendingChanges) return true;
  }

  return false;
}

export function wasEntityAddedInLatestICEdit(entity: { createdAt?: Date }, client: Client) {
  if (entity.createdAt == null) return true;
  const lastSignedDeclinedAccepted = new Date(
    Math.max(
      client.eifSignedAt?.getTime() ?? 0,
      client.deifChangesAcceptedAt?.getTime() ?? 0,
      client.deifChangesDeclinedAt?.getTime() ?? 0,
    ),
  );
  return !!(client.eifSignedAt && entity.createdAt > lastSignedDeclinedAccepted);
}

export function getIsChangeDetailInfo(
  recordWithMetadata: ChangeDetailInfo | ChangeMetadata | undefined,
): recordWithMetadata is ChangeDetailInfo {
  const isMetadata = recordWithMetadata && "parentId" in recordWithMetadata;
  return !isMetadata;
}
