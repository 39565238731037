import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { jsonContactToContact } from "./contact";
import { jsonLocationToLocation } from "./location";
import { compareQueryKey, useSlobMutation, useSlobQuery } from "./query";
import type { JsonToTypeMapper, ResponseError } from "./query";
import type { Bill } from "shared/types/Bill";
import type { ClientId, PolicyId } from "shared/types/Client";
import type { BillFormValues } from "shared/validation/bill";

const selectNonDeletedBills = (bills: Bill[]) => bills.filter((bill) => !bill.deletedAt);

const selectDeletedBills = (bills: Bill[]) => bills.filter((bill) => bill.deletedAt);

const useGetAllBills = (
  clientId: ClientId,
  select: typeof selectNonDeletedBills | typeof selectDeletedBills,
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const query = useSlobQuery<Bill[]>({
    method: "get",
    path: `/api/clients/${clientId}/bills`,
    map: (bills) => bills.map(jsonBillToBill),
    options: { select, enabled },
  });
  return query;
};

export const useGetBills = (clientId: ClientId) => {
  const query = useGetAllBills(clientId, selectNonDeletedBills);
  return query;
};

export const useGetDeletedBills = (
  clientId: ClientId,
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const query = useGetAllBills(clientId, selectDeletedBills, { enabled });
  return query;
};

export const useBulkUpsertBill = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    { bills: BillFormValues[] },
    Bill[],
    "/api/clients/:clientId/bills/bulk",
    ResponseError,
    unknown
  >({
    method: "put",
    path: `/api/clients/:clientId/bills/bulk`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
};

export const useDeleteBillsForClient = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    { policyId: PolicyId; billIds?: string[] },
    { deleted: number },
    "api/clients/:clientId/bills/bulk"
  >({
    method: "delete",
    path: `api/clients/:clientId/bills/bulk`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/bills`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/contacts`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
};

export const jsonBillToBill: JsonToTypeMapper<Bill> = (bill) => {
  return {
    ...bill,
    createdAt: bill.createdAt ? new Date(bill.createdAt) : new Date(),
    updatedAt: bill.updatedAt ? new Date(bill.updatedAt) : new Date(),
    deletedAt: bill.deletedAt ? new Date(bill.deletedAt) : null,
    contact: bill.contact ? jsonContactToContact(bill.contact) : undefined,
    location: bill.location && jsonLocationToLocation(bill.location),
    groupedByLocations: bill.groupedByLocations?.map(jsonLocationToLocation),
    categoriesByLocation: bill.categoriesByLocation?.map((cat) => cat.map(jsonLocationToLocation)),
  };
};
