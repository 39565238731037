import clsx from "clsx";

import * as styles from "./spinner.module.less";

type SpinnerProps = {
  size?: "large" | "small" | "xsmall" | "xxsmall";
  initialColor?: "white" | "transparent";
  color?: "default" | "primary" | "secondary" | "tertiary" | "danger" | "dangerTertiary";
  opaque?: boolean;
};

const SizeToStyle: Record<NonNullable<SpinnerProps["size"]>, string> = {
  large: styles.large,
  small: styles.small,
  xsmall: styles.xsmall,
  xxsmall: styles.xxsmall,
};

const ColorToStyle: Record<NonNullable<SpinnerProps["color"]>, string> = {
  default: styles.defaultColor,
  primary: styles.primary,
  secondary: styles.secondary,
  tertiary: styles.tertiary,
  danger: styles.danger,
  dangerTertiary: styles.dangerTertiary,
};

const InitialColorToStyle: Record<NonNullable<SpinnerProps["initialColor"]>, string> = {
  white: styles.whiteInitialColor,
  transparent: styles.transparentInitialColor,
};

export const Spinner = ({
  size = "large",
  color = "default",
  initialColor = "white",
  opaque = false,
}: SpinnerProps) => {
  return (
    <div
      className={clsx([
        styles.spinner,
        SizeToStyle[size],
        InitialColorToStyle[initialColor],
        ColorToStyle[color],
        !opaque ? styles.light : undefined,
      ])}
    ></div>
  );
};
