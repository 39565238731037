import { useRef, useState, useEffect } from "react";

export function useIntersectionObserver<THTMLElement extends HTMLElement>(
  options?: IntersectionObserverInit,
) {
  const ref = useRef<THTMLElement>(null);

  const [entry, setEntry] = useState<IntersectionObserverEntry | undefined>();

  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    const target = ref.current;
    if (!target) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries.find((e) => e.target === target);
        setEntry(entry);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
        ...optionsRef.current,
      },
    );

    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  }, []);

  return { ref, entry };
}
