import { datadogRum } from "@datadog/browser-rum";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { extractSearchParams } from "shared/data/Analytics";

import { isLocalDev, isTest } from "shared/utils/config";
import { useSlobAuth } from "../hooks/auth";
import { useGetClientByID } from "../hooks/client";
import { useGetActiveRouteData } from "../hooks/route";
import { useHubConfiguration } from "../hooks/useConfig";
import type { PhaseId } from "@prisma/client";
import type { TaskName } from "shared/data/Tasks";

import type { Client, ClientId } from "shared/types/Client";
import type { PublicExplorerPage } from "shared/types/ExplorerPage";

export const useAnalytics = () => {
  const { SEGMENT_KEY } = useHubConfiguration();

  useEffect(() => {
    // Create a queue, but don't obliterate an existing one!

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- arteficial type for something created in a JS way
    const def = new Array<unknown>() as Analytics;
    const analytics = (window.analytics = window.analytics || def);
    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;
    // If the snippet was invoked already show an error.
    if (!analytics.invoked) {
      // Invoked flag, to make sure the snippet
      // is never invoked twice.
      analytics.invoked = true;
      // A list of the methods in Analytics.js to stub.
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ];
      // Define a factory to create stubs. These are placeholders
      // for methods in Analytics.js so that you never have to wait
      // for it to load to actually record data. The `method` is
      // stored as the first argument, so we can replay the data.
      analytics.factory = function (method) {
        return function () {
          // eslint-disable-next-line prefer-rest-params -- code from docs
          const args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          analytics.push(args);
          return analytics;
        };
      };
      // For each of our methods, generate a queueing stub.
      for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-non-null-assertion -- code from docs
        (analytics as unknown as any)[key!] = analytics.factory(key!);
      }
      // Define a method to load Analytics.js from our CDN,
      // and that will be sure to only ever load it once.
      analytics.load = function (key, options) {
        if (isLocalDev || isTest) {
          console.log("Segment is disabled in local development.");
          return;
        }
        // Create an async script element based on your key.
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
        // Insert our script next to the first script element.
        const first = document.getElementsByTagName("script")[0];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- yolo
        first!.parentNode?.insertBefore(script, first!);
        analytics._loadOptions = options;
      };
      // Add a version to keep track of what's in the wild.
      analytics.SNIPPET_VERSION = "4.13.2";
    }
    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    if (SEGMENT_KEY) {
      analytics._writeKey = SEGMENT_KEY;
      analytics.load(SEGMENT_KEY);
    }
  }, [SEGMENT_KEY]);
};

const page = (name?: string, options?: unknown) => {
  if (!window.analytics) {
    throw new Error("Analytics not defined");
  }
  return window.analytics.page(name, options);
};

type IdentifyOptions = {
  userId?: string;
  role?: string;
  email?: string;
  name?: string;
  companyName: string;
  clientId?: string;
};

const identify = (userId?: string, options?: IdentifyOptions) => {
  if (userId) {
    datadogRum.setUser({
      id: userId,
      userId,
      ...options,
    });
  } else {
    datadogRum.clearUser();
  }

  if (!window.analytics) {
    throw new Error("Analytics not defined");
  }
  return window.analytics.identify(userId, options, {
    integrations: {
      "Google Analytics": true,
      Mixpanel: true,
    },
  });
};

type SegmentEvent =
  | "Element Clicked"
  | "Onboard: Give Feedback"
  | "Onboard: Send Feedback"
  | "Element Viewed"
  | "Element Changed"
  | "Explorer: Send Feedback"
  | "Request Download All"
  | "Download All"
  | "Status Changed";

export const track = (
  event: SegmentEvent,
  properties?: unknown,
  options?: unknown,
  callback?: () => void,
) => {
  if (!window.analytics) {
    throw new Error("Analytics not defined");
  }
  return window.analytics.track(event, properties, options, callback);
};

export const useAnalyticsIdentify = () => {
  const { isLoading, authUser, isAuthenticated } = useSlobAuth();
  const activeRouteData = useGetActiveRouteData();
  const clientId = activeRouteData?.params.clientId ?? "";
  const isAuthRequiredPage = activeRouteData?.authType === "required";

  const { data: client } = useGetClientByID(clientId || "", {
    enabled: isAuthRequiredPage,
  });

  const userId = authUser?.userId || "(unknown)";
  const email = authUser?.email;
  const role = authUser?.role || "(unknown)";
  const name = authUser?.name || "(unknown)";
  const companyName = client?.name || "(unknown)";

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      identify();
    } else if (!isLoading && userId) {
      identify(userId, { userId, role, email, name, companyName, clientId });
    }
  }, [isLoading, isAuthenticated, userId, email, role, name, companyName, clientId]);
};

export const useAnalyticsPage = () => {
  const location = useLocation();

  useEffect(() => {
    let reported = false;
    let startTime: DateTime;

    const reportAnalytics = () => {
      if (reported) return;

      const timeOnPage = DateTime.now().diff(startTime, "seconds").seconds;
      // Note: In analytics.js, we automatically send the following properties: title, path, url, referrer, and search
      // https://segment.com/docs/connections/spec/page/
      page("", { timeOnPage });
      reported = true;
    };

    const setStartTime = () => {
      reported = false;
      startTime = DateTime.now();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setStartTime();
      }
      if (document.visibilityState === "hidden") {
        reportAnalytics();
      }
    };

    setStartTime();
    window.addEventListener("beforeunload", reportAnalytics);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      reportAnalytics();
      window.removeEventListener("beforeunload", reportAnalytics);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [location]);
};

export const useTrackExternalClick = () => {
  const navigate = useNavigate();
  const trackElementClicked = useTrackElementClicked();

  const tracker = useRef(trackElementClicked);
  useEffect(() => {
    tracker.current = trackElementClicked;
  }, [trackElementClicked]);

  const { href } = window.location;

  useEffect(() => {
    const url = new URL(href);
    const { newUrl, params } = extractSearchParams(url);
    if (params) {
      tracker.current(params, () => {
        navigate(newUrl.pathname + newUrl.search + newUrl.hash, { replace: true });
      });
    }
  }, [href, navigate]);
};

type StatusChanged = {
  clientName: string; // The client Name
  clientID: string; // The client model ID
  policyID: string; // The policy model ID
  phase: PhaseId | ""; // Indicates which phase this click event occurred in
  task: TaskName | ""; // Indicates the Onboard task name
  module: string; // Indicates which module the status change occurred in (this could be n / a or empty if there is only one module on the child page)
  moduleStatus: string; //The new status of the module
  fullUrl: string; // The full url path of the current page being viewed
  currentUrlPath: string; // The url path of the current page being viewed
  email: string; // email address (for slack notifications)
};
const statusChanged = (options: StatusChanged, callback?: () => void) => {
  return track("Status Changed", options, undefined, callback);
};
export type StatusChangedOptions = {
  module: string;
  moduleStatus: string;
  clientName?: string;
  clientID?: string;
};
export type TrackStatusChangedFunc = (options: StatusChangedOptions, callback?: () => void) => void;
export const useTrackStatusChanged = (
  client?: Client,
  task?: TaskName,
  policyId?: string,
): TrackStatusChangedFunc => {
  const { authUser } = useSlobAuth();
  const email = authUser?.email;

  const primaryPolicy = client?.policies.find((p) => p.primaryPolicy);
  const policy = policyId ? client?.policies.find((p) => p.id === policyId) : undefined;

  const phaseId = policy ? policy.phaseId : primaryPolicy?.phaseId;

  return useCallback(
    (
      { module, moduleStatus, clientName, clientID }: StatusChangedOptions,
      callback?: () => void,
    ) => {
      return statusChanged(
        {
          fullUrl: window.location.href,
          currentUrlPath: window.location.pathname,
          clientID: clientID || client?.id || "",
          clientName: clientName || client?.name || "",
          task: task || "",
          policyID: policyId || "",
          phase: phaseId || "",
          module,
          moduleStatus,
          email: email || "",
        },
        callback,
      );
    },
    [client, task, policyId, email, phaseId],
  );
};

type ElementClicked = {
  clientName: string; // The client Name
  clientID: string; // The client model ID
  policyID: string; // The policy model ID
  phase: PhaseId | ""; // Indicates which phase this click event occurred in
  task: TaskName | ""; // Indicates the Onboard task name
  module: string; // Indicates which module the click event occurred in (this could be n / a or empty if there is only one module on the child page)
  fullUrl: string; // The full url path of the current page being viewed
  currentUrlPath: string; // The url path of the current page being viewed
  buttonLabel: string; // Indicates which module the click event occurred in (this could be empty if there is only one module on the child page)
  moduleState: string; // Indicates the status of the module(not started, completed, etc)
  featureToggleState?: Record<string, boolean>;
  email: string; // email address (for slack notifications)
  explorerPageId: string | "";
  explorerPlanId: string | "";
  daysLeftInOE: number | null; // Indicated the number of days left in the current OE
  daysUntilOE: number | null; // Indicated the number of days until the OE period starts
  personalBenefitsPlan: string[] | null; // The list of plan ID that are currently selected for the personal benefits plan (BenEx only)
  ebrEmailAddress: string;
};
const elementClicked = (options: ElementClicked, callback?: () => void) => {
  return track("Element Clicked", options, undefined, callback);
};

export type ElementClickedOptions = {
  buttonLabel?: string;
  module?: string;
  moduleState?: string;
  explorerPlanId?: string;
  daysLeftInOE?: number;
  daysUntilOE?: number;
  personalBenefitsPlan?: string[];
  clientName?: string;
  clientID?: string;
  ebrEmailAddress?: string;
};

export type TrackElementClickedFunc = (
  options: ElementClickedOptions,
  callback?: () => void,
) => void;

export const useTrackElementClicked = (
  client?: Client,
  task?: TaskName,
  policyId?: string,
): TrackElementClickedFunc => {
  const { authUser } = useSlobAuth();
  const email = authUser?.email;

  const primaryPolicy = client?.policies.find((p) => p.primaryPolicy);
  const policy = policyId ? client?.policies.find((p) => p.id === policyId) : undefined;

  const phaseId = policy ? policy.phaseId : primaryPolicy?.phaseId;

  return useCallback(
    (
      {
        buttonLabel,
        module,
        moduleState,
        personalBenefitsPlan,
        explorerPlanId,
        daysLeftInOE,
        daysUntilOE,
        clientName,
        clientID,
        ebrEmailAddress,
      }: ElementClickedOptions,
      callback?: () => void,
    ) => {
      return elementClicked(
        {
          fullUrl: window.location.href,
          currentUrlPath: window.location.pathname,
          clientID: clientID || client?.id || "",
          policyID: policyId || "",
          clientName: clientName || client?.name || "",
          task: task || "",
          phase: phaseId || "",
          module: module || task || "",
          moduleState: moduleState || "",
          buttonLabel: buttonLabel || "",
          email: email || "",
          explorerPageId: "",
          explorerPlanId: explorerPlanId || "",
          personalBenefitsPlan: personalBenefitsPlan ?? null,
          daysLeftInOE: daysLeftInOE ?? null,
          daysUntilOE: daysUntilOE ?? null,
          ebrEmailAddress: ebrEmailAddress || "",
        },
        callback,
      );
    },
    [client, task, policyId, email, phaseId],
  );
};

export const useExplorerTrackElementClicked = (explorerPage?: PublicExplorerPage) => {
  const { authUser } = useSlobAuth();
  const email = authUser?.email;

  return useCallback(
    ({
      buttonLabel,
      module,
      moduleState,
      explorerPlanId,
      personalBenefitsPlan,
      daysLeftInOE,
      daysUntilOE,
      ebrEmailAddress,
    }: ElementClickedOptions) => {
      return elementClicked({
        fullUrl: window.location.href,
        currentUrlPath: window.location.pathname,
        clientID: explorerPage?.clientId || "",
        policyID: "",
        clientName: explorerPage?.clientName || "",
        task: "",
        phase: "",
        module: module || "",
        moduleState: moduleState || "",
        buttonLabel: buttonLabel || "",
        email: email || "",
        explorerPageId: explorerPage?.id || "",
        explorerPlanId: explorerPlanId || "",
        daysLeftInOE: daysLeftInOE ?? null,
        personalBenefitsPlan: personalBenefitsPlan ?? null,
        daysUntilOE: daysUntilOE ?? null,
        ebrEmailAddress: ebrEmailAddress || "",
      });
    },
    [explorerPage, email],
  );
};

type ElementViewed = {
  clientName: string; // The client Name
  clientID: string; // The client ID
  phase: PhaseId | ""; // Indicates which phase this view event occurred in
  task: TaskName | ""; // Indicates the Onboard task name
  module: string; // Indicates which module the view event occurred in (this could be n / a or empty if there is only one module on the child page)
  fullUrl: string; // The full url path of the current page being viewed
  currentUrlPath: string; // The url path of the current page being viewed
  moduleState: string; // Indicates the status of the module(not started, completed, etc)
  featureToggleState?: Record<string, boolean>;
  email: string; // email address (for slack notifications)
  explorerPageId: string | ""; //explorer page ID
  explorerPlanId: string | ""; //explorer plan ID
  benefitIndex: number | null; //refers the index of the explorer page benefit being viewed, null if not applicable
};
const elementViewed = (options: ElementViewed) => track("Element Viewed", options);

export type ElementViewedOptions = {
  clientId: ClientId;
  clientName: string | undefined;
  module: string;
  moduleState: string;
  explorerPage?: PublicExplorerPage;
  explorerPlanId?: string;
  benefitIndex?: number;
};

const useInView = (margin = 0) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const callback = () => {
      const position = ref.current?.getBoundingClientRect();
      if (position === undefined) return;

      const verticalInView =
        position.top <= window.innerHeight - margin && position.bottom >= margin;
      const horizontalInView =
        position.left <= window.innerWidth - margin && position.right >= margin;

      setInView(verticalInView && horizontalInView);
    };
    callback();
    window.addEventListener("scroll", callback, { passive: true });
    return () => window.removeEventListener("scroll", callback);
  }, [ref, margin]);

  return { ref, inView };
};

export const useTrackElementViewed = (
  {
    explorerPage,
    clientId,
    clientName,
    module,
    moduleState,
    explorerPlanId,
    benefitIndex,
  }: ElementViewedOptions,
  rootMargin?: number,
) => {
  const { authUser } = useSlobAuth();
  const email = authUser?.email;

  // ref to make sure we only send this event once per page render
  const hasBeenInView = useRef(false);

  const { ref, inView } = useInView(rootMargin);

  useEffect(() => {
    if (!hasBeenInView.current && inView) {
      elementViewed({
        fullUrl: window.location.href,
        currentUrlPath: window.location.pathname,
        clientID: clientId,
        clientName: clientName || "",
        task: "",
        phase: "",
        module,
        moduleState,
        email: email || "",
        explorerPageId: explorerPage?.id || "",
        explorerPlanId: explorerPlanId || "",
        benefitIndex: benefitIndex ?? null,
      });

      hasBeenInView.current = true;
    }
  }, [
    benefitIndex,
    clientId,
    clientName,
    email,
    explorerPage?.id,
    explorerPlanId,
    inView,
    module,
    moduleState,
  ]);

  return ref;
};

type ElementChanged = {
  clientName: string; // The client Name
  clientID: string; // The client ID
  phase: PhaseId | ""; // Indicates which phase this view event occurred in
  task: TaskName | ""; // Indicates the Onboard task name
  module: string; // Indicates which module the view event occurred in (this could be n / a or empty if there is only one module on the child page)
  fullUrl: string; // The full url path of the current page being viewed
  currentUrlPath: string; // The url path of the current page being viewed
  moduleState: string; // Indicates the status of the module(not started, completed, etc)
  value: string | ""; // value of changed element
  featureToggleState?: Record<string, boolean>;
  email: string; // email address (for slack notifications)
  explorerPageId: string | ""; //explorer page ID
  explorerPlanId: string | ""; //explorer plan ID
};
const elementChanged = (options: ElementChanged) => track("Element Changed", options);

export type ElementChangedOptions = {
  module?: string;
  moduleState?: string;
  value?: string;
  explorerPage?: PublicExplorerPage;
  explorerPlanId?: string;
};

export const useExplorerTrackElementChanged = (explorerPage?: PublicExplorerPage) => {
  const { authUser } = useSlobAuth();
  const email = authUser?.email;

  return useCallback(
    ({ value, module, moduleState, explorerPlanId }: ElementChangedOptions) => {
      return elementChanged({
        fullUrl: window.location.href,
        currentUrlPath: window.location.pathname,
        clientID: explorerPage?.clientId || "",
        clientName: explorerPage?.clientName || "",
        task: "",
        phase: "",
        module: module || "",
        moduleState: moduleState || "",
        value: value || "",
        email: email || "",
        explorerPageId: explorerPage?.id || "",
        explorerPlanId: explorerPlanId || "",
      });
    },
    [explorerPage, email],
  );
};

export const useTrackZendeskClicked = () => {
  const trackElementClicked = useTrackElementClicked();
  const tracker = useRef(trackElementClicked);
  useEffect(() => {
    tracker.current = trackElementClicked;
  }, [trackElementClicked]);
  useEffect(() => {
    window.zE?.("webWidget:on", "open", () => {
      tracker.current({
        buttonLabel: "Support widget open",
      });
    });
  }, []);
};

const getClickable = (el: HTMLElement | null): HTMLElement | null => {
  do {
    if (!el || el.tagName === "BODY") {
      return null;
    }
    if (["A", "BUTTON"].includes(el.tagName)) {
      return el;
    }
    if (el instanceof HTMLInputElement) {
      const inputType = String(el.type).toLowerCase();
      return ["submit", "button", "reset"].includes(inputType) ? el : null;
    }
  } while ((el = el.parentElement));

  return null;
};

const getLabel = (el: HTMLElement): string | null => {
  let label = String(el.textContent).trim();
  if (!label && el instanceof HTMLInputElement) {
    label = el.value.trim();
  }
  return label || null;
};

const clickHandler: EventListener = (e) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we want to listen to HTMLElements only
  const clickTarget = e.target as HTMLElement;
  const el = getClickable(clickTarget);
  if (el) {
    elementClicked({
      fullUrl: window.location.href,
      currentUrlPath: window.location.pathname,
      clientID: "",
      policyID: "",
      clientName: "",
      task: "",
      phase: "",
      module: "",
      moduleState: "",
      buttonLabel: getLabel(el) || "",
      email: "",
      explorerPageId: "",
      explorerPlanId: "",
      daysLeftInOE: null,
      personalBenefitsPlan: null,
      daysUntilOE: null,
      ebrEmailAddress: "",
    });
  }
};

export const useTrackAllClicks = () =>
  useCallback((element: HTMLElement | null) => {
    if (element) {
      element.addEventListener("click", clickHandler);
    }
  }, []);

export const useSendNativeFeedbackForm = () => {
  const { authUser } = useSlobAuth();
  const activeRouteData = useGetActiveRouteData();
  const clientId = activeRouteData?.params.clientId ?? "";
  const { data: client } = useGetClientByID(clientId || "");

  const userId = authUser?.userId || "(unknown)";
  const email = authUser?.email;
  const role = authUser?.role || "(unknown)";
  const name = authUser?.name || "(unknown)";
  const companyName = client?.name || "(unknown)";
  const ticketId = client?.ticketId || "(unknown)";

  return useCallback(
    (feedback: string, contact: "YES" | "NO") => {
      return track("Onboard: Send Feedback", {
        name,
        role,
        userId,
        email,
        companyName,
        ticketId,
        feedback,
        contact,
      });
    },
    [userId, email, role, name, companyName, ticketId],
  );
};

export const useSendExplorerFeedbackForm = (explorerPageId?: string) => {
  const { authUser } = useSlobAuth();
  const activeRouteData = useGetActiveRouteData();
  const clientId = activeRouteData?.params.clientId ?? "";
  const { data: client } = useGetClientByID(clientId || "");

  const userId = authUser?.userId || "(unknown)";
  const email = authUser?.email;
  const role = authUser?.role || "(unknown)";
  const name = authUser?.name || "(unknown)";
  const companyName = client?.name || "(unknown)";
  const ticketId = client?.ticketId || "(unknown)";

  return useCallback(
    (feedback: string, rating?: number) => {
      return track("Explorer: Send Feedback", {
        name,
        role,
        userId,
        email,
        companyName,
        ticketId,
        feedback,
        rating,
        explorerPageId,
      });
    },
    [userId, email, role, name, companyName, ticketId, explorerPageId],
  );
};
