import { useLocation } from "react-router-dom";
import { useScrollToTopOnLocationChange } from "../../hooks/useScrollToTopOnLocationChange";
import {
  useAnalytics,
  useAnalyticsPage,
  useTrackExternalClick,
  useAnalyticsIdentify,
} from "../../utils/analytics";
import { useSetHelpCenterSuggestions, useZendeskWidget } from "../../utils/zendesk";

export const AnalyticsPage = () => {
  const location = useLocation();
  useSetHelpCenterSuggestions(location);
  useScrollToTopOnLocationChange();
  useZendeskWidget();

  useAnalytics();
  useAnalyticsPage();
  useTrackExternalClick();
  useAnalyticsIdentify();

  return null;
};
