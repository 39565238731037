import en_US from "antd/lib/locale-provider/en_US";
import es_ES from "antd/lib/locale-provider/es_ES";

import type { english } from "./en";
import type { spanish } from "./es";

import type { Locale as AntLocale } from "antd/lib/locale-provider";
import type { ValueOf } from "shared/types/Helper";

// union of all supported locales
export type Locale = ValueOf<typeof LocaleKeys>;

export const LocaleKeys = ["en", "es"] as const;

export type BaseLocale = typeof english & typeof spanish;

export const antdLocales: { [k in Locale]: AntLocale } = {
  en: en_US,
  es: es_ES,
};

export const defaultLocale: Locale = LocaleKeys[0];
