import type { ComponentType } from "react";

/**
 * Use this as a shortcut to bundle up several components instead
 * of just one with `React.lazy`. To use it, create a file that exports an object
 * with multiple components, then dynamically import that into the argument of this function.
 *
 * Example usage:
 *
 * ```tsx
 * // BundleFile.ts
 *
 * import { ComponentA } from './ComponentA'
 * import { ComponentB } from './ComponentB'
 *
 * export { ComponentA, ComponentB }
 * ```
 *
 * Then...
 *
 * ```tsx
 * // Router
 *
 * const bundle = lazyBundler(() => import('./BundleFile.ts'))
 * const ComponentA = React.lazy(bundle(bundle => bundle.ComponentA))
 * const ComponentB = React.lazy(bundle(bundle => bundle.ComponentB))
 * ```
 *
 * @param factory
 * @returns
 */
export function lazyBundler<T>(factory: () => Promise<T>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- required for proper inference on consumer side?
  return <C extends ComponentType<any>>(getComponent: (bundle: T) => C) => {
    return async () => {
      const bundle = await factory();
      const component = getComponent(bundle);
      return { default: component };
    };
  };
}
