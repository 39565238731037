import clsx from "clsx";

import { Card } from "../Cards/Card";

import * as styles from "./hubCard.module.less";

export type HubCardProps = {
  variant?: "activation";
  header?: React.ReactNode;
  children?: React.ReactNode;
  padding?: "small" | "medium" | "none";
  grow?: boolean;
  printMode?: boolean;
  disabled?: boolean;
};

export function HubCard(props: HubCardProps): JSX.Element {
  const {
    variant,
    header,
    children,
    padding = "small",
    disabled = false,
    printMode = false,
    grow = false,
    ...rest
  } = props;

  return (
    <Card
      variant={variant}
      padding="none"
      disabled={disabled}
      printMode={printMode}
      grow={grow}
      {...rest}
    >
      {header}
      <div
        aria-disabled={disabled}
        className={clsx(
          styles.hubCardBody,
          grow && styles.hubCardBodyGrow,
          printMode && styles.hubCardBodyPrint,
          padding === "small" && "p-32",
          padding === "medium" && "p-48",
          disabled && styles.disabled,
        )}
      >
        {children}
      </div>
    </Card>
  );
}
