import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor } from "../../components/Anchor/Anchor";
import type { ClientId } from "shared/types/Client";

export function getDownloadEIFSummaryPDFHref(clientId: ClientId) {
  // this call could return undefined if someone is using IE11 or an out of date version of their browser
  // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions_computed_timezone
  // https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- needed to account for old browsers
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "America/New_York";
  // timeZone will be an IANA timezone that could have a "/" in it, which we cannot put in the query string
  const urlEncodedTimeZone = encodeURIComponent(timeZone);
  return `/api/clients/${clientId}/eif-summary?tz=${urlEncodedTimeZone}`;
}

export function DownloadEIFSummaryPDFLink({ clientId }: { clientId: ClientId }) {
  const href = getDownloadEIFSummaryPDFHref(clientId);

  return (
    <Anchor variant="boldSmall" href={href} download>
      <FontAwesomeIcon icon={faFileDownload} className="mr-4" />
      Download PDF
    </Anchor>
  );
}
