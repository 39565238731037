import type { Client } from "shared/types/Client";

export function getImplementationContactInfo(client: Client | undefined) {
  const implementationConsultantFullName = client?.slfImplementationConsultant
    ? [
        client.slfImplementationConsultant.firstName,
        client.slfImplementationConsultant.lastName,
      ].join(" ")
    : "";

  const implementationConsultantPhone = client?.slfImplementationConsultant?.phoneNumber ?? "";
  const implementationConsultantEmail = client?.slfImplementationConsultant?.email ?? "";

  return {
    implementationConsultantFullName,
    implementationConsultantPhone,
    implementationConsultantEmail,
  };
}
