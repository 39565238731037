import clsx from "clsx";
import React from "react";

import { UpdatedByPopover } from "../Badge/UpdatedByPopover";
import StaticSpinner from "../Icons/StaticSpinner.svg?react";

import * as styles from "./badge.module.less";
import type { ClientSetupStatus } from "shared/data/Client";

import type { TaskStatus } from "shared/data/Tasks";
import type { User } from "shared/types/User";

export type Variant = "info" | "error" | "success" | "warning";

const variantStyleBorder: Record<Variant, string> = {
  info: styles.infoBorder,
  error: styles.errorBorder,
  success: styles.successBorder,
  warning: styles.warningBorder,
};

const variantStyle: Record<Variant, string> = {
  info: styles.info,
  error: styles.error,
  success: styles.success,
  warning: styles.warning,
};

type BadgeProps = React.HTMLAttributes<HTMLSpanElement> & {
  srOnlyLabel: string;
  variant?: Variant;
  status?: string;
  message?: React.ReactNode;
  children?: React.ReactNode;
  showPopover?: boolean;
  popoverUpdatedBy?: User | null;
  popoverUpdatedAt?: Date | null;
  popoverLoading?: boolean;
  popoverError?: unknown;
  showStaticSpinner?: boolean;
};

export function Badge({
  srOnlyLabel,
  status,
  message,
  variant = "info",
  children,
  showPopover = false,
  popoverError,
  popoverLoading,
  popoverUpdatedBy,
  popoverUpdatedAt,
  showStaticSpinner = false,
  ...rest
}: BadgeProps) {
  return (
    <span
      {...rest}
      className={clsx(
        styles.badge,
        variantStyleBorder[variant],
        variantStyle[variant],
        rest.className,
      )}
    >
      <span className="sr-only">{srOnlyLabel}</span>

      {status &&
        (showPopover ? (
          <UpdatedByPopover
            updatedByUser={popoverUpdatedBy}
            updatedAt={popoverUpdatedAt}
            loading={popoverLoading}
            error={popoverError}
          >
            <span className={clsx(styles.status, showStaticSpinner && styles.statusWithSpinner)}>
              {showStaticSpinner && <StaticSpinner />}
              {status}
            </span>
          </UpdatedByPopover>
        ) : (
          <span className={clsx(styles.status, showStaticSpinner && styles.statusWithSpinner)}>
            {showStaticSpinner && <StaticSpinner />}
            {status}
          </span>
        ))}
      {message && (
        <span className={clsx(styles.message, variantStyleBorder[variant])}>{message}</span>
      )}
      {children}
    </span>
  );
}

export const taskStatusToVariant: Record<NonNullable<TaskStatus | ClientSetupStatus>, Variant> = {
  Upcoming: "info",
  "Not Started": "info",
  "Needs Review": "warning",
  "In Progress": "info",
  "In Review": "warning",
  Voided: "error",
  Declined: "error",
  NIGO: "error",
  Done: "success",
};

export const taskStatusToLabel: Record<NonNullable<TaskStatus | ClientSetupStatus>, string> = {
  Upcoming: "Not Started",
  "Not Started": "Not Started",
  "Needs Review": "Needs Review",
  "In Progress": "In Progress",
  "In Review": "In Review",
  Voided: "Voided",
  Declined: "Declined",
  NIGO: "Action Required",
  Done: "Completed",
};
