import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { jsonLocationToLocation } from "./location";
import { compareQueryKey, useSlobMutation, useSlobQuery } from "./query";
import type { JsonToTypeMapper, ResponseError } from "./query";
import type { ClientId } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Pretty, UndefinedToOptional } from "shared/types/Helper";
import type { contactInputValidationSchema } from "shared/validation/contact";
import type { InferType } from "yup";

export const jsonContactToContact: JsonToTypeMapper<Contact> = (contact) => ({
  ...contact,
  createdAt: contact.createdAt ? new Date(contact.createdAt) : new Date(),
  updatedAt: contact.updatedAt ? new Date(contact.updatedAt) : new Date(),
  deletedAt: contact.deletedAt ? new Date(contact.deletedAt) : null,
  location: contact.location && jsonLocationToLocation(contact.location),
  managedLocations: contact.managedLocations?.map(jsonLocationToLocation),
});

export const useGetContacts = (clientId: ClientId) =>
  useSlobQuery<Contact[]>({
    method: "get",
    path: `/api/clients/${clientId}/contacts`,
    map: (contacts) => contacts.map(jsonContactToContact),
  });

export type CreateContactInput = UndefinedToOptional<
  Pretty<InferType<typeof contactInputValidationSchema>>
>;

export type CreateContactQuery = Pick<
  ReturnType<typeof useCreateContact>,
  "isPending" | "mutateAsync" | "error" | "isError"
>;

export const useCreateContact = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    CreateContactInput,
    Contact,
    "/api/clients/:clientId/contacts",
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/contacts`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
};

export const useUpdateContact = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    UndefinedToOptional<Pretty<InferType<typeof contactInputValidationSchema>>>,
    Contact,
    "/api/clients/:clientId/contacts/:contactId",
    ResponseError,
    unknown
  >({
    method: "put",
    path: `/api/clients/:clientId/contacts/:contactId`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
};

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "delete",
    path: `api/clients/:clientId/contacts/:contactId`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
};
