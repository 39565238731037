import { OktaAuth } from "@okta/okta-auth-js";
import { writeStorage } from "@rehooks/local-storage";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { genericErrorCopy } from "../components/Error/ErrorMessage";
import { useMarkNewSeenDocumentNoticesAsOld } from "../hooks/document";
import { useSlobQuery, useSlobMutation, compareQueryKey } from "../hooks/query";

import { useHubConfiguration } from "../hooks/useConfig";
import { usePostLoginActions } from "../hooks/user";

import type {
  PathBodyToVariables,
  ResponseToMutationReturnType,
  ResponseError,
} from "../hooks/query";
import type { AuthnTransaction, AuthnTransactionFunction } from "@okta/okta-auth-js";

import type { UseMutateAsyncFunction } from "@tanstack/react-query";
import type { UserData } from "shared/rbac/rbac";

export type AuthState = {
  authUser: UserData | null;
};

export const useGetAuthUserStatus = () =>
  useSlobQuery<
    | { authUser: UserData; isAuthenticated: true }
    | { authUser: null; isAuthenticated: false }
    | null
  >({
    method: "get",
    path: "/api/users/authenticated",
    map: (data) => {
      if (!data?.authUser) {
        return {
          isAuthenticated: false,
          authUser: null,
        };
      }

      const authUser: UserData = {
        ...data.authUser,
        termsOfUseDate: data.authUser.termsOfUseDate
          ? new Date(data.authUser.termsOfUseDate)
          : null,
      };

      return {
        isAuthenticated: true,
        authUser,
      };
    },
    options: {
      staleTime: 0,
      gcTime: 0,
      refetchOnWindowFocus: "always",
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  });

export class OktaError extends Error {
  status: number;
  errorSummary: string | undefined;
  error_description?: string;

  static isOktaError(err: unknown): err is OktaError {
    return (
      err instanceof OktaError ||
      !!(typeof err === "object" && err && "errorSummary" in err) ||
      !!(typeof err === "object" && err && "error_description" in err)
    );
  }
  constructor(status: number, errorSummary: string | undefined, error_description?: string) {
    super();
    this.status = status;
    this.errorSummary = errorSummary;
    this.error_description = error_description;
    this.name = "OktaError";
    this.message = errorSummary ?? error_description ?? "Unidentified Okta error";
    Object.setPrototypeOf(this, OktaError.prototype);
  }
}

export function oktaErrorToMessage(error: unknown): string {
  if (OktaError.isOktaError(error)) {
    console.log(error.errorSummary);
    if (typeof error.errorSummary === "string" && error.errorSummary.length > 0) {
      return error.errorSummary;
    }
    if (typeof error.error_description === "string" && error.error_description.length > 0) {
      return error.error_description;
    }
    if (error.message) {
      return error.message;
    }
  }
  if (typeof error === "string") {
    return error;
  }
  return genericErrorCopy;
}

type EnrollOptions = { profile?: { phoneNumber: string; updatePhone: boolean } };

export interface Factor {
  enroll: ((option?: EnrollOptions) => Promise<AuthnTransaction>) | undefined;
  verify: AuthnTransactionFunction | undefined;
  enrollment: string;
  factorType: "call" | "sms" | "token:software:totp";
  provider: string;
  profile?: {
    phoneNumber: string;
  };
  status: "NOT_SETUP" | "PENDING_ACTIVATION" | "ACTIVE" | "INACTIVE";
  vendorName: string;
  activation?: {
    qrcode: {
      href: string;
      type: string;
    };
    factorResult: string; // not used but "WAITING" is an known result. Used for pooling
    sharedSecret: string;
  };
}

export type OktaTransactionResponse = {
  status?: LoginWindowOktaStatuses;
  mfaFactorsToEnroll?: Factor[];
  mfaOptions?: {
    factor?: Factor;
    rememberDeviceLifetimeInMinutes?: number;
  };
  resend?: AuthnTransactionFunction;
  transaction?: AuthnTransaction;
  error?: string;
};

export type ChallengeResponse = {
  status?: LoginWindowOktaStatuses;
  mfaOptions?: {
    factor?: Factor;
    rememberDeviceLifetimeInMinutes?: number;
  };
  resend?: AuthnTransactionFunction;
  transaction?: AuthnTransaction;
  error?: string;
};

type EnrollReponse = {
  status?: LoginWindowOktaStatuses;
  error?: string;
  transaction?: AuthnTransaction;
  mfaOptions?: {
    factor?: Factor;
    rememberDeviceLifetimeInMinutes?: number;
  };
};

type WithErrorResponse = {
  status?: LoginWindowOktaStatuses;
  error?: string;
};

export const LOCAL_STORAGE_REMEMBER_USER_KEY = "signin-remember-me-user";

type LoginWindowOktaStatuses =
  | "SUCCESS"
  | "MFA_REQUIRED"
  | "MFA_CHALLENGE"
  | "MFA_ENROLL"
  | "MFA_ENROLL_ACTIVATE";

export const useGetOktaAuth = () => {
  const config = useHubConfiguration();

  const { mutateAsync: updatePostLoginAction } = usePostLoginActions();
  const { markNewDocumentNoticesAsOld } = useMarkNewSeenDocumentNoticesAsOld();

  const [isLoading, setIsLoading] = useState(false);
  const [shouldRememberUser, setShouldRememberUser] = useState(false);
  const [signinUsername, setSigninUsername] = useState("");

  const oktaAuth = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions -- @todo
      ((window as any).oktaAuth = new OktaAuth({
        clientId: config.REACT_APP_OKTA_CLIENT_ID,
        issuer: `${config.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
        scopes: config.REACT_APP_OKTA_SCOPES,
        pkce: true,
        redirectUri: `${window.location.origin}/login/callback`,
      })),
    [config],
  );

  const onSignIn = useCallback(
    async (sessionToken: string) => {
      // This creates an iframe to Okta authorizeUrl, returning tokens in it's callback. This reduces the need of one hard redirect in current page
      // preventing redirect querystring to be lost in the process
      // The no iframe option would be oktaAuth.token.getWithRedirect()
      const { tokens } = await oktaAuth.token.getWithoutPrompt({ sessionToken });

      try {
        await updatePostLoginAction({
          data: {
            accessToken: tokens.accessToken?.accessToken ?? "",
            refreshToken: tokens.refreshToken?.refreshToken ?? "",
          },
        });
        await markNewDocumentNoticesAsOld();

        writeStorage(LOCAL_STORAGE_REMEMBER_USER_KEY, shouldRememberUser ? signinUsername : "");
      } catch (error) {
        return { error: oktaErrorToMessage(error) };
      }
    },
    [
      markNewDocumentNoticesAsOld,
      oktaAuth.token,
      updatePostLoginAction,
      shouldRememberUser,
      signinUsername,
    ],
  );

  const getGoogleFactor = (factors: Factor[]) =>
    factors.find((f) => f.factorType === "token:software:totp" || f.provider === "GOOGLE");

  const getValidFactors = (factors: Factor[]) => factors.filter((f) => f.status !== "NOT_SETUP");
  const getSetupFactors = (factors: Factor[]) =>
    factors.filter((f) => f.status === "NOT_SETUP" || f.status === "PENDING_ACTIVATION");

  const handleOktaTransaction = useCallback(
    async (transaction: AuthnTransaction): Promise<OktaTransactionResponse> => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
      const factors = (transaction.factors as Factor[] | undefined) || [];

      switch (transaction.status) {
        case "SUCCESS": {
          if (!transaction.sessionToken) return { error: "Missing sessionToken from response" };
          const res = await onSignIn(transaction.sessionToken);
          return { status: transaction.status, error: res?.error };
        }
        // For Google Authenticator, this is the only step. The user will get the code himself in the app
        case "MFA_REQUIRED": {
          const validFactors = getValidFactors(factors);
          if (!validFactors.length) return { error: "There's no MFA factor option configured" };
          if (validFactors.length > 1)
            return { error: "There's more than one MFA factor option configured" };
          const factor = validFactors[0];
          const mfaOptions = {
            factor,
            rememberDeviceLifetimeInMinutes: transaction.policy?.allowRememberDevice
              ? transaction.policy.rememberDeviceLifetimeInMinutes
              : undefined,
          };
          return { status: transaction.status, mfaOptions };
        }
        // Extra step for Phone code after code is requested
        case "MFA_CHALLENGE": {
          if (!transaction.factor) return { error: "There's no MFA factor option configured" };
          const mfaOptions = {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
            factor: transaction.factor as Factor,
            rememberDeviceLifetimeInMinutes: transaction.policy?.allowRememberDevice
              ? transaction.policy.rememberDeviceLifetimeInMinutes
              : undefined,
          };
          return {
            status: transaction.status,
            mfaOptions,
            transaction,
          };
        }
        // User doesn't have MFA configured yet
        case "MFA_ENROLL": {
          const setupFactors = getSetupFactors(factors);
          if (!setupFactors.length) return { error: "There's no MFA factor option configured" };
          const googleFactor = getGoogleFactor(setupFactors);
          // we automatically enroll to Google Authenticator if only Google factor exists
          if (setupFactors.length === 1 && googleFactor && googleFactor.enroll) {
            const transaction = await googleFactor.enroll();
            return await handleOktaTransaction(transaction);
          }
          return {
            status: transaction.status,
            mfaFactorsToEnroll: setupFactors,
          };
        }
        // After successfully enrolling, we receive QR code for first setup/activation (with code challenge)
        case "MFA_ENROLL_ACTIVATE": {
          return {
            status: transaction.status,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
            mfaOptions: { factor: transaction.factor as Factor },
            transaction,
          };
        }
        case "LOCKED_OUT":
          return {
            error:
              "Your account is locked for security reasons. Please, try again in about 60 minutes",
          };
        case "PASSWORD_EXPIRED":
          return {
            error: "Your password has expired.",
          };
        default: {
          console.error(`Unknow unhandled process status ${transaction.status}`);
          // https://github.com/okta/okta-auth-js/blob/HEAD/docs/authn.md#transactionstatus
          return { error: `Unknow unhandled process status ${transaction.status}` };
        }
      }
    },
    [onSignIn],
  );

  /*
    Possible options
    options: {warnBeforePasswordExpired: true, multiOptionalFactorEnroll: false}
  */
  const signIn = useCallback(
    async ({
      username,
      password,
      rememberMe,
    }: {
      username: string;
      password: string;
      rememberMe: boolean;
    }): Promise<OktaTransactionResponse> => {
      setShouldRememberUser(rememberMe);
      setSigninUsername(username);
      setIsLoading(true);
      try {
        const transaction = await oktaAuth.signInWithCredentials({ username, password });
        return await handleOktaTransaction(transaction);
      } catch (error) {
        return { error: oktaErrorToMessage(error) };
      } finally {
        setIsLoading(false);
      }
    },
    [oktaAuth, handleOktaTransaction],
  );

  const verifyGoogleMFA = async (
    passcode: string,
    factor: Factor,
    rememberDevice: boolean,
  ): Promise<WithErrorResponse> => {
    setIsLoading(true);
    try {
      if (!factor.verify) {
        return { error: `Inconsistent Factor config (verify)` };
      }
      const factorTransaction = await factor.verify({ passCode: passcode, rememberDevice });
      return await handleOktaTransaction(factorTransaction);
    } catch (error) {
      return { error: oktaErrorToMessage(error) };
    } finally {
      setIsLoading(false);
    }
  };

  const verifyPhoneMFA = async (
    passcode: string,
    transaction: AuthnTransaction,
    rememberDevice: boolean,
  ): Promise<WithErrorResponse> => {
    setIsLoading(true);
    try {
      if (!transaction.verify) {
        return { error: `Inconsistent Factor config (verify)` };
      }
      const factorTransaction = await transaction.verify({ passCode: passcode, rememberDevice });
      return await handleOktaTransaction(factorTransaction);
    } catch (error) {
      return { error: oktaErrorToMessage(error) };
    } finally {
      setIsLoading(false);
    }
  };

  const enrollMFAGoogle = async (factor: Factor): Promise<EnrollReponse> => {
    setIsLoading(true);
    try {
      if (!factor.enroll) {
        return { error: `Inconsistent transaction config (factor enroll)` };
      }
      const factorTransaction = await factor.enroll();
      return await handleOktaTransaction(factorTransaction);
    } catch (error) {
      return { error: oktaErrorToMessage(error) };
    } finally {
      setIsLoading(false);
    }
  };

  const enrollMFAPhoneBased = async (
    factor: Factor,
    phoneNumber: string,
  ): Promise<EnrollReponse> => {
    setIsLoading(true);
    try {
      if (!factor.enroll) {
        return { error: `Inconsistent transaction config (factor enroll)` };
      }
      const factorTransaction = await factor.enroll({
        profile: {
          phoneNumber,
          updatePhone: true,
        },
      });
      return await handleOktaTransaction(factorTransaction);
    } catch (error) {
      return { error: oktaErrorToMessage(error) };
    } finally {
      setIsLoading(false);
    }
  };

  const requestPhoneCode = async (factor: Factor): Promise<ChallengeResponse> => {
    setIsLoading(true);
    try {
      if (!factor.verify) {
        return { error: `Inconsistent Factor config (verify)` };
      }
      const factorTransaction = await factor.verify(); // empty verify requests phone code
      return await handleOktaTransaction(factorTransaction);
    } catch (error) {
      return { error: oktaErrorToMessage(error) };
    } finally {
      setIsLoading(false);
    }
  };

  const resendPhoneCode = async (
    transaction: AuthnTransaction,
    factorType: Factor["factorType"],
  ): Promise<ChallengeResponse> => {
    setIsLoading(true);
    try {
      if (!transaction.resend) {
        return { error: `Inconsistent Transaction config (resend)` };
      }
      const factorTransaction = await transaction.resend(factorType);
      return await handleOktaTransaction(factorTransaction);
    } catch (error) {
      return { error: oktaErrorToMessage(error) };
    } finally {
      setIsLoading(false);
    }
  };

  const setupMFA = async (
    passcode: string,
    transaction: AuthnTransaction,
  ): Promise<WithErrorResponse> => {
    setIsLoading(true);
    try {
      if (!transaction.activate) {
        return { error: `Inconsistent transaction config (activate)` };
      }
      const transactionActivationResponse = await transaction.activate({ passCode: passcode });
      return await handleOktaTransaction(transactionActivationResponse);
    } catch (error) {
      return { error: oktaErrorToMessage(error) };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    oktaAuth,
    oktaStep: {
      signIn,
      verifyGoogleMFA,
      verifyPhoneMFA,
      enrollMFAGoogle,
      enrollMFAPhoneBased,
      requestPhoneCode,
      resendPhoneCode,
      setupMFA,
    },
    isLoading,
  };
};

export type LogoutPostType = UseMutateAsyncFunction<
  ResponseToMutationReturnType<{
    success: boolean;
  }>,
  ResponseError,
  PathBodyToVariables<"/api/users/authenticated/logout", unknown, never>,
  unknown
>;
export const useLogout = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  return useSlobMutation<unknown, { success: boolean }, `/api/users/authenticated/logout`>({
    method: "post",
    path: `/api/users/authenticated/logout`,
    options: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/users/authenticated`]),
        });
        navigate(RouteData.login.getPathTemplate());
      },
    },
  });
};
