import moment from "moment";
import { BenefitTypeBenExData, BenefitTypesBenEx } from "shared/types/BenefitTypes";
import { BenefitLevelData, PlanTypeData } from "../data/ExplorerBenefit";
import { LocationStateData } from "../data/LocationState";

import { LocationStateCodes } from "./Location";

import type { explorerPageBenefitInputValidation } from "../validation/explorerPageBenefit";
import type { Carrier, CarrierName } from "./Carrier";
import type { Document } from "./Document";
import type { Pretty, ValueOf, ValuesForValidationSchema } from "./Helper";
import type { LocationStateCode } from "./Location";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type * as Yup from "yup";

export type ExplorerPageBenefitId = string;

export type TicketMeta = { benefits: ExplorerPageBenefitInput[]; slfPolicies: string[] };

export type ExplorerPageBenefitInput = Pretty<
  Yup.InferType<typeof explorerPageBenefitInputValidation>
>;
export interface ExplorerPageBenefit {
  id: ExplorerPageBenefitId;
  pageId: string;
  carrierId: number | null;
  carrier?: Carrier | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  benefitType: BenefitTypeBenEx;
  planType: PlanType | null;
  isNew: boolean;
  planSummaryDocumentId: string | null;
  planSummaryDocument?: BenefitPlanSummaryDocument | null;
  spanishPlanSummaryDocumentId: string | null;
  spanishPlanSummaryDocument?: BenefitPlanSummaryDocument | null;
  legalEntity: string | null;
  planName: string;
  contentFlags: ContentFlag[] | null;
  benefitLevel: BenefitLevel | null;
  wellnessAmount: number | null;
  prepaidDentalNetwork: DentalNetwork | null;
  prepaidSitusState: LocationStateCode | null;
  premium: string | null;
  outOfPocketCosts: string | null;
  hidePremiumOutOfPocket?: boolean;
  linkedHDHPPlanName: string | null;
  planYearDeductibleIndividualInNetwork: number | null;
  planYearDeductibleIndividualOutOfNetwork: number | null;
  planYearDeductibleFamilyInNetwork: number | null;
  planYearDeductibleFamilyOutOfNetwork: number | null;
  planYearDeductibleOutOfNetworkNA: boolean;
  planYearMaximumIndividualInNetwork: number | null;
  planYearMaximumIndividualOutOfNetwork: number | null;
  planYearMaximumFamilyInNetwork: number | null;
  planYearMaximumFamilyOutOfNetwork: number | null;
  planYearMaximumOutOfNetworkNA: boolean;
  medicalHealthSavingsAccountEmployerContribution: boolean | null;
  medicalOutOfNetworkBenefits: boolean | null;
  medicalPcpCoordinationRequired: boolean | null;
  medicalPcpSpecialtyCareReferralRequired: boolean | null;
  dentalYearType: DentalYearType | null;
  dentalOrthodonticServices: string | null;
  dentalOrthodonticMaximumAdult: number | null;
  dentalOrthodonticMaximumAdultPercent: number | null;
  dentalOrthodonticMaximumChild: number | null;
  dentalOrthodonticMaximumChildPercent: number | null;
  dentalTypeOneInNetwork: number | null;
  dentalTypeOneOutOfNetwork: number | null;
  dentalTypeTwoInNetwork: number | null;
  dentalTypeTwoOutOfNetwork: number | null;
  dentalTypeThreeInNetwork: number | null;
  dentalTypeThreeOutOfNetwork: number | null;
  visionExamServicesBiYearly: boolean | null;
  visionExamServicesInNetwork: number | null;
  visionExamServicesOutOfNetwork: number | null;
  visionLensesBiYearly: boolean | null;
  visionLensesInNetwork: number | null;
  visionLensesOutOfNetwork: number | null;
  visionFramesBiYearly: boolean | null;
  visionFramesInNetwork: number | null;
  visionFramesOutOfNetwork: number | null;
  visionFramesOverInNetwork: number | null;
  visionFramesOverOutOfNetwork: number | null;
  visionContactLensesBiYearly: boolean | null;
  visionContactLensesInNetwork: number | null;
  visionContactLensesOutOfNetwork: number | null;
  visionContactLensesExamInNetwork: number | null;
  visionContactLensesExamOutOfNetwork: number | null;
  financialFSAMoneyRollover: boolean | null;
  financialFSAMoneyRolloverAmount: number | null;
  financialFSAMoneyRolloverDate: Date | null;
}

export const sortBenefits = (
  benefits: ExplorerPageBenefit[] | undefined,
  customOrder: boolean,
): ExplorerPageBenefit[] | undefined => {
  if (customOrder) {
    return benefits
      ?.sort((a, b) => {
        if (moment(a.createdAt).isSame(b.createdAt)) {
          return a.id.localeCompare(b.id);
        }
        return moment(a.createdAt).diff(b.createdAt);
      })
      .reduce<ExplorerPageBenefit[][]>((acc, val) => {
        const existingBenefitGrouping = acc.find(
          (group) => group[0]?.benefitType === val.benefitType,
        );

        if (existingBenefitGrouping) existingBenefitGrouping.push(val);
        else acc.push([val]);
        return acc;
      }, [])
      .flat();
  }

  return benefits?.sort((a, b) => {
    const aIdx = BenefitTypesBenEx.indexOf(a.benefitType);
    const bIdx = BenefitTypesBenEx.indexOf(b.benefitType);
    if (aIdx === bIdx) {
      if (moment(a.createdAt).isSame(b.createdAt)) {
        return a.id.localeCompare(b.id);
      }
      return moment(a.createdAt).diff(b.createdAt);
    }
    return aIdx - bIdx;
  });
};

export const sortBenefitInputs = <B extends ExplorerPageBenefitInput | ExplorerPageBenefitInput>(
  benefits: B[],
  customOrder: boolean,
): B[] => {
  if (customOrder) {
    return benefits
      .reduce<B[][]>((acc, val) => {
        const existingBenefitGrouping = acc.find(
          (group) => group[0]?.benefitType === val.benefitType,
        );

        if (existingBenefitGrouping) existingBenefitGrouping.push(val);
        else acc.push([val]);
        return acc;
      }, [])
      .flat();
  }

  return benefits.sort((a, b) => {
    const aIdx = BenefitTypesBenEx.indexOf(a.benefitType);
    const bIdx = BenefitTypesBenEx.indexOf(b.benefitType);
    if (aIdx === bIdx) return 1;
    return aIdx - bIdx;
  });
};

export const getDefaultPlanName = (
  {
    benefitType,
    planType,
    benefitLevel,
    carrierId,
    contentFlags,
    prepaidSitusState,
  }: ValuesForValidationSchema<typeof explorerPageBenefitInputValidation>,
  carriers: Carrier[],
  employerState: LocationStateCode | null,
): string => {
  const benTypeVal = benefitType ? BenefitTypeBenExData[benefitType].displayName : "";
  const planTypeVal = planType ? PlanTypeData[planType].displayName : "";
  const benLevelVal = benefitLevel ? BenefitLevelData[benefitLevel].displayName : "";
  const carrierName = carriers.find((c) => c.id === carrierId)?.carrierName ?? "";
  const situsStateVal = prepaidSitusState ? LocationStateData[prepaidSitusState].displayName : "";

  switch (benefitType) {
    case "GAP":
      return isSunlifeCarrier(carrierId)
        ? `${getBenefitNameBySitusState(benefitType, employerState)} Insurance offered by Sun Life`
        : `${carrierName} ${getBenefitNameBySitusState(benefitType, employerState)} Insurance ${
            benLevelVal ? `(${benLevelVal})` : ""
          }`
            .trim()
            .replace(/\s+/g, " ");

    case "ACCIDENT":
    case "CANCER":
    case "HOSPITAL_INDEMNITY":
      return `${carrierName} ${benTypeVal} Insurance`.trim().replace(/\s+/g, " ");
    case "CRITICAL_ILLNESS":
      return `${carrierName} ${getBenefitNameBySitusState(benefitType, employerState)} Insurance`
        .trim()
        .replace(/\s+/g, " ");

    case "FINANCIAL":
      return planType ? PlanTypeData[planType].fullName ?? "" : "";

    case "LIFE":
      return `${carrierName} ${planTypeVal} ${contentFlags?.includes("AD&D") ? "and AD&D" : ""} ${
        benefitLevel === "BUY_UP" ? "with buy up option" : ""
      }`
        .trim()
        .replace(/\s+/g, " ");

    case "LONG_TERM_DISABILITY":
      return `${carrierName} Long-Term Disability ${planTypeVal ? `(${planTypeVal})` : ""}`
        .trim()
        .replace(/\s+/g, " ");
    case "SHORT_TERM_DISABILITY":
      return `${carrierName} Short-Term Disability ${planTypeVal ? `(${planTypeVal})` : ""}`
        .trim()
        .replace(/\s+/g, " ");
    case "VISION":
      return `${carrierName} ${benTypeVal} ${planTypeVal ? `(${planTypeVal})` : ""}`
        .trim()
        .replace(/\s+/g, " ");

    case "MEDICAL":
      return carrierName;

    case "DENTAL":
      return planType === "DHMO"
        ? prepaidSitusState === "TX" || prepaidSitusState === "FL"
          ? `Sun Life Dental Prepaid Plan - ${situsStateVal}`
          : `${carrierName} Dental Prepaid/DHMO Plan ${situsStateVal ? `- ${situsStateVal}` : ""}`
              .trim()
              .replace(/\s+/g, " ")
        : planType === "ASO_PPO"
        ? `${carrierName} ${benTypeVal} Plan`.trim().replace(/\s+/g, " ")
        : `${carrierName} ${benTypeVal} ${planTypeVal} Plan`.trim().replace(/\s+/g, " ");

    case "OTHER":
      return `${carrierName} ${planTypeVal}`.trim().replace(/\s+/g, " ");

    default:
      return "";
  }
};

export const getBenefitNameBySitusState = (
  benefitType: BenefitTypeBenEx,
  employerState: LocationStateCode | null,
) => {
  switch (employerState) {
    case "CA":
      return benefitType === "GAP" ? "Supplemental Medical Expense “Gap”" : "Specified Disease";
    case "CO":
      return benefitType === "GAP" ? "Supplemental Medical Expense “Gap”" : "Critical Illness";
    case "IN":
      return benefitType === "GAP" ? "Supplemental Medical Expense “Gap”" : "Critical Illness";
    case "OR":
      return benefitType === "GAP" ? "Supplemental Medical Expense “Gap”" : "Critical Illness";
    case "MO":
      return benefitType === "GAP" ? "Supplemental Medical Expense “Gap”" : "Critical Illness";
    case "NY":
      return benefitType === "GAP" ? "Hospital Confinement Indemnity “Gap”" : "Specified Disease";
    case "CT":
      return benefitType === "GAP" ? "Hospital Confinement Indemnity “Gap”" : "Specified Disease";
    default:
      return benefitType === "GAP" ? "Hospital Confinement Indemnity “Gap”" : "Critical Illness";
  }
};

export const isSunlifeCarrier = (carrierId: number | null) => {
  const sunLifeCarrierIds = [1];
  if (carrierId) {
    if (sunLifeCarrierIds.find((cid) => cid === carrierId)) return true;
  }
  return false;
};

export type BenefitPlanSummaryDocument = Pick<Document, "documentKey" | "id" | "name" | "mimeType">;

export const PlanTypes = [
  "HMO",
  "EPO",
  "POS",
  "PPO",
  "MEC",
  "OAP",
  "DHMO",
  "EMPLOYER_PAID",
  "VOLUNTARY",
  "BASIC_LIFE",
  "VOLUNTARY_LIFE",
  "STANDALONE_ADD",
  "HSA",
  "HRA",
  "FSA",
  "LPFSA",
  "EAP",
  "DCA",
  "PFML_FMLA_LEAVE",
  "COMMUTER_TRANSIT",
  "DISCOUNT_PROGRAM",
  "FITNESS_PROGRAM",
  "HEALTH_AND_WELLNESS",
  "IDENTITY_PROTECTION",
  "LEGAL_SERVICES",
  "PET_INSURANCE",
  "RETIREMENT",
  "TELEHEALTH",
  "TRAVEL_INSURANCE",
  "OTHER",
  "ASO_PPO",
  "INDEMNITY",
] as const;
export type PlanType = ValueOf<typeof PlanTypes>;

export const BenefitPlanTypes = new Map<BenefitTypeBenEx, PlanType[]>([
  ["MEDICAL", ["HMO", "EPO", "POS", "PPO", "MEC", "OAP"]],
  ["DENTAL", ["PPO", "DHMO", "ASO_PPO", "INDEMNITY"]],
  ["SHORT_TERM_DISABILITY", ["EMPLOYER_PAID", "VOLUNTARY"]],
  ["LONG_TERM_DISABILITY", ["EMPLOYER_PAID", "VOLUNTARY"]],
  ["FINANCIAL", ["HSA", "HRA", "FSA", "LPFSA", "DCA"]],
  [
    "OTHER",
    [
      "EAP",
      "PFML_FMLA_LEAVE",
      "COMMUTER_TRANSIT",
      "DISCOUNT_PROGRAM",
      "FITNESS_PROGRAM",
      "HEALTH_AND_WELLNESS",
      "IDENTITY_PROTECTION",
      "LEGAL_SERVICES",
      "PET_INSURANCE",
      "RETIREMENT",
      "TELEHEALTH",
      "TRAVEL_INSURANCE",
      "OTHER",
    ],
  ],
  ["LIFE", ["BASIC_LIFE", "VOLUNTARY_LIFE", "STANDALONE_ADD"]],
]);

export const ContentFlags = [
  "HDHP",
  "HSA compatible",
  "HRA",
  "AD&D",
  "VAS_1",
  "VAS_2",
  "Self_Care+",
] as const;
export type ContentFlag = ValueOf<typeof ContentFlags>;

export const BenefitContentFlags = new Map<BenefitTypeBenEx, ContentFlag[]>([
  ["MEDICAL", ["HDHP", "HSA compatible", "HRA"]],
  ["LIFE", ["AD&D", "VAS_1", "VAS_2", "Self_Care+"]],
  ["ACCIDENT", ["VAS_1"]],
]);

export const BenefitLevels = ["LOW_HIGH", "LEVEL_1_LEVEL_2", "BUY_UP"] as const;
export type BenefitLevel = ValueOf<typeof BenefitLevels>;

export const BenefitLevelsByBenefit = new Map<BenefitTypeBenEx, BenefitLevel[]>([
  ["ACCIDENT", ["LOW_HIGH"]],
  ["HOSPITAL_INDEMNITY", ["LOW_HIGH"]],
  ["CANCER", ["LEVEL_1_LEVEL_2"]],
  ["SHORT_TERM_DISABILITY", ["BUY_UP"]],
  ["LONG_TERM_DISABILITY", ["BUY_UP"]],
  ["LIFE", ["BUY_UP"]],
  ["DENTAL", ["LOW_HIGH"]],
]);

export const PremiumAndCosts = ["$", "$$", "$$$"] as const;
export type PremiumAndCost = ValueOf<typeof PremiumAndCosts>;

export const BenefitPremiumAndCosts = new Map<BenefitTypeBenEx, PremiumAndCost[]>([
  ["MEDICAL", ["$", "$$", "$$$"]],
]);

export const BenefitCarrierBlocklist = new Map<BenefitTypeBenEx, CarrierName[]>([
  ["MEDICAL", ["Sun Life"]],
  ["FINANCIAL", ["Sun Life"]],
]);

export const DentalYearTypes = ["PLAN", "CALENDAR"] as const;

export type DentalYearType = ValueOf<typeof DentalYearTypes>;

export const DentalNetworks = [
  "SUN_LIFE_FOCUS_DENTAL_NETWORK_PPO",
  "SUN_LIFE_DENTAL_NETWORK_PPO",
  "HERITAGE",
  "DHMO_DENTAL_SERIES",
  "CA_UDC_PREPAID",
  "LEGEND",
  "UNITED_DENTAL_CARE",
  "PREPAID_DENTAL_SERIES_FLORIDA_ONLY",
  "GEORGIA_PREPAID",
] as const;

export type DentalNetwork = ValueOf<typeof DentalNetworks>;

export const DentalNetworkByPlan = new Map<PlanType, DentalNetwork[]>([
  ["PPO", ["SUN_LIFE_FOCUS_DENTAL_NETWORK_PPO", "SUN_LIFE_DENTAL_NETWORK_PPO"]],
  [
    "DHMO",
    [
      "HERITAGE",
      "DHMO_DENTAL_SERIES",
      "CA_UDC_PREPAID",
      "LEGEND",
      "UNITED_DENTAL_CARE",
      "PREPAID_DENTAL_SERIES_FLORIDA_ONLY",
      "GEORGIA_PREPAID",
    ],
  ],
  ["ASO_PPO", ["SUN_LIFE_FOCUS_DENTAL_NETWORK_PPO", "SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["INDEMNITY", ["SUN_LIFE_FOCUS_DENTAL_NETWORK_PPO", "SUN_LIFE_DENTAL_NETWORK_PPO"]],
]);

export const DentalStateByPlanType = new Map<PlanType, LocationStateCode[]>([
  ["PPO", LocationStateCodes.filter((c) => c !== "MT")],
  [
    "DHMO",
    [
      "AL",
      "AZ",
      "CA",
      "CO",
      "FL",
      "GA",
      "IL",
      "KS",
      "KY",
      "MI",
      "MO",
      "NE",
      "NV",
      "NJ",
      "NM",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "TN",
      "TX",
      "UT",
      "WI",
    ],
  ],
  ["ASO_PPO", LocationStateCodes.filter((c) => c !== "MT")],
  ["INDEMNITY", LocationStateCodes.filter((c) => c !== "MT" && c !== "RI")],
]);

export const DentalNetworkByState = new Map<Exclude<LocationStateCode, "MT">, DentalNetwork[]>([
  ["AK", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["AL", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["AR", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["AZ", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE", "DHMO_DENTAL_SERIES"]],
  ["CA", ["SUN_LIFE_DENTAL_NETWORK_PPO", "CA_UDC_PREPAID", "DHMO_DENTAL_SERIES"]],
  ["CO", ["SUN_LIFE_DENTAL_NETWORK_PPO", "UNITED_DENTAL_CARE"]],
  ["CT", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["DC", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["DE", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["FL", ["PREPAID_DENTAL_SERIES_FLORIDA_ONLY", "SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["GA", ["GEORGIA_PREPAID", "SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["HI", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["IA", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["ID", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["IL", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["IN", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["KS", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["KY", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["LA", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["MA", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["MD", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["ME", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["MI", ["SUN_LIFE_DENTAL_NETWORK_PPO", "LEGEND"]],
  ["MN", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["MO", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["MS", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["NC", ["SUN_LIFE_FOCUS_DENTAL_NETWORK_PPO"]],
  ["ND", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["NE", ["SUN_LIFE_DENTAL_NETWORK_PPO", "LEGEND", "UNITED_DENTAL_CARE"]],
  ["NH", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["NJ", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["NM", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE", "LEGEND"]],
  ["NV", ["SUN_LIFE_DENTAL_NETWORK_PPO", "UNITED_DENTAL_CARE"]],
  ["NY", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["OH", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["OK", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["OR", ["SUN_LIFE_DENTAL_NETWORK_PPO", "LEGEND"]],
  ["PA", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["RI", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["SC", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["SD", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["TN", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE"]],
  ["TX", ["SUN_LIFE_DENTAL_NETWORK_PPO", "HERITAGE", "DHMO_DENTAL_SERIES"]],
  ["UT", ["SUN_LIFE_DENTAL_NETWORK_PPO", "LEGEND"]],
  ["VA", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["VT", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["WA", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["WI", ["SUN_LIFE_DENTAL_NETWORK_PPO", "LEGEND"]],
  ["WV", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
  ["WY", ["SUN_LIFE_DENTAL_NETWORK_PPO"]],
]);

export const DentalNetworkPlanNumber: { [key in DentalNetwork]: string } = {
  SUN_LIFE_FOCUS_DENTAL_NETWORK_PPO: "46",
  SUN_LIFE_DENTAL_NETWORK_PPO: "47",
  HERITAGE: "23",
  DHMO_DENTAL_SERIES: "29",
  CA_UDC_PREPAID: "28",
  LEGEND: "24",
  UNITED_DENTAL_CARE: "27",
  PREPAID_DENTAL_SERIES_FLORIDA_ONLY: "29",
  GEORGIA_PREPAID: "26",
};
