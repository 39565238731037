import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "client/src/components/Button/Button";
import { StackX } from "client/src/components/Spacing/Spacing";
import { usePreventBodyScrollWhenVisible } from "client/src/hooks/usePreventBodyScrollWhenVisible";
import React from "react";
import CloseIcon from "../Icons/close.svg?react";
import * as styles from "./modal.module.less";

export type ModalTitle =
  | {
      ariatitle: string;
      title?: never;
    }
  | {
      ariatitle?: never;
      title: React.ReactElement | string;
    };

export type ModalProps = {
  "data-testid"?: string;
  open: boolean;
  width?: number | string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  onOk?: () => void;
  /**
   * Defaults to `true`
   *
   * Set to `false` when you have other portaled elements inside the dialog
   * that require interaction, like dropdowns, selects, etc.
   */
  modal?: boolean;
} & (
  | {
      onCancel: () => void;
      disableClose?: boolean;
    }
  | {
      onCancel?: undefined;
      disableClose?: never;
    }
) &
  ModalTitle;

export function Modal(props: ModalProps) {
  const {
    open,
    width = 750,
    title,
    ariatitle,
    children,
    footer,
    onOk,
    onCancel,
    modal = true,
  } = props;

  const Overlay = modal ? Dialog.Overlay : "div";

  usePreventBodyScrollWhenVisible(!modal && open);

  return (
    <Dialog.Root open={open} onOpenChange={(open) => !open && onCancel?.()} modal={modal}>
      <Dialog.Portal>
        <Overlay className={styles.DialogOverlay}>
          <Dialog.Content
            data-testid={props["data-testid"]}
            className={styles.DialogContent}
            style={{ width }}
          >
            {ariatitle && (
              <Dialog.Title asChild>
                <span className="sr-only">{ariatitle}</span>
              </Dialog.Title>
            )}

            <div className="stack-y-24">
              {title && (
                <div className={styles.DialogTitle}>
                  <Dialog.Title className="h1">{title}</Dialog.Title>

                  <hr />
                </div>
              )}

              <Dialog.Description asChild>
                <div>{children}</div>
              </Dialog.Description>

              {footer === undefined ? (
                <StackX dist={8} className="flex items-right">
                  {onCancel && (
                    <Button
                      type="text"
                      onClick={props.disableClose ? undefined : onCancel}
                      disabled={props.disableClose}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button type="primary" onClick={onOk}>
                    Ok
                  </Button>
                </StackX>
              ) : (
                footer
              )}
            </div>

            {onCancel && (
              <Dialog.Close asChild>
                <button
                  className={styles.IconButton}
                  aria-label="Close"
                  aria-disabled={props.disableClose}
                >
                  <CloseIcon />
                </button>
              </Dialog.Close>
            )}
          </Dialog.Content>
        </Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
