import {
  formatCurrency,
  formatCurrencyWithCents,
  formatLongMonthDayDate,
  formatLongMonthDayLongDate,
  formatLongMonthDayYearDate,
  formatMonthDayDate,
  formatYearDate,
} from "../utils/formatters";

import type { FormatFunction } from "i18next";

/**
 * Add new formatters to this object, the key will be the name of the formatters.
 *
 * @see https://www.i18next.com/translation-function/formatting#basic
 *
 * @example
 * // utils/i18n/locales/en.ts
 * export default {
 *   "SAMPLE": "This fruit is {{ name, lowercase }}"
 * }
 * // 'name' is a param, while 'lowercase' is the formatter to use which is
 * // defined in the object below.
 */
const formatters: Record<string, FormatFunction> = {
  lowercase: (value) => (typeof value === "string" ? value.toLowerCase() : ""),
  uppercase: (value) => (typeof value === "string" ? value.toUpperCase() : ""),
  currency: (value) => formatCurrency(value),
  currencyWithCents: (value) => formatCurrencyWithCents(value),
  monthDayDate: (value) => formatMonthDayDate(value),
  longMonthDayDate: (value) => formatLongMonthDayDate(value),
  yearDate: (value) => formatYearDate(value),
  longMonthDayYearDate: (value) => formatLongMonthDayYearDate(value),
  longMonthDayLongDate: (value) => formatLongMonthDayLongDate(value),
  capitalize: (value) =>
    typeof value === "string" ? value.charAt(0).toUpperCase() + value.slice(1) : "",
};

export const format: FormatFunction = (value, format, lng) => {
  if (typeof format !== "string") {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- needed
    return value as string;
  }

  /**
   * Support multiple formats. Example: `{{ fruit, lowercase, uppercase }}`.
   * i18next support two or more formatters together, but for some reason it
   * doesn't work. TODO: Update i18next and related dependencies.
   * @see https://github.com/i18next/i18next/blob/1fc4c8fad353be919cf4ef5b6a1b16979a291995/test/i18next.translation.formatting.spec.js#L17-L18
   */
  const formatNames = format.split(",").map((f) => f.trim());

  formatNames.forEach((name) => {
    if (!formatters[name]) {
      throw new Error(`The format: ${name} does not exist`);
    }
  });

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- needed
  return formatNames.reduce((lastValue, formatName) => {
    const formatter = formatters[formatName];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- yolo
    return formatter!(lastValue, format, lng);
  }, value) as string;
};
