import { RouteData } from "shared/config/routeData";

import { NavBar } from "../../components/NavBar/NavBar";
import { SlobNavLink } from "../../components/SlobLink/SlobLink";
import { StackX } from "../../components/Spacing/Spacing";
import { useSlobAuth } from "../../hooks/auth";
import { NavItemPopover } from "./NavItemPopover";
import type { logOutType } from "./NavBarPage";

type BenAdminPageProps = {
  logOut: logOutType;
  clientId?: string;
};

export const BenAdminNavPage = ({ logOut, clientId }: BenAdminPageProps) => {
  const { authUser } = useSlobAuth();

  const userName = authUser?.name || "";

  const rightLinks = (
    <StackX dist={48}>
      <SlobNavLink key={RouteData.homeBenAdmin.friendlyName} to={RouteData.homeBenAdmin.getPath()}>
        {RouteData.homeBenAdmin.friendlyName}
      </SlobNavLink>

      <NavItemPopover userName={userName} email={authUser?.email} logOut={logOut} />
    </StackX>
  );

  return (
    <NavBar
      homeLink={RouteData.homeBenAdmin.getPath()}
      clientId={clientId}
      rightLinks={rightLinks}
    />
  );
};
