import * as Popover from "@radix-ui/react-popover";
import { H3 } from "client/src/components/Typography/Typography";
import clsx from "clsx";
import React, { useState } from "react";

import * as styles from "./slobPopover.module.less";

import type { ComponentProps } from "react";

type Side = ComponentProps<typeof Popover.Content>["side"];

type Props = {
  variant?: "primary" | "secondary";
  title?: string;
  content: React.ReactNode;
  children: React.ReactElement;
  side?: Side;
};

export function SlobPopover(props: Props) {
  const { variant = "primary", title, children, content, side } = props;

  const [open, setOpen] = useState(false);

  return (
    <Popover.Root open={open} onOpenChange={content ? setOpen : undefined}>
      <Popover.Trigger asChild>{children}</Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className={clsx(
            styles.PopoverContent,
            variant === "secondary" && styles.Popover__secondary,
          )}
          sideOffset={5}
          side={side}
        >
          {title && (
            <H3 as="div" white={variant === "primary"}>
              {title}
            </H3>
          )}

          {content}

          <Popover.Arrow className={styles.PopoverArrow} aria-hidden />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
