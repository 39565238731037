import type { ClientId } from "shared/types/Client";
import type { ValueOf } from "shared/types/Helper";

export type TaskId = ClientTaskId | PolicyTaskId;

export const clientTaskIds = [
  "kickoff-call",
  "ongoing-service-call",
  "onboarding-forms",
  "prior-carrier-certificates",
  "sun-life-connect",
  "enrollment-resources",
  "company-profile",
  "eif-submit-company-information",
] as const;
export type ClientTaskId = ValueOf<typeof clientTaskIds>;

export const policyTaskIds = [
  "prior-carrier-bills",
  "enrollment-elections",
  "pfml",
  "pfml-spd",
  "sunadvisor-spd",
  "dental-rollover",
  "deductible-maximum-report",
] as const;
export type PolicyTaskId = ValueOf<typeof policyTaskIds>;

export const infoGatheringClientTasks: TaskId[] = [
  "kickoff-call",
  "ongoing-service-call",
  "onboarding-forms",
  "prior-carrier-certificates",
  "sun-life-connect",
  "company-profile",
  "eif-submit-company-information",
];

export const infoGatheringPolicyTasks = policyTaskIds;

export const taskNames = [
  "Kickoff Call",
  "Company Profile",
  "Prior Carrier Certificates",
  "Onboarding Forms",
  "Prior Carrier Bills",
  "Submit Enrollment Elections",
  "Submit Census Information", // this is 'enrollment-elections' for Maxwell clients
  "Enrollment Resources",
  "Ongoing Service Call",
  "Sun Life Connect",
  "PFML Plan Exemption Document",
  "PFML SPDs",
  "SunAdvisor SPD",
  "Submit Company Information",
  "Dental Rollover", // dental-rollover for task
  "Submit Banked Dental Rollover Amounts", // dental-rollover for email line item
  "ASO Dental Deductible and Maximum Report",
] as const;
export type TaskName = ValueOf<typeof taskNames>;

export const taskNamesById: Record<TaskId, TaskName> = {
  "kickoff-call": "Kickoff Call",
  "company-profile": "Company Profile",
  "prior-carrier-certificates": "Prior Carrier Certificates",
  "onboarding-forms": "Onboarding Forms",
  "prior-carrier-bills": "Prior Carrier Bills",
  "enrollment-elections": "Submit Enrollment Elections",
  "enrollment-resources": "Enrollment Resources",
  "ongoing-service-call": "Ongoing Service Call",
  "sun-life-connect": "Sun Life Connect",
  pfml: "PFML Plan Exemption Document",
  "pfml-spd": "PFML SPDs",
  "sunadvisor-spd": "SunAdvisor SPD",
  "eif-submit-company-information": "Submit Company Information",
  "dental-rollover": "Submit Banked Dental Rollover Amounts", // dental-rollover for email line item
  "deductible-maximum-report": "ASO Dental Deductible and Maximum Report",
};

export const clientTaskEnumNames = [
  "KICKOFF_CALL",
  "COMPANY_PROFILE",
  "PRIOR_CARRIER_CERTIFICATES",
  "ONBOARDING_FORMS",
  "ENROLLMENT_RESOURCES",
  "ONGOING_SERVICE_CALL",
  "SUN_LIFE_CONNECT",
  "EIF_SUBMIT_COMPANY_INFORMATION",
] as const;
export const policyTaskEnumNames = [
  "PRIOR_CARRIER_BILLS",
  "ENROLLMENT_ELECTIONS",
  "PFML",
  "PFML_SPD",
  "SUNADVISOR_SPD",
  "DENTAL_ROLLOVER",
  "DEDUCTIBLE_MAXIMUM_REPORT",
] as const;

export type TaskEnumName = ValueOf<typeof clientTaskEnumNames | typeof policyTaskEnumNames>;
export type PolicyTaskEnumName = ValueOf<typeof policyTaskEnumNames>;
export type ClientTaskEnumName = ValueOf<typeof clientTaskEnumNames>;

export type TaskStatusId =
  | "your-prior-carrier-certificate-needs-attention"
  | "sunlife-is-reviewing-certificates"
  | "sunlife-is-reviewing-bills"
  | "enrollment-census-completed"
  | "nothing-to-do"
  | "no-ongoing-service-call"
  | "schedule-an-ongoing-service-call"
  | "future-ongoing-service-call"
  | "added-not-submitted-enrollment-census"
  | "you-need-to-provide-enrollment-census"
  | "sunlife-is-reviewing-enrollment-census"
  | "no-kickoff-call"
  | "schedule-a-kickoff-call"
  | "kickoff-call-preference-provided-outside-onboard"
  | "sun-life-connect-available"
  | "sun-life-connect-not-available"
  | "fill-out-benefits-required"
  | "fill-out-benefits-in-slf-review"
  | "fill-out-benefits-updates-available"
  | "fill-out-benefits-waiting"
  | "fill-out-benefits-ready-to-sign"
  | "fill-out-benefits-in-progress"
  | "fill-out-benefits-voided-declined"
  | "fill-out-benefits-complete"
  | "company-profile-in-progress"
  | "new-enrollment-resource-available"
  | "eif-steps-completed"
  | null;

export type TaskEnumStatusId =
  | "YOUR_PRIOR_CARRIER_CERTIFICATE_NEEDS_ATTENTION"
  | "SUNLIFE_IS_REVIEWING_CERTIFICATES"
  | "SUNLIFE_IS_REVIEWING_BILLS"
  | "ENROLLMENT_CENSUS_COMPLETED"
  | "NOTHING_TO_DO"
  | "NO_ONGOING_SERVICE_CALL"
  | "SCHEDULE_AN_ONGOING_SERVICE_CALL"
  | "FUTURE_ONGOING_SERVICE_CALL"
  | "YOU_NEED_TO_PROVIDE_ENROLLMENT_CENSUS"
  | "ADDED_NOT_SUBMITTED_ENROLLMENT_CENSUS"
  | "SUNLIFE_IS_REVIEWING_ENROLLMENT_CENSUS"
  | "NO_KICKOFF_CALL"
  | "SCHEDULE_A_KICKOFF_CALL"
  | "KICKOFF_CALL_PREFERENCE_PROVIDED_OUTSIDE_ONBOARD"
  | "SUN_LIFE_CONNECT_AVAILABLE"
  | "SUN_LIFE_CONNECT_NOT_AVAILABLE"
  | "FILL_OUT_BENEFITS_REQUIRED"
  | "FILL_OUT_BENEFITS_IN_SLF_REVIEW"
  | "FILL_OUT_BENEFITS_UPDATES_AVAILABLE"
  | "FILL_OUT_BENEFITS_WAITING"
  | "FILL_OUT_BENEFITS_READY_TO_SIGN"
  | "FILL_OUT_BENEFITS_IN_PROGRESS"
  | "FILL_OUT_BENEFITS_VOIDED_DECLINED"
  | "FILL_OUT_BENEFITS_COMPLETE"
  | "COMPANY_PROFILE_IN_PROGRESS"
  | "NEW_ENROLLMENT_RESOURCE_AVAILABLE"
  | "EIF_STEPS_COMPLETED"
  | null;

export const taskStatus = [
  "Upcoming",
  "Not Started",
  "In Progress",
  "In Review",
  "NIGO",
  "Voided",
  "Declined",
  "Done",
  null,
] as const;

export type TaskStatus = ValueOf<typeof taskStatus>;

export const taskEnumStatus = [
  "UPCOMING",
  "NOT_STARTED",
  "IN_PROGRESS",
  "IN_REVIEW",
  "NIGO",
  "VOIDED",
  "DECLINED",
  "DONE",
] as const;

export type TaskEnumStatus = ValueOf<typeof taskEnumStatus>;

export const taskEnumStatusByStatus: Record<NonNullable<TaskStatus>, TaskEnumStatus> = {
  Upcoming: "UPCOMING",
  "Not Started": "NOT_STARTED",
  "In Progress": "IN_PROGRESS",
  "In Review": "IN_REVIEW",
  NIGO: "NIGO",
  Voided: "VOIDED",
  Declined: "DECLINED",
  Done: "DONE",
};

export const taskStatusByEnumStatus: Record<TaskEnumStatus, TaskStatus> = {
  UPCOMING: "Upcoming",
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  IN_REVIEW: "In Review",
  NIGO: "NIGO",
  VOIDED: "Voided",
  DECLINED: "Declined",
  DONE: "Done",
};

export type Assignee = "Sun Life" | "Benefits Administrator or Broker";

export type AssigneeEnum = "SUN_LIFE" | "BENEFITS_ADMINISTRATOR_OR_BROKER";

export const taskEnumAssigneeByAssignee: Record<Assignee, AssigneeEnum> = {
  "Sun Life": "SUN_LIFE",
  "Benefits Administrator or Broker": "BENEFITS_ADMINISTRATOR_OR_BROKER",
};

export const taskAssigneeByEnumAssignee: Record<AssigneeEnum, Assignee> = {
  SUN_LIFE: "Sun Life",
  BENEFITS_ADMINISTRATOR_OR_BROKER: "Benefits Administrator or Broker",
};

export type GenericTask = {
  clientId?: ClientId;
  id: Exclude<TaskId, "enrollment-resources">;
  status: TaskStatus;
  dueDate: Date | null;
  assignee: Assignee;
  statusId: Exclude<TaskStatusId, "new-enrollment-resource-available">;
};

export type EnrollmentResourcesTask = {
  clientId?: ClientId;
  id: "enrollment-resources";
  status: TaskStatus;
  dueDate: null;
  assignee: Assignee;
} & ({ statusId: null } | { statusId: "new-enrollment-resource-available"; availableAt: Date });

export type TaskFromJiraTicket = {
  clientId?: ClientId;
  id: TaskId;
  status: TaskStatus;
  dueDate: Date | null;
  assignee: Assignee;
  statusId: TaskStatusId;
};

export type Task = (GenericTask | EnrollmentResourcesTask | TaskFromJiraTicket) & {
  policyId?: string;
};

export type PolicyTask = {
  clientId?: ClientId;
  id: PolicyTaskId;
  policyId: string;
  status: TaskStatus;
  dueDate: Date | null;
  assignee: Assignee;
  statusId: TaskStatusId;
};

export const TaskOrder: ReadonlyArray<TaskId> = [
  "kickoff-call",
  "company-profile",
  "eif-submit-company-information",
  "onboarding-forms",
  "prior-carrier-certificates",
  "prior-carrier-bills",
  "sunadvisor-spd",
  "enrollment-elections",
  "dental-rollover",
  "pfml",
  "pfml-spd",
  "sun-life-connect",
  "ongoing-service-call",
  "enrollment-resources",
];

export const dentalRolloverFeatureToggleImplementationDate = new Date("2024-05-01T00:00:00Z");
