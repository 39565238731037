import { DateTime, Duration, FixedOffsetZone } from "luxon";
import { assertIsDefined } from "shared/utils/utils";
import { monthDaySchema } from "shared/validation/monthDaySchema";

const uiDateFormat = "LLLL d";
const uiDateWithYear = "LLLL d, y";

// April, August, and October need "an" as an article
const monthsForAnArticle = [3, 7, 9];

/**
 * @param input
 * @returns e.g. 10/30/2021 in UTC timezone
 */
export const formatDate = (input: Date) =>
  DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toLocaleString(
    DateTime.DATE_SHORT,
  );

/**
 * @param input
 * @returns e.g. 10/14/1983, 9:30 AM EDT
 * @description Returns short datetime string with the local timezone of the user (when run on client) or the server (when run on server)
 */
export const formatDateTimeLocal = (input: Date) =>
  DateTime.fromJSDate(input).toLocaleString({
    ...DateTime.DATETIME_SHORT,
    timeZoneName: "short",
  });

/**
 * @param input
 * @returns e.g. 9:30 AM EDT
 * @description Returns short time string with the local timezone of the user (when run on client) or the server (when run on server)
 */
export const formatTimeLocal = (input: Date) => {
  const datetime = DateTime.fromJSDate(input).toLocaleString({
    ...DateTime.DATETIME_SHORT,
    timeZoneName: "short",
  });
  const time = datetime.split(",")[1]?.trim() ?? "";
  return time;
};

/**
 * @param input
 * @returns e.g. 10/14/1983
 * @description Returns short date string with the local timezone of the user (when run on client) or the server (when run on server)
 */
export const formatShortDateTimeLocal = (input: Date) =>
  DateTime.fromJSDate(input).toLocaleString({
    ...DateTime.DATE_SHORT,
  });

/**
 * @param input
 * @returns e.g. Oct 31
 */
export const formatDateShortMonth = (input: Date) => {
  return DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat("LLL d");
};

/**
 * @param input
 * @returns e.g. Wed Jan 5 2022 with the local timezone of the user
 */
export const formatDateWeekDayShortMonthWithYear = (input: Date) => {
  return DateTime.fromJSDate(input).toLocal().toFormat("ccc LLL d y");
};

/**
 * @param input
 * @returns e.g. Wed Jan 05, 2022
 */
export const formatDateWithTimeAndtimezone = (input: Date) => {
  return DateTime.fromJSDate(input).toFormat("ccc LLL d, y, h:mm a ZZZZ");
};

/**
 * @param input
 * @returns e.g. October 31
 */
export const formatDateFullMonth = (input: Date) => {
  return DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat("LLLL d");
};

/**
 * @param input
 * @returns e.g. October 31, 2021
 */
export const formatDateFullMonthWithYear = (input: Date) =>
  DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat(uiDateWithYear);

/**
 * @param input
 * @param includeYear
 * @returns e.g. "an October 31, 2021", "a September 31, 2021"
 */
export const formatDateFullMonthWithArticle = (input: Date, includeYear: boolean) => {
  const currentMonth = input.getMonth();
  const article = monthsForAnArticle.includes(currentMonth) ? "an" : "a";
  return `${article} ${
    includeYear ? formatDateFullMonthWithYear(input) : formatDateFullMonth(input)
  }`;
};

export const minutesToTimeFormat = (totalMinutes: number) => {
  if (totalMinutes === 0) return "";
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  if (hours && minutes) return `${hours} hours and ${minutes} minutes`;
  if (hours) return `${hours} hours`;
  if (minutes) return `${minutes} minutes`;
  return "";
};

/**
 * @param input
 * @returns e.g. 2021
 */
export const formatDateYear = (input: Date) =>
  DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat("y");

export const formatDateRangeUI = (start: Date, end: Date) => {
  const s = DateTime.fromJSDate(start, { zone: FixedOffsetZone.utcInstance });
  const e = DateTime.fromJSDate(end, { zone: FixedOffsetZone.utcInstance });
  if (s.year !== e.year) {
    return s.toFormat(uiDateWithYear) + " - " + e.toFormat(uiDateWithYear);
  } else if (s.month !== e.month) {
    return s.toFormat(uiDateFormat) + " - " + e.toFormat(uiDateFormat) + ", " + String(e.year);
  } else {
    return s.toFormat(uiDateFormat) + " - " + String(e.day) + ", " + String(e.year);
  }
};

/**
 * @param input
 * @returns e.g. { date: 'Oct 1', time: '10AM EST' }
 */
export const formatRoundedDateAndTime = (input: Date) => {
  const datetime = DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance });
  const roundedDateTime = datetime.plus({
    minutes: datetime.minute > 30 ? 60 - datetime.minute : -datetime.minute,
  });

  const date = roundedDateTime.setZone("America/New_York").toFormat("LLL d");
  const time = roundedDateTime.setZone("America/New_York").toFormat("ha 'EST'");

  return {
    date,
    time,
  };
};

/**
 * @param input
 * @returns e.g. Oct-1-2021 13-07-01, Oct-1-2021 06-30-59
 */
export const formatDateTimeWithoutSlash = (input: Date): string => {
  const datetime = DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance });
  const formattedDate = datetime.setZone("America/New_York").toFormat("LLL-d-y HH-mm-ss");

  return formattedDate;
};

/**
 * @param monthDay 12/31
 * @returns December 31st
 */
export function formatMonthDayToMonthOrdinal(monthDay: string): string;
export function formatMonthDayToMonthOrdinal(monthDay: `${number}/${number}`): string;
export function formatMonthDayToMonthOrdinal(monthDay: string | `${number}/${number}`) {
  const monthDayRegex = /^(?<month>\d{1,2})\/(?<day>\d{1,2})$/;
  if (!monthDayRegex.test(monthDay)) {
    throw new TypeError(
      `Argument must be in the format "MM/DD" (e.g. 12/31). Provided: ${monthDay}`,
    );
  }
  monthDaySchema.validateSync(monthDay);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- guaranteed to not be null
  const [monthStr, dayStr] = monthDay.split("/");
  assertIsDefined(monthStr, "monthStr");
  assertIsDefined(dayStr, "dayStr");
  const month = Number.parseInt(monthStr, 10);
  const day = Number.parseInt(dayStr, 10);

  const pluralRules = new Intl.PluralRules("en-US", { type: "ordinal" });
  const suffixes: Record<Intl.LDMLPluralRule, string> = {
    zero: "",
    one: "st",
    two: "nd",
    few: "rd",
    many: "",
    other: "th",
  };
  const rule = pluralRules.select(day);
  const suffix = suffixes[rule];

  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
  });
  const REFERENCE_YEAR = 2024; // 2024 is a leap year so it will allow Feb 29
  const date = new Date(REFERENCE_YEAR, month - 1, day);
  const formattedDate = dateFormatter.format(date) + suffix;
  return formattedDate;
}

type KnownEntity = {
  firstName?: string | null;
  lastName?: string | null;
};

export const formatFullName = (entity: KnownEntity | undefined): string =>
  entity ? `${entity.firstName?.trim() ?? ""} ${entity.lastName?.trim() ?? ""}`.trim() : "";

export const extractFullName = (fullName: string): { firstName: string; lastName: string } => {
  const [firstName, ...lastName] = fullName.split(" ");
  return {
    firstName: firstName ?? "",
    lastName: lastName.join(" "),
  };
};

export type ListFormatOptions = {
  type: "conjunction" | "disjunction" | "unit";
  style: "long" | "short" | "narrow";
};

const defaultListFormat: ListFormatOptions = {
  type: "conjunction",
  style: "long",
};

export function listFormat(wordArray: string[], options: ListFormatOptions = defaultListFormat) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ListFormat is not currently exported because Microsoft is slacking
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- see above
  const list: string = new Intl.ListFormat("en-US", options).format(wordArray);
  return list;
}

/**
 * @param ms
 * @returns e.g. 5:00s
 */
export function formatDuration(ms: number) {
  const duration = Duration.fromMillis(ms).toFormat(`m:ss's'`);
  return duration;
}
